/*
  BaseGameTile.js
*/

import { Button, Icon } from 'semantic-ui-react';
import { Link } from "react-router-dom";


export default function BaseGameTile ({gameID, gameStatus, uid}) {

  let name = 'Game';
  let turnRequiredFlag = false;

  if(gameStatus) {
    if(gameStatus.name) {
      name = gameStatus.name;
    }

    if(Array.isArray(gameStatus.playerID)) {
      if(uid) {
        for(let p = 1; p < gameStatus.playerID.length; p++) {
          if(gameStatus.playerID[p] === uid) {
            // Is in game.
            if((gameStatus.required & (1<<p)) && (gameStatus.submitted & (1<<p)) === 0) {
              turnRequiredFlag = true;
            }
            break;
          }
        }
      }
    }
  }

  return (
    <Link to={'/game/' + gameID} >
      <Button color={ turnRequiredFlag ? 'green' : 'olive' } >
        <div>
          { name }<br />
          { '#' + gameID }
          { turnRequiredFlag ? <Icon color='red' name='exclamation' /> : null }
        </div>
      </Button>
    </Link>
  );
}
