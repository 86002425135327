import { Game } from "../../../bfcore/gameTypes";


export const standardIndianOceanGame: Game = {
    "_id": 0,  // To be filled in.
    "state": 3,
    "flags": 3137,
    "name": "Standard Indian Ocean",
    "uid": 2,
    "uName": "GameAdmin",
    "scenarioID": 50004,
    "scenarioName": "Standard Indian Ocean",
    "scenarioUID": 2,
    "scenarioUName": "GameAdmin",
    "factories": 9,
    "mapID": 5,
    "mapName": "Indian Ocean",
    "mapUID": 2,
    "mapUName": "GameAdmin",
    "mapFlags": 35,
    "mapXS": 512,
    "mapYS": 512,
    "mapData": "",
    "zones": 99,
    "zonesLand": 63,
    "zonesWater": 36,
    "zoneBonus": [],
    "zoneKey": [
        0,
        73,
        94,
        95,
        88,
        12,
        7,
        2,
        66,
        76,
        68,
        79,
        91,
        9,
        80,
        54,
        96,
        83,
        90,
        53,
        55,
        52,
        82,
        64,
        81,
        86,
        85,
        78,
        100,
        101,
        72,
        3,
        56,
        87,
        70,
        58,
        6,
        63,
        60,
        71,
        67,
        74,
        99,
        98,
        65,
        61,
        89,
        97,
        69,
        57,
        11,
        102,
        5,
        8,
        1,
        4,
        62,
        77,
        93,
        10,
        84,
        75,
        92,
        59,
        32,
        38,
        20,
        39,
        18,
        31,
        36,
        33,
        44,
        47,
        27,
        19,
        29,
        34,
        49,
        24,
        37,
        14,
        21,
        26,
        50,
        23,
        25,
        40,
        28,
        15,
        17,
        48,
        45,
        51,
        13,
        46,
        16,
        22,
        30,
        35
    ],
    "teams": 0,
    "players": 4,
    "humanPlayers": 4,
    "computerPlayers": 0,
    "pickingOrder": 1,
    "victoryCapitals": 0,
    "victoryIncome": 0,
    "victoryLandZones": 0,
    "victoryCash": 0,
    "maxDuration": 31536000,
    "maxTurns": 100,
    "regScore": [
        0,
        1000000
    ],
    "regMembership": [
        0,
        10
    ],
    "playTimes": [],
    "timeTurn": 1209600,
    "timeZonePicking": 1209600,
    "timeCapitalPicking": 1209600,
    "sourceGameID": 0,
    "privatePIN": 0,
    "turn": 100000000,
    "timeLimit": "",
    "required": 0,
    "noWait": 0,
    "submitted": 0,
    "autoPlay": 0,
    "teammatesSubmit": 0,
    "autoSpend": [
        100,
        100,
        100,
        100,
        100
    ],
    "autoMove": [
        100,
        100,
        100,
        100,
        100
    ],
    "slotName": [],
    "slotFlag": [],
    "playerID": [
        0,
        0,
        0,
        0,
        0
    ],
    "playerName": [
        "",
        "",
        "",
        "",
        ""
    ],
    "playerFlags": [
        0,
        1,
        1,
        1,
        1
    ],
    "playerFlag": [
        "",
        "us",
        "sa",
        "gb",
        "cn"
    ],
    "playerColor": [
        16777215,
        12544851,
        8158332,
        16382385,
        16764699
    ],
    "playerScore": [
        0,
        0,
        0,
        0,
        0
    ],
    "canBuy": [
        0,
        1023,
        1023,
        1023,
        1023
    ],
    "maxBuy": [
        0,
        0,
        0,
        0,
        0
    ],
    "showBuy": [
        0,
        1023,
        1023,
        1023,
        1023
    ],
    "finished": [
        [
            0,
            0
        ],
        [
            0,
            0
        ],
        [
            0,
            0
        ],
        [
            0,
            0
        ],
        [
            0,
            0
        ]
    ],
    "playerAutoTotal": [
        0,
        0,
        0,
        0,
        0
    ],
    "playerAutoCurrent": [
        0,
        0,
        0,
        0,
        0
    ],
    "playerMissedTotal": [
        0,
        0,
        0,
        0,
        0
    ],
    "playerMissedCurrent": [
        0,
        0,
        0,
        0,
        0
    ],
    "capital": [
        0,
        0,
        0,
        0,
        0
    ],
    "capitalX": [
        0,
        0,
        0,
        0,
        0
    ],
    "capitalY": [
        0,
        0,
        0,
        0,
        0
    ],
    "cash": [
        0,
        0,
        0,
        0,
        0
    ],
    "team": [],
    "fixedAlly": [
        1,
        2,
        4,
        8,
        16
    ],
    "declaredAlly": [
        0,
        0,
        0,
        0,
        0
    ],
    "force": [
        [],
        [
            [
                1,
                0,
                1,
                1,
                0,
                1,
                0,
                0,
                3504,
                6256,
                3504,
                6256
            ],
            [
                2,
                4,
                1,
                1,
                0,
                6,
                0,
                0,
                3504,
                6224,
                3504,
                6224
            ],
            [
                3,
                5,
                72,
                72,
                0,
                2,
                0,
                0,
                3968,
                6144,
                3968,
                6144
            ],
            [
                49,
                0,
                14,
                14,
                0,
                1,
                0,
                0,
                576,
                624,
                576,
                624
            ],
            [
                50,
                0,
                14,
                14,
                0,
                1,
                0,
                0,
                768,
                576,
                768,
                576
            ],
            [
                56,
                0,
                16,
                16,
                0,
                1,
                0,
                0,
                1408,
                896,
                1408,
                896
            ],
            [
                57,
                0,
                16,
                16,
                0,
                1,
                0,
                0,
                1472,
                896,
                1472,
                896
            ],
            [
                58,
                0,
                16,
                16,
                0,
                1,
                0,
                0,
                1728,
                1088,
                1728,
                1088
            ],
            [
                59,
                2,
                16,
                16,
                0,
                1,
                0,
                0,
                1472,
                1152,
                1472,
                1152
            ],
            [
                60,
                3,
                16,
                16,
                0,
                4,
                0,
                0,
                1728,
                1216,
                1728,
                1216
            ],
            [
                61,
                3,
                16,
                16,
                0,
                4,
                0,
                0,
                1280,
                1152,
                1280,
                1152
            ],
            [
                62,
                6,
                91,
                91,
                0,
                2,
                2,
                0,
                2048,
                1088,
                2048,
                1088
            ],
            [
                63,
                7,
                91,
                91,
                0,
                2,
                0,
                0,
                1744,
                880,
                1744,
                880
            ],
            [
                64,
                8,
                91,
                91,
                0,
                2,
                0,
                0,
                1856,
                1024,
                1856,
                1024
            ],
            [
                65,
                9,
                16,
                16,
                0,
                0,
                0,
                0,
                1280,
                896,
                1280,
                896
            ],
            [
                66,
                0,
                17,
                17,
                0,
                1,
                0,
                0,
                1600,
                1920,
                1600,
                1920
            ],
            [
                67,
                0,
                18,
                18,
                0,
                1,
                0,
                0,
                2112,
                1856,
                2112,
                1856
            ],
            [
                86,
                0,
                27,
                27,
                0,
                1,
                0,
                0,
                4032,
                2304,
                4032,
                2304
            ],
            [
                87,
                0,
                27,
                27,
                0,
                1,
                0,
                0,
                3968,
                2496,
                3968,
                2496
            ],
            [
                88,
                0,
                27,
                27,
                0,
                1,
                0,
                0,
                3904,
                2432,
                3904,
                2432
            ],
            [
                89,
                0,
                27,
                27,
                0,
                1,
                0,
                0,
                3712,
                2560,
                3712,
                2560
            ],
            [
                90,
                2,
                27,
                27,
                0,
                1,
                0,
                0,
                3904,
                2304,
                3904,
                2304
            ],
            [
                91,
                3,
                27,
                27,
                0,
                4,
                0,
                0,
                3520,
                2304,
                3520,
                2304
            ],
            [
                92,
                3,
                27,
                27,
                0,
                4,
                0,
                0,
                3712,
                2112,
                3712,
                2112
            ],
            [
                93,
                6,
                84,
                84,
                0,
                2,
                2,
                0,
                3328,
                2304,
                3328,
                2304
            ],
            [
                94,
                7,
                84,
                84,
                0,
                2,
                0,
                0,
                3392,
                2496,
                3392,
                2496
            ],
            [
                95,
                8,
                84,
                84,
                0,
                2,
                0,
                0,
                3264,
                2048,
                3264,
                2048
            ],
            [
                96,
                9,
                27,
                27,
                0,
                0,
                0,
                0,
                4160,
                2240,
                4160,
                2240
            ],
            [
                97,
                0,
                28,
                28,
                0,
                1,
                0,
                0,
                3792,
                2800,
                3792,
                2800
            ],
            [
                98,
                1,
                28,
                28,
                0,
                2,
                0,
                0,
                3808,
                2928,
                3808,
                2928
            ],
            [
                168,
                0,
                47,
                47,
                0,
                1,
                0,
                0,
                6912,
                3504,
                6912,
                3504
            ],
            [
                169,
                0,
                47,
                47,
                0,
                1,
                0,
                0,
                6816,
                3568,
                6816,
                3568
            ],
            [
                170,
                0,
                48,
                48,
                0,
                1,
                0,
                0,
                6128,
                3952,
                6128,
                3952
            ],
            [
                171,
                0,
                48,
                48,
                0,
                1,
                0,
                0,
                6288,
                3760,
                6288,
                3760
            ],
            [
                172,
                5,
                87,
                87,
                0,
                2,
                0,
                0,
                5376,
                3520,
                5376,
                3520
            ],
            [
                173,
                5,
                87,
                87,
                0,
                2,
                0,
                0,
                6528,
                3264,
                6528,
                3264
            ],
            [
                174,
                0,
                49,
                49,
                0,
                1,
                0,
                0,
                5888,
                4224,
                5888,
                4224
            ],
            [
                175,
                0,
                49,
                49,
                0,
                1,
                0,
                0,
                5952,
                4208,
                5952,
                4208
            ],
            [
                176,
                0,
                49,
                49,
                0,
                1,
                0,
                0,
                5952,
                4208,
                5952,
                4208
            ],
            [
                179,
                0,
                51,
                51,
                0,
                1,
                0,
                0,
                5504,
                4416,
                5504,
                4416
            ],
            [
                180,
                0,
                51,
                51,
                0,
                1,
                0,
                0,
                5376,
                4352,
                5376,
                4352
            ],
            [
                181,
                0,
                51,
                51,
                0,
                1,
                0,
                0,
                4992,
                3712,
                4992,
                3712
            ],
            [
                182,
                5,
                79,
                79,
                0,
                2,
                0,
                0,
                4992,
                3840,
                4992,
                3840
            ],
            [
                183,
                8,
                79,
                79,
                0,
                2,
                0,
                0,
                4096,
                4864,
                4096,
                4864
            ],
            [
                184,
                0,
                52,
                52,
                0,
                1,
                0,
                0,
                5584,
                4784,
                5584,
                4784
            ],
            [
                185,
                0,
                52,
                52,
                0,
                1,
                0,
                0,
                5600,
                4912,
                5600,
                4912
            ],
            [
                186,
                0,
                52,
                52,
                0,
                1,
                0,
                0,
                5584,
                4848,
                5584,
                4848
            ],
            [
                187,
                0,
                52,
                52,
                0,
                1,
                0,
                0,
                5984,
                4976,
                5984,
                4976
            ],
            [
                188,
                0,
                52,
                52,
                0,
                1,
                0,
                0,
                5632,
                4928,
                5632,
                4928
            ],
            [
                189,
                3,
                52,
                52,
                0,
                4,
                0,
                0,
                5888,
                4928,
                5888,
                4928
            ],
            [
                190,
                3,
                52,
                52,
                0,
                4,
                0,
                0,
                5600,
                4848,
                5600,
                4848
            ],
            [
                191,
                6,
                80,
                80,
                0,
                2,
                2,
                0,
                5056,
                4352,
                5056,
                4352
            ],
            [
                192,
                6,
                80,
                80,
                0,
                2,
                2,
                0,
                5888,
                4608,
                5888,
                4608
            ],
            [
                193,
                7,
                80,
                80,
                0,
                2,
                0,
                0,
                5568,
                4096,
                5568,
                4096
            ],
            [
                194,
                8,
                80,
                80,
                0,
                2,
                0,
                0,
                5632,
                4224,
                5632,
                4224
            ],
            [
                195,
                9,
                52,
                52,
                0,
                0,
                0,
                0,
                5696,
                4912,
                5696,
                4912
            ]
        ],
        [
            [
                74,
                0,
                20,
                20,
                0,
                1,
                0,
                0,
                2752,
                320,
                2752,
                320
            ],
            [
                75,
                0,
                21,
                21,
                0,
                1,
                0,
                0,
                2368,
                896,
                2368,
                896
            ],
            [
                76,
                0,
                22,
                22,
                0,
                1,
                0,
                0,
                3008,
                768,
                3008,
                768
            ],
            [
                77,
                0,
                22,
                22,
                0,
                1,
                0,
                0,
                2560,
                1088,
                2560,
                1088
            ],
            [
                78,
                0,
                23,
                23,
                0,
                1,
                0,
                0,
                3968,
                1280,
                3968,
                1280
            ],
            [
                79,
                0,
                24,
                24,
                0,
                1,
                0,
                0,
                3328,
                960,
                3328,
                960
            ],
            [
                80,
                1,
                24,
                24,
                0,
                2,
                0,
                0,
                3072,
                1472,
                3072,
                1472
            ],
            [
                81,
                0,
                25,
                25,
                0,
                1,
                0,
                0,
                3328,
                1792,
                3328,
                1792
            ],
            [
                82,
                0,
                25,
                25,
                0,
                1,
                0,
                0,
                3264,
                1792,
                3264,
                1792
            ],
            [
                83,
                3,
                25,
                25,
                0,
                4,
                0,
                0,
                3840,
                1536,
                3840,
                1536
            ],
            [
                84,
                0,
                26,
                26,
                0,
                1,
                0,
                0,
                4224,
                1728,
                4224,
                1728
            ],
            [
                85,
                0,
                26,
                26,
                0,
                1,
                0,
                0,
                4384,
                1648,
                4384,
                1648
            ],
            [
                101,
                0,
                30,
                30,
                0,
                1,
                0,
                0,
                4096,
                0,
                4096,
                0
            ],
            [
                102,
                0,
                31,
                31,
                0,
                1,
                0,
                0,
                4864,
                448,
                4864,
                448
            ],
            [
                103,
                0,
                32,
                32,
                0,
                1,
                0,
                0,
                4544,
                1600,
                4544,
                1600
            ],
            [
                104,
                0,
                32,
                32,
                0,
                1,
                0,
                0,
                4864,
                1920,
                4864,
                1920
            ],
            [
                105,
                6,
                86,
                86,
                0,
                2,
                2,
                0,
                4928,
                2624,
                4928,
                2624
            ],
            [
                106,
                0,
                33,
                33,
                0,
                1,
                0,
                0,
                6208,
                128,
                6208,
                128
            ],
            [
                107,
                0,
                34,
                34,
                0,
                1,
                0,
                0,
                6784,
                192,
                6784,
                192
            ],
            [
                108,
                0,
                35,
                35,
                0,
                1,
                0,
                0,
                6336,
                512,
                6336,
                512
            ],
            [
                109,
                0,
                35,
                35,
                0,
                1,
                0,
                0,
                6272,
                896,
                6272,
                896
            ],
            [
                110,
                1,
                35,
                35,
                0,
                2,
                0,
                0,
                6208,
                512,
                6208,
                512
            ],
            [
                111,
                0,
                36,
                36,
                0,
                1,
                0,
                0,
                6992,
                624,
                6992,
                624
            ],
            [
                112,
                0,
                36,
                36,
                0,
                1,
                0,
                0,
                6912,
                624,
                6912,
                624
            ],
            [
                113,
                0,
                36,
                36,
                0,
                1,
                0,
                0,
                7040,
                432,
                7040,
                432
            ],
            [
                114,
                1,
                36,
                36,
                0,
                2,
                0,
                0,
                6864,
                432,
                6864,
                432
            ],
            [
                115,
                2,
                36,
                36,
                0,
                1,
                0,
                0,
                7040,
                432,
                7040,
                432
            ],
            [
                116,
                3,
                36,
                36,
                0,
                4,
                0,
                0,
                7024,
                432,
                7024,
                432
            ],
            [
                117,
                5,
                98,
                98,
                0,
                2,
                0,
                0,
                6720,
                576,
                6720,
                576
            ],
            [
                118,
                6,
                98,
                98,
                0,
                2,
                2,
                0,
                7040,
                512,
                7040,
                512
            ],
            [
                119,
                8,
                98,
                98,
                0,
                2,
                0,
                0,
                7168,
                320,
                7168,
                320
            ],
            [
                120,
                9,
                36,
                36,
                0,
                0,
                0,
                0,
                6912,
                512,
                6912,
                512
            ],
            [
                121,
                0,
                37,
                37,
                0,
                1,
                0,
                0,
                5632,
                1088,
                5632,
                1088
            ],
            [
                122,
                1,
                37,
                37,
                0,
                2,
                0,
                0,
                5632,
                1600,
                5632,
                1600
            ],
            [
                123,
                0,
                38,
                38,
                0,
                1,
                0,
                0,
                6336,
                1088,
                6336,
                1088
            ],
            [
                124,
                0,
                38,
                38,
                0,
                1,
                0,
                0,
                6208,
                1280,
                6208,
                1280
            ],
            [
                125,
                0,
                38,
                38,
                0,
                1,
                0,
                0,
                6144,
                1472,
                6144,
                1472
            ],
            [
                126,
                3,
                38,
                38,
                0,
                4,
                0,
                0,
                6208,
                1216,
                6208,
                1216
            ],
            [
                127,
                4,
                38,
                38,
                0,
                6,
                0,
                0,
                6336,
                1280,
                6336,
                1280
            ],
            [
                128,
                6,
                97,
                97,
                0,
                2,
                2,
                0,
                7168,
                1792,
                7168,
                1792
            ],
            [
                129,
                8,
                97,
                97,
                0,
                2,
                0,
                0,
                7104,
                1920,
                7104,
                1920
            ],
            [
                130,
                0,
                39,
                39,
                0,
                1,
                0,
                0,
                5504,
                1856,
                5504,
                1856
            ],
            [
                131,
                0,
                39,
                39,
                0,
                1,
                0,
                0,
                5888,
                1648,
                5888,
                1648
            ],
            [
                132,
                0,
                39,
                39,
                0,
                1,
                0,
                0,
                5760,
                1856,
                5760,
                1856
            ],
            [
                133,
                0,
                39,
                39,
                0,
                1,
                0,
                0,
                6016,
                1984,
                6016,
                1984
            ],
            [
                134,
                0,
                40,
                40,
                0,
                1,
                0,
                0,
                5568,
                2688,
                5568,
                2688
            ],
            [
                135,
                0,
                40,
                40,
                0,
                1,
                0,
                0,
                5632,
                2176,
                5632,
                2176
            ],
            [
                136,
                0,
                40,
                40,
                0,
                1,
                0,
                0,
                5632,
                2688,
                5632,
                2688
            ],
            [
                137,
                2,
                40,
                40,
                0,
                1,
                0,
                0,
                5696,
                2688,
                5696,
                2688
            ],
            [
                138,
                3,
                40,
                40,
                0,
                4,
                0,
                0,
                5312,
                2176,
                5312,
                2176
            ],
            [
                139,
                0,
                41,
                41,
                0,
                1,
                0,
                0,
                7856,
                368,
                7856,
                368
            ],
            [
                140,
                0,
                41,
                41,
                0,
                1,
                0,
                0,
                7984,
                112,
                7984,
                112
            ],
            [
                157,
                0,
                44,
                44,
                0,
                1,
                0,
                0,
                6624,
                2016,
                6624,
                2016
            ],
            [
                158,
                0,
                44,
                44,
                0,
                1,
                0,
                0,
                6592,
                1968,
                6592,
                1968
            ],
            [
                159,
                0,
                44,
                44,
                0,
                1,
                0,
                0,
                6720,
                1984,
                6720,
                1984
            ],
            [
                160,
                2,
                44,
                44,
                0,
                1,
                0,
                0,
                6656,
                1952,
                6656,
                1952
            ],
            [
                161,
                3,
                44,
                44,
                0,
                4,
                0,
                0,
                6704,
                1904,
                6704,
                1904
            ],
            [
                162,
                4,
                44,
                44,
                0,
                6,
                0,
                0,
                6656,
                1952,
                6656,
                1952
            ],
            [
                165,
                0,
                46,
                46,
                0,
                1,
                0,
                0,
                6656,
                3392,
                6656,
                3392
            ],
            [
                166,
                0,
                46,
                46,
                0,
                1,
                0,
                0,
                6528,
                2624,
                6528,
                2624
            ],
            [
                167,
                4,
                46,
                46,
                0,
                6,
                0,
                0,
                6512,
                2544,
                6512,
                2544
            ],
            [
                232,
                0,
                63,
                63,
                0,
                1,
                0,
                0,
                7568,
                7840,
                7568,
                7840
            ]
        ],
        [
            [
                4,
                0,
                2,
                2,
                0,
                1,
                0,
                0,
                1728,
                5696,
                1728,
                5696
            ],
            [
                5,
                0,
                2,
                2,
                0,
                1,
                0,
                0,
                1760,
                5296,
                1760,
                5296
            ],
            [
                6,
                3,
                2,
                2,
                0,
                4,
                0,
                0,
                1664,
                6144,
                1664,
                6144
            ],
            [
                7,
                6,
                71,
                71,
                0,
                2,
                2,
                0,
                2048,
                6400,
                2048,
                6400
            ],
            [
                141,
                0,
                42,
                42,
                0,
                1,
                0,
                0,
                7792,
                560,
                7792,
                560
            ],
            [
                142,
                0,
                42,
                42,
                0,
                1,
                0,
                0,
                7744,
                1152,
                7744,
                1152
            ],
            [
                143,
                0,
                42,
                42,
                0,
                1,
                0,
                0,
                7840,
                880,
                7840,
                880
            ],
            [
                144,
                0,
                42,
                42,
                0,
                1,
                0,
                0,
                7792,
                688,
                7792,
                688
            ],
            [
                145,
                2,
                42,
                42,
                0,
                1,
                0,
                0,
                7552,
                1088,
                7552,
                1088
            ],
            [
                146,
                3,
                42,
                42,
                0,
                4,
                0,
                0,
                7808,
                704,
                7808,
                704
            ],
            [
                147,
                3,
                42,
                42,
                0,
                4,
                0,
                0,
                7872,
                688,
                7872,
                688
            ],
            [
                148,
                5,
                99,
                99,
                0,
                2,
                0,
                0,
                7936,
                1280,
                7936,
                1280
            ],
            [
                149,
                6,
                99,
                99,
                0,
                2,
                2,
                0,
                8128,
                576,
                8128,
                576
            ],
            [
                150,
                7,
                99,
                99,
                0,
                2,
                0,
                0,
                7936,
                1344,
                7936,
                1344
            ],
            [
                151,
                8,
                99,
                99,
                0,
                2,
                0,
                0,
                8128,
                640,
                8128,
                640
            ],
            [
                152,
                9,
                42,
                42,
                0,
                0,
                0,
                0,
                7856,
                688,
                7856,
                688
            ],
            [
                153,
                0,
                43,
                43,
                0,
                1,
                0,
                0,
                7152,
                1264,
                7152,
                1264
            ],
            [
                154,
                0,
                43,
                43,
                0,
                1,
                0,
                0,
                7088,
                1328,
                7088,
                1328
            ],
            [
                155,
                0,
                43,
                43,
                0,
                1,
                0,
                0,
                7296,
                1200,
                7296,
                1200
            ],
            [
                156,
                3,
                43,
                43,
                0,
                4,
                0,
                0,
                7040,
                1600,
                7040,
                1600
            ],
            [
                163,
                0,
                45,
                45,
                0,
                1,
                0,
                0,
                5824,
                2480,
                5824,
                2480
            ],
            [
                164,
                5,
                96,
                96,
                0,
                2,
                0,
                0,
                6016,
                2304,
                6016,
                2304
            ],
            [
                177,
                0,
                50,
                50,
                0,
                1,
                0,
                0,
                6336,
                4464,
                6336,
                4464
            ],
            [
                178,
                8,
                81,
                81,
                0,
                2,
                0,
                0,
                6528,
                4096,
                6528,
                4096
            ],
            [
                196,
                0,
                53,
                53,
                0,
                1,
                0,
                0,
                6496,
                5040,
                6496,
                5040
            ],
            [
                197,
                0,
                53,
                53,
                0,
                1,
                0,
                0,
                6608,
                5040,
                6608,
                5040
            ],
            [
                198,
                0,
                53,
                53,
                0,
                1,
                0,
                0,
                6144,
                5040,
                6144,
                5040
            ],
            [
                199,
                0,
                53,
                53,
                0,
                1,
                0,
                0,
                6272,
                5104,
                6272,
                5104
            ],
            [
                204,
                0,
                56,
                56,
                0,
                1,
                0,
                0,
                7040,
                5568,
                7040,
                5568
            ],
            [
                205,
                0,
                56,
                56,
                0,
                1,
                0,
                0,
                6976,
                5760,
                6976,
                5760
            ],
            [
                206,
                0,
                57,
                57,
                0,
                1,
                0,
                0,
                6016,
                6912,
                6016,
                6912
            ],
            [
                207,
                0,
                57,
                57,
                0,
                1,
                0,
                0,
                5952,
                6592,
                5952,
                6592
            ],
            [
                208,
                1,
                57,
                57,
                0,
                2,
                0,
                0,
                6016,
                6192,
                6016,
                6192
            ],
            [
                209,
                2,
                57,
                57,
                0,
                1,
                0,
                0,
                6144,
                6976,
                6144,
                6976
            ],
            [
                210,
                3,
                57,
                57,
                0,
                4,
                0,
                0,
                6272,
                7168,
                6272,
                7168
            ],
            [
                211,
                6,
                74,
                74,
                0,
                2,
                2,
                0,
                5760,
                5824,
                5760,
                5824
            ],
            [
                212,
                8,
                74,
                74,
                0,
                2,
                0,
                0,
                5888,
                6272,
                5888,
                6272
            ],
            [
                213,
                9,
                57,
                57,
                0,
                0,
                0,
                0,
                6272,
                6976,
                6272,
                6976
            ],
            [
                214,
                0,
                58,
                58,
                0,
                1,
                0,
                0,
                6592,
                6528,
                6592,
                6528
            ],
            [
                215,
                0,
                58,
                58,
                0,
                1,
                0,
                0,
                6528,
                6336,
                6528,
                6336
            ],
            [
                216,
                0,
                59,
                59,
                0,
                1,
                0,
                0,
                7104,
                6656,
                7104,
                6656
            ],
            [
                217,
                0,
                60,
                60,
                0,
                1,
                0,
                0,
                7744,
                6336,
                7744,
                6336
            ],
            [
                218,
                0,
                60,
                60,
                0,
                1,
                0,
                0,
                7808,
                6656,
                7808,
                6656
            ],
            [
                219,
                3,
                60,
                60,
                0,
                4,
                0,
                0,
                7744,
                6528,
                7744,
                6528
            ],
            [
                220,
                4,
                60,
                60,
                0,
                6,
                0,
                0,
                7552,
                6720,
                7552,
                6720
            ],
            [
                221,
                0,
                61,
                61,
                0,
                1,
                0,
                0,
                6784,
                6784,
                6784,
                6784
            ],
            [
                222,
                0,
                62,
                62,
                0,
                1,
                0,
                0,
                7744,
                7104,
                7744,
                7104
            ],
            [
                223,
                0,
                62,
                62,
                0,
                1,
                0,
                0,
                7808,
                7296,
                7808,
                7296
            ],
            [
                224,
                0,
                62,
                62,
                0,
                1,
                0,
                0,
                7680,
                7552,
                7680,
                7552
            ],
            [
                225,
                2,
                62,
                62,
                0,
                1,
                0,
                0,
                7296,
                7232,
                7296,
                7232
            ],
            [
                226,
                3,
                62,
                62,
                0,
                4,
                0,
                0,
                7296,
                7424,
                7296,
                7424
            ],
            [
                227,
                3,
                62,
                62,
                0,
                4,
                0,
                0,
                7360,
                7552,
                7360,
                7552
            ],
            [
                228,
                6,
                69,
                69,
                0,
                2,
                2,
                0,
                8064,
                7360,
                8064,
                7360
            ],
            [
                229,
                7,
                69,
                69,
                0,
                2,
                0,
                0,
                8128,
                7488,
                8128,
                7488
            ],
            [
                230,
                8,
                69,
                69,
                0,
                2,
                0,
                0,
                8128,
                7680,
                8128,
                7680
            ],
            [
                231,
                9,
                62,
                62,
                0,
                0,
                0,
                0,
                7616,
                7040,
                7616,
                7040
            ]
        ],
        [
            [
                8,
                0,
                3,
                3,
                0,
                1,
                0,
                0,
                192,
                6272,
                192,
                6272
            ],
            [
                9,
                0,
                3,
                3,
                0,
                1,
                0,
                0,
                0,
                6400,
                0,
                6400
            ],
            [
                10,
                1,
                3,
                3,
                0,
                2,
                0,
                0,
                0,
                6592,
                0,
                6592
            ],
            [
                11,
                1,
                3,
                3,
                0,
                2,
                0,
                0,
                384,
                6400,
                384,
                6400
            ],
            [
                12,
                2,
                3,
                3,
                0,
                1,
                0,
                0,
                512,
                6208,
                512,
                6208
            ],
            [
                13,
                3,
                3,
                3,
                0,
                4,
                0,
                0,
                0,
                6208,
                0,
                6208
            ],
            [
                14,
                3,
                3,
                3,
                0,
                4,
                0,
                0,
                192,
                6528,
                192,
                6528
            ],
            [
                15,
                3,
                3,
                3,
                0,
                4,
                0,
                0,
                576,
                6464,
                576,
                6464
            ],
            [
                16,
                3,
                3,
                3,
                0,
                4,
                0,
                0,
                448,
                6272,
                448,
                6272
            ],
            [
                17,
                6,
                64,
                64,
                0,
                2,
                2,
                0,
                320,
                7168,
                320,
                7168
            ],
            [
                18,
                6,
                64,
                64,
                0,
                2,
                2,
                0,
                1024,
                6976,
                1024,
                6976
            ],
            [
                19,
                7,
                64,
                64,
                0,
                2,
                0,
                0,
                1216,
                7104,
                1216,
                7104
            ],
            [
                20,
                7,
                64,
                64,
                0,
                2,
                0,
                0,
                1280,
                7104,
                1280,
                7104
            ],
            [
                21,
                9,
                3,
                3,
                0,
                0,
                0,
                0,
                576,
                6208,
                576,
                6208
            ],
            [
                22,
                0,
                4,
                4,
                0,
                1,
                0,
                0,
                576,
                5696,
                576,
                5696
            ],
            [
                23,
                0,
                5,
                5,
                0,
                1,
                0,
                0,
                704,
                4864,
                704,
                4864
            ],
            [
                24,
                0,
                6,
                6,
                0,
                1,
                0,
                0,
                1152,
                5184,
                1152,
                5184
            ],
            [
                25,
                0,
                6,
                6,
                0,
                1,
                0,
                0,
                1024,
                4608,
                1024,
                4608
            ],
            [
                26,
                0,
                7,
                7,
                0,
                1,
                0,
                0,
                448,
                3776,
                448,
                3776
            ],
            [
                27,
                0,
                7,
                7,
                0,
                1,
                0,
                0,
                1088,
                3840,
                1088,
                3840
            ],
            [
                28,
                1,
                7,
                7,
                0,
                2,
                0,
                0,
                448,
                3968,
                448,
                3968
            ],
            [
                29,
                6,
                77,
                77,
                0,
                2,
                2,
                0,
                1344,
                4288,
                1344,
                4288
            ],
            [
                30,
                0,
                8,
                8,
                0,
                1,
                0,
                0,
                384,
                2368,
                384,
                2368
            ],
            [
                31,
                0,
                8,
                8,
                0,
                1,
                0,
                0,
                0,
                2880,
                0,
                2880
            ],
            [
                32,
                0,
                9,
                9,
                0,
                1,
                0,
                0,
                1856,
                2880,
                1856,
                2880
            ],
            [
                33,
                5,
                83,
                83,
                0,
                2,
                0,
                0,
                2304,
                2944,
                2304,
                2944
            ],
            [
                34,
                0,
                10,
                10,
                0,
                1,
                0,
                0,
                960,
                2496,
                960,
                2496
            ],
            [
                35,
                0,
                11,
                11,
                0,
                1,
                0,
                0,
                384,
                1664,
                384,
                1664
            ],
            [
                36,
                0,
                12,
                12,
                0,
                1,
                0,
                0,
                320,
                704,
                320,
                704
            ],
            [
                37,
                0,
                12,
                12,
                0,
                1,
                0,
                0,
                192,
                832,
                192,
                832
            ],
            [
                38,
                0,
                12,
                12,
                0,
                1,
                0,
                0,
                64,
                704,
                64,
                704
            ],
            [
                39,
                1,
                12,
                12,
                0,
                2,
                0,
                0,
                448,
                768,
                448,
                768
            ],
            [
                40,
                2,
                12,
                12,
                0,
                1,
                0,
                0,
                192,
                896,
                192,
                896
            ],
            [
                41,
                3,
                12,
                12,
                0,
                4,
                0,
                0,
                192,
                320,
                192,
                320
            ],
            [
                42,
                6,
                94,
                94,
                0,
                2,
                2,
                0,
                176,
                112,
                176,
                112
            ],
            [
                43,
                8,
                94,
                94,
                0,
                2,
                0,
                0,
                464,
                48,
                464,
                48
            ],
            [
                44,
                9,
                12,
                12,
                0,
                0,
                0,
                0,
                0,
                1152,
                0,
                1152
            ],
            [
                45,
                0,
                13,
                13,
                0,
                1,
                0,
                0,
                704,
                48,
                704,
                48
            ],
            [
                46,
                1,
                13,
                13,
                0,
                2,
                0,
                0,
                640,
                128,
                640,
                128
            ],
            [
                47,
                2,
                13,
                13,
                0,
                1,
                0,
                0,
                576,
                0,
                576,
                0
            ],
            [
                48,
                3,
                13,
                13,
                0,
                4,
                0,
                0,
                640,
                256,
                640,
                256
            ],
            [
                51,
                0,
                15,
                15,
                0,
                1,
                0,
                0,
                832,
                512,
                832,
                512
            ],
            [
                52,
                0,
                15,
                15,
                0,
                1,
                0,
                0,
                1088,
                448,
                1088,
                448
            ],
            [
                53,
                2,
                15,
                15,
                0,
                1,
                0,
                0,
                1408,
                256,
                1408,
                256
            ],
            [
                54,
                3,
                15,
                15,
                0,
                4,
                0,
                0,
                1152,
                640,
                1152,
                640
            ],
            [
                55,
                3,
                15,
                15,
                0,
                4,
                0,
                0,
                1472,
                256,
                1472,
                256
            ],
            [
                68,
                0,
                19,
                19,
                0,
                1,
                0,
                0,
                1728,
                256,
                1728,
                256
            ],
            [
                69,
                0,
                19,
                19,
                0,
                1,
                0,
                0,
                1728,
                448,
                1728,
                448
            ],
            [
                70,
                1,
                19,
                19,
                0,
                2,
                0,
                0,
                2048,
                0,
                2048,
                0
            ],
            [
                71,
                2,
                19,
                19,
                0,
                1,
                0,
                0,
                2048,
                192,
                2048,
                192
            ],
            [
                72,
                3,
                19,
                19,
                0,
                4,
                0,
                0,
                1984,
                448,
                1984,
                448
            ],
            [
                73,
                3,
                19,
                19,
                0,
                4,
                0,
                0,
                1856,
                64,
                1856,
                64
            ],
            [
                99,
                0,
                29,
                29,
                0,
                1,
                0,
                0,
                3952,
                3504,
                3952,
                3504
            ],
            [
                100,
                6,
                85,
                85,
                0,
                2,
                2,
                0,
                4160,
                3136,
                4160,
                3136
            ],
            [
                200,
                0,
                54,
                54,
                0,
                1,
                0,
                0,
                7040,
                4480,
                7040,
                4480
            ],
            [
                201,
                6,
                82,
                82,
                0,
                2,
                2,
                0,
                7360,
                4288,
                7360,
                4288
            ],
            [
                202,
                0,
                55,
                55,
                0,
                1,
                0,
                0,
                7808,
                4992,
                7808,
                4992
            ],
            [
                203,
                3,
                55,
                55,
                0,
                4,
                0,
                0,
                7520,
                4912,
                7520,
                4912
            ]
        ]
    ],
    "losses": [
        [
            0,
            0,
            0,
            0,
            0
        ],
        [
            0,
            0,
            0,
            0,
            0
        ],
        [
            0,
            0,
            0,
            0,
            0
        ],
        [
            0,
            0,
            0,
            0,
            0
        ],
        [
            0,
            0,
            0,
            0,
            0
        ]
    ],
    "missedAllowed": 2,
    "autoTotal": 0,
    "autoCurrent": 0,
    "autoAllowed": 2,
    "teamName": [
        ""
    ],
    "zoneFlags": [
        0,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2
    ],
    "zoneName": [
        "",
        "Kerguelen",
        "Madagasgar",
        "South Africa",
        "Zimbabwe",
        "Zambia",
        "Mozambique",
        "Kenya",
        "Congo",
        "Somalia",
        "Ethiopia",
        "Sudan",
        "Egypt",
        "Israel",
        "Jordan",
        "Iraq",
        "Saudi Arabia",
        "Western Arabia",
        "Yemen",
        "Iran",
        "Afghanistan",
        "West Pakistan",
        "East Pakistan",
        "Nepal",
        "Rajasthan",
        "North India",
        "Bangladesh",
        "Central India",
        "South India",
        "Sri Lanka",
        "Tajikistan",
        "Tibet",
        "Myanmar",
        "Gansu",
        "Russia",
        "Shandong",
        "Korea",
        "Yunnan",
        "Shanghai",
        "Guangdong",
        "Thailand",
        "Hokkaido",
        "Honshu",
        "Kyushu",
        "Taiwan",
        "Hainan",
        "North Philippines",
        "South Philippines",
        "North Borneo",
        "South Borneo",
        "Cebu",
        "Sumatra",
        "Java",
        "Timor",
        "Papau",
        "Papau New Guinea",
        "Northern Territory",
        "Western Australia",
        "Outback",
        "Central Australia",
        "Queensland",
        "South Australia",
        "New South Wales",
        "Tasmania",
        "Cape Of Good Hope",
        "Walters Sea",
        "Croset Basin",
        "Poinsett Sea",
        "South Australian Basin",
        "Tasman Sea",
        "Natal Basin",
        "Crozet Basin",
        "Kerguelen Sea",
        "Diamantina Sea",
        "West Australian Basin",
        "Arafura Sea",
        "Coral Sea",
        "Comoro Sea",
        "Mascarene Basin",
        "Wharton Basin",
        "Sumatra Sea",
        "Banda Sea",
        "Papau Sea",
        "Somali Basin",
        "Arabian Sea",
        "Ceylon Sea",
        "Andaman Basin",
        "Palawan Trough",
        "Philippine Sea",
        "Mariana Basin",
        "Gulf Of Aden",
        "Persian Gulf",
        "Gulf Of Oman",
        "Red Sea",
        "Mediterranean Sea",
        "Bay Of Bengal",
        "South China Sea",
        "Taiwan Sea",
        "Sea Of Japan",
        "Honshu Sea"
    ],
    "zoneIncome": [
        0,
        2,
        3,
        10,
        1,
        1,
        2,
        3,
        2,
        1,
        1,
        1,
        8,
        1,
        2,
        6,
        10,
        1,
        2,
        6,
        1,
        1,
        2,
        1,
        2,
        3,
        2,
        10,
        2,
        2,
        1,
        1,
        2,
        1,
        1,
        3,
        8,
        2,
        5,
        4,
        6,
        3,
        10,
        4,
        6,
        1,
        3,
        2,
        3,
        3,
        2,
        4,
        10,
        3,
        2,
        2,
        2,
        6,
        3,
        2,
        4,
        1,
        10,
        1,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "bordersLand": [
        [],
        [],
        [],
        [
            4
        ],
        [
            3,
            6,
            5
        ],
        [
            4,
            6,
            7,
            8
        ],
        [
            4,
            5,
            7
        ],
        [
            5,
            6,
            8,
            9
        ],
        [
            5,
            7,
            9,
            10,
            11
        ],
        [
            7,
            8,
            10
        ],
        [
            9,
            8,
            11
        ],
        [
            8,
            10,
            12
        ],
        [
            11,
            13
        ],
        [
            14,
            15,
            12
        ],
        [
            17,
            16,
            15,
            13
        ],
        [
            14,
            16,
            19,
            13
        ],
        [
            18,
            17,
            14,
            15
        ],
        [
            18,
            16,
            14
        ],
        [
            17,
            16
        ],
        [
            21,
            15,
            20
        ],
        [
            22,
            21,
            30,
            19
        ],
        [
            22,
            19,
            20
        ],
        [
            24,
            21,
            20,
            23,
            30
        ],
        [
            26,
            25,
            32,
            24,
            22
        ],
        [
            25,
            22,
            23
        ],
        [
            27,
            26,
            24,
            23
        ],
        [
            27,
            25,
            32,
            23
        ],
        [
            28,
            26,
            25
        ],
        [
            27
        ],
        [],
        [
            31,
            22,
            20
        ],
        [
            37,
            33,
            30
        ],
        [
            40,
            37,
            26,
            23
        ],
        [
            37,
            31,
            35,
            34
        ],
        [
            36,
            35,
            33
        ],
        [
            37,
            38,
            33,
            34
        ],
        [
            34
        ],
        [
            40,
            32,
            39,
            38,
            31,
            33,
            35
        ],
        [
            39,
            37,
            35
        ],
        [
            40,
            37,
            38
        ],
        [
            32,
            39,
            37
        ],
        [],
        [
            43
        ],
        [
            42
        ],
        [],
        [],
        [],
        [],
        [
            49
        ],
        [
            48
        ],
        [],
        [],
        [],
        [],
        [
            55
        ],
        [
            54
        ],
        [
            60,
            59,
            58
        ],
        [
            61,
            58
        ],
        [
            61,
            57,
            59,
            56
        ],
        [
            61,
            60,
            58,
            56
        ],
        [
            62,
            61,
            59,
            56
        ],
        [
            62,
            57,
            60,
            58,
            59
        ],
        [
            61,
            60
        ],
        [],
        [
            3
        ],
        [],
        [],
        [],
        [
            57,
            61,
            62
        ],
        [
            62,
            63
        ],
        [
            2,
            3,
            4,
            6
        ],
        [
            2
        ],
        [
            1
        ],
        [],
        [
            57,
            58
        ],
        [
            55,
            56,
            58
        ],
        [
            55,
            56,
            60,
            62
        ],
        [
            2,
            6,
            7
        ],
        [],
        [
            51
        ],
        [
            40,
            49,
            51,
            52
        ],
        [
            48,
            49,
            50,
            52,
            53
        ],
        [
            54,
            55
        ],
        [
            7,
            9
        ],
        [
            25,
            27,
            28
        ],
        [
            27,
            28,
            29
        ],
        [
            32,
            40,
            51
        ],
        [
            40,
            46,
            47,
            48,
            49
        ],
        [
            46,
            47
        ],
        [],
        [
            9,
            10,
            18
        ],
        [
            15,
            16,
            19,
            21
        ],
        [
            16,
            18,
            21,
            22,
            24,
            25
        ],
        [
            10,
            11,
            12,
            13,
            14,
            17,
            18
        ],
        [
            12,
            13
        ],
        [
            26,
            27,
            32
        ],
        [
            39,
            40,
            45,
            46
        ],
        [
            38,
            39,
            43,
            44,
            46
        ],
        [
            34,
            35,
            36,
            38,
            41,
            42,
            43
        ],
        [
            41,
            42,
            43
        ]
    ],
    "bordersWater": [
        [],
        [
            72
        ],
        [
            71,
            70,
            77
        ],
        [
            64,
            70
        ],
        [
            70
        ],
        [],
        [
            70,
            77
        ],
        [
            77,
            83
        ],
        [],
        [
            83,
            90
        ],
        [
            90,
            93
        ],
        [
            93
        ],
        [
            94,
            93
        ],
        [
            93,
            94
        ],
        [
            93
        ],
        [
            91
        ],
        [
            91,
            92
        ],
        [
            93
        ],
        [
            90,
            93,
            92
        ],
        [
            91
        ],
        [],
        [
            92,
            91
        ],
        [
            92
        ],
        [],
        [
            92
        ],
        [
            84,
            92
        ],
        [
            95
        ],
        [
            84,
            85,
            95
        ],
        [
            85,
            84
        ],
        [
            85
        ],
        [],
        [],
        [
            86,
            95
        ],
        [],
        [
            98
        ],
        [
            98
        ],
        [
            98
        ],
        [],
        [
            97,
            98
        ],
        [
            96,
            97
        ],
        [
            80,
            87,
            86,
            96
        ],
        [
            98,
            99
        ],
        [
            99,
            98
        ],
        [
            97,
            99,
            98
        ],
        [
            97
        ],
        [
            96
        ],
        [
            87,
            88,
            96,
            97
        ],
        [
            88,
            87
        ],
        [
            81,
            87
        ],
        [
            81,
            80,
            87
        ],
        [
            81
        ],
        [
            79,
            80,
            86
        ],
        [
            80,
            81
        ],
        [
            81
        ],
        [
            82
        ],
        [
            76,
            75,
            82
        ],
        [
            76,
            75
        ],
        [
            74,
            68
        ],
        [
            74,
            75
        ],
        [],
        [
            76
        ],
        [
            68
        ],
        [
            69,
            68,
            76
        ],
        [
            69
        ],
        [
            65,
            71,
            70
        ],
        [
            64,
            66,
            71
        ],
        [
            65,
            67,
            73,
            71,
            72
        ],
        [
            66,
            69,
            68,
            73,
            74
        ],
        [
            69,
            67,
            74
        ],
        [
            67,
            68,
            76
        ],
        [
            64,
            71,
            77
        ],
        [
            65,
            64,
            66,
            72,
            70,
            78,
            77
        ],
        [
            66,
            71,
            73,
            78,
            79
        ],
        [
            66,
            67,
            74,
            72,
            75,
            79,
            81,
            80
        ],
        [
            67,
            68,
            73,
            75
        ],
        [
            74,
            76,
            73,
            81,
            82
        ],
        [
            69,
            75,
            82
        ],
        [
            70,
            71,
            78,
            83
        ],
        [
            72,
            71,
            79,
            77,
            83,
            85,
            84
        ],
        [
            72,
            73,
            80,
            78,
            86,
            85
        ],
        [
            73,
            81,
            79,
            87,
            86
        ],
        [
            75,
            73,
            80,
            82,
            88,
            87
        ],
        [
            75,
            81,
            76,
            88,
            89
        ],
        [
            77,
            78,
            84,
            90
        ],
        [
            78,
            83,
            85,
            90,
            92
        ],
        [
            79,
            78,
            86,
            84,
            95
        ],
        [
            79,
            85,
            80,
            95
        ],
        [
            80,
            81,
            88,
            96
        ],
        [
            82,
            81,
            87,
            89,
            97
        ],
        [
            82,
            88,
            97,
            99
        ],
        [
            83,
            84,
            93,
            92
        ],
        [
            92
        ],
        [
            90,
            84,
            91
        ],
        [
            90,
            94
        ],
        [
            93
        ],
        [
            85,
            86
        ],
        [
            87,
            97
        ],
        [
            88,
            96,
            89,
            99,
            98
        ],
        [
            97,
            99
        ],
        [
            89,
            97,
            98
        ]
    ],
    "zoneOwner": [
        0,
        1,
        3,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        1,
        4,
        1,
        1,
        1,
        4,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        1,
        4,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        3,
        3,
        2,
        3,
        2,
        1,
        1,
        1,
        3,
        1,
        1,
        3,
        4,
        4,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        2,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "zoneOwnerPrev": [
        0,
        1,
        3,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        1,
        4,
        1,
        1,
        1,
        4,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        1,
        4,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        3,
        3,
        2,
        3,
        2,
        1,
        1,
        1,
        3,
        1,
        1,
        3,
        4,
        4,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        2,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "forceID": [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10
    ],
    "forceName": [
        "Troop",
        "Tank",
        "SAM Launcher",
        "Fighter",
        "Bomber",
        "Submarine",
        "Transport",
        "Carrier",
        "Destroyer",
        "Factory"
    ],
    "forceNamePlural": [
        "Troops",
        "Tanks",
        "SAM Launchers",
        "Fighters",
        "Bombers",
        "Submarines",
        "Transports",
        "Carriers",
        "Destroyers",
        "Factories"
    ],
    "forceDescription": [
        "Standard Troop",
        "Standard Tank",
        "Standard SAM Launcher",
        "Standard Fighter",
        "Standard Bomber",
        "Standard Submarine",
        "Standard Transport",
        "Standard Aircraft Carrier",
        "Standard Destroyer",
        "Standard Factory"
    ],
    "forceFlags": [
        198485,
        198485,
        235477,
        75501,
        75501,
        3162,
        2099034 - 64,
        1882,
        1882,
        260046849
    ],
    "forceCost": [
        10,
        15,
        15,
        30,
        35,
        20,
        20,
        35,
        25,
        45
    ],
    "forceRange": [
        1,
        2,
        1,
        4,
        6,
        2,
        2,
        2,
        2,
        0
    ],
    "forceAttack": [
        15,
        50,
        0,
        50,
        64,
        30,
        0,
        34,
        50,
        0
    ],
    "forceDefense": [
        30,
        30,
        20,
        64,
        15,
        30,
        0,
        34,
        50,
        0
    ],
    "forceCargoCapacity": [
        0,
        0,
        0,
        0,
        0,
        0,
        2,
        0,
        0,
        0
    ],
    "forceCargoSpace": [
        1,
        1,
        1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1
    ],
    "forceCarrierCapacity": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        4,
        0,
        0
    ],
    "forceCarrierSpace": [
        -1,
        -1,
        -1,
        1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1
    ],
    "forceLiftCapacity": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "forceLiftSpace": [
        1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1
    ],
    "forceProduction": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        1
    ],
    "forceZ": [
        2,
        2,
        2,
        3,
        3,
        2,
        2,
        2,
        2,
        1
    ],
    "forceScale": [
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        0.85,
        1,
        1
    ],
    "forceSfxMake": [
        "troopbuild",
        "tankbuild",
        "sambuild",
        "fplanebuild",
        "bplanebuild",
        "subbuild",
        "transportbuild",
        "carrierbuild",
        "cruiserbuild",
        "factorybuild"
    ],
    "forceSfxMove": [
        "troopmove",
        "tankmove",
        "sammove",
        "fplanemove",
        "bplanemove",
        "submove",
        "transportmove",
        "carriermove",
        "cruisermove",
        "factorymove"
    ],
    "forceSfxDie": [
        "troopgonzo",
        "tankgonzo",
        "samgonzo",
        "fplanegonzo",
        "bplanegonzo",
        "subgonzo",
        "transportgonzo",
        "carriergonzo",
        "cruisergonzo",
        "factorygonzo"
    ],
    "forceVersion": [
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1
    ],
    "year": 2000,
    "nextForceID": 233,
    "replay": {},
    "created": "2024-08-15T01:01:49.849Z",
    "started": "",
    "ended": "",
    "zoneBoxXSize": 16,
    "zoneBoxYSize": 16,
    "mapXScale": 1,
    "mapYScale": 1,
    "zoneBoxX": [
        0,
        219,
        104,
        20,
        20,
        16,
        60,
        48,
        12,
        68,
        52,
        20,
        12,
        44,
        48,
        72,
        92,
        72,
        116,
        116,
        176,
        144,
        188,
        240,
        212,
        232,
        264,
        232,
        236,
        248,
        264,
        296,
        300,
        372,
        436,
        388,
        438,
        340,
        392,
        376,
        336,
        497,
        485,
        452,
        413,
        364,
        408,
        424,
        385,
        368,
        403,
        324,
        357,
        396,
        456,
        478,
        440,
        376,
        400,
        440,
        476,
        432,
        476,
        476,
        48,
        112,
        208,
        340,
        420,
        484,
        72,
        136,
        216,
        312,
        356,
        448,
        500,
        112,
        184,
        272,
        340,
        404,
        480,
        132,
        192,
        256,
        300,
        368,
        444,
        480,
        128,
        110,
        164,
        52,
        16,
        276,
        372,
        428,
        460,
        496
    ],
    "zoneBoxY": [
        0,
        394,
        356,
        392,
        352,
        292,
        300,
        232,
        172,
        176,
        152,
        92,
        48,
        12,
        48,
        24,
        68,
        96,
        124,
        16,
        8,
        52,
        52,
        72,
        80,
        108,
        112,
        148,
        180,
        208,
        4,
        20,
        108,
        8,
        8,
        44,
        35,
        88,
        84,
        120,
        148,
        15,
        55,
        80,
        130,
        149,
        176,
        224,
        243,
        264,
        279,
        252,
        307,
        316,
        287,
        307,
        356,
        420,
        396,
        392,
        408,
        436,
        456,
        493,
        444,
        492,
        480,
        476,
        460,
        480,
        372,
        376,
        368,
        360,
        376,
        332,
        344,
        268,
        264,
        288,
        272,
        268,
        256,
        196,
        148,
        192,
        180,
        216,
        180,
        144,
        152,
        55,
        96,
        92,
        7,
        148,
        164,
        120,
        24,
        56
    ],
    "navyZone": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "startingForces": [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        []
    ]
};
