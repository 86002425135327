/*
  BaseGameLoadingError.js
  (c) Human Cube Inc.
*/

import BaseMenuBasic from './BaseMenuBasic';
import background from './img/background/BurntMirage2000.jpg';


export default function BaseGameLoadingError () {
  return (
    <div
      className="full-bg-size"
      style={{
        backgroundImage: `url(${background})`,
        height: '100vh'
      }}
    >
      <BaseMenuBasic pageName='Unable To Load Game' />
      <center>
        <h3>
          Unable to load game. Please try again later.
        </h3>
        <h5>
          This could be due to a poor internet connection, a server error or an unavailable game.
          <br />
          The first one is on you, we will take care of the other two.
        </h5>
      </center>
    </div>
  );
}
