/*
  solo/index.tsx
  (c) Human Cube Inc.

  Root page for solo unauthenticated pages.
*/

import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Button } from 'semantic-ui-react';

import { LocalSoloGameList } from '../../components/BaseGui/BaseLocalSoloGames';
import background from '../../components/BaseGui/img/background/fleet1920x1200.jpg';


export default function SoloPage ({store}: {store: any}) {
  const navigate = useNavigate();

  return (
    <div
      className='full-bg-size bg-slate-500'
      style={{
        backgroundImage: `url(${background})`,
        height: '100vh',
        width: '100vw',
      }}
    >
      <div className='flex justify-center items-center h-screen'>
        <div className='flex flex-col'>
          <div className='p-6 border-slate-300 border-2 rounded-md bg-slate-400 bg-opacity-50'>
            <div className='mb-3 text-slate-100 text-bold text-5xl'>
              BATTLE FORCES Solitaire
            </div>
            <div className='text-xl text-slate-100'>Your Active Games (stored on this browser/device only):</div>
            <LocalSoloGameList
              uid={store.userSession?.uid}
              startNewButton = {
                <div
                  className='self-center m-1'
                  onMouseUp={() => { navigate('startgame'); }}
                >
                  <Button color='olive' >START A NEW GAME</Button>
                </div>
              }
            />

            <div className='flex flex-row justify-between pt-6 '>
              <Link to='/rules/landwar'>
                <Button inverted color='olive' size='small'>Land War Rules</Button>
              </Link>
              <Link to='/rules/standard'>
                <Button inverted color='olive' size='small'>Standard Rules</Button>
              </Link>
              <Link to='/rules/forcetypes'>
                <Button inverted color='olive' size='small'>Force Types</Button>
              </Link>
            </div>
          </div>
          <div className='flex flex-row mt-32 text-white justify-center'>
              Version { store.version }
              &nbsp;&nbsp;&nbsp;
              BATTLE FORCES &copy; 2024 Human Cube Inc.
          </div>
        </div>
      </div>
    </div>
  );
}
