/*
  BaseMapIcon.js
  (c) Human Cube Inc.
*/

import { Image } from 'semantic-ui-react';

import mapIcon1 from './img/mapicon/0_44_44.jpg';
// import mapIcon2 from './img/mapicon/2_44_44.jpg';
import mapIcon3 from './img/mapicon/3_44_44.jpg';
import mapIcon4 from './img/mapicon/4_44_44.jpg';
import mapIcon5 from './img/mapicon/5_44_44.jpg';
import mapIcon6 from './img/mapicon/6_44_44.jpg';
import mapIconN from './img/mapicon/n_44_44.jpg';


export default function BaseMapIcon({ mapID }) {
  let icon = mapIconN;
  if(mapID === 1) {
    icon = mapIcon1;
  }
  else if(mapID === 3) {
    icon = mapIcon3;
  }
  else if(mapID === 4) {
    icon = mapIcon4;
  }
  else if(mapID === 5) {
    icon = mapIcon5;
  }
  else if(mapID === 6) {
    icon = mapIcon6;
  }
  return <Image src={ icon } />;
}
