/*
  BasePlayerDetail.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { Button, Card, Image, Statistic } from 'semantic-ui-react';

import BaseAdSense from './BaseAdSense';
import BaseBackScroll from './BaseBackScroll';
import BaseFlag from './BaseFlag';
import BaseMenuBasic from './BaseMenuBasic';
import BaseRank from './BaseRank';
import { sendPacket } from '../../network/network';
import BF from '../../bfcore/bfconst1';
import bfH from '../../bfcore/bf_helper';
import computerAvatar from './img/avatar/computer.png';
import background from './img/background/soldiermountains.jpg';


const LOCAL_NO_AUTH_UID = 2000000000;

function ordinalStr (n) {
  return n + (['','st','nd','rd'][~~(n/10%10)-1?n%10:0] || 'th');
}

function gameItemRender (key, text, d0, d1, d2, d3) {
  d0 = parseInt(d0, 10);
  if(d0) {
    const completed = Math.floor(d0 / 1000000);
    const won = Math.floor(d0 % 1000000);

    let completedStr = '';
    if(completed) {
      completedStr = ' (ranked ' + ordinalStr(d1 + 1) + ')';
    }
    let wonStr = '';
    if(d2 && won) {
      wonStr = ' (ranked ' + ordinalStr(d3 + 1) + ')';
    }

    return (
      <Card.Content key={ key } >
        <b>{ text }</b>
        <br />
        Completed: <b>{ completed }</b>
        { completedStr && completedStr }
        <br />
        Won: <b>{ won }</b>
        { wonStr && wonStr }
      </Card.Content>
    );
  }
  return null;
}

function playerStatsRender (data) {
  if(!Array.isArray(data)) {
    return null;
  }

  const stats = [];

  // TODO: later, there are some score and xp ranking info we do not display since we already show
  // totals. Try to expose these some way:
  //     if(data[0])
  //         s += '<b>Score</b><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>' + data[0] + '</b> (ranked: <b>' + ordinalStr(data[1] + 1) + '</b>)<br />';
  //     if(data[2])
  //         s += '<b>XP</b><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>' + data[2] + '</b> (ranked: <b>' + ordinalStr(data[3] + 1) + '</b>)<br /><br />';

  let solo = gameItemRender('ksolo', 'Solo Games', data[4], data[5], data[12], data[13]);
  if(solo) {
    stats.push(solo);
  }
  let heads = gameItemRender('kheads', 'Heads-Up Games', data[6], data[7], data[14], data[15]);
  if(heads) {
    stats.push(heads);
  }
  let multi = gameItemRender('kmulti', 'Multiplayer Games', data[8], data[9], data[16], data[17]);
  if(multi) {
    stats.push(multi);
  }
  let team = gameItemRender('kteam', 'Team Games', data[10], data[11], data[18], data[19]);
  if(team) {
    stats.push(team);
  }

  let turns = parseInt(data[20], 10);
  if(!turns) {
    turns = 0;
  }
  let missed = parseInt(data[22], 10);
  if(!missed) {
    missed = 0;
  }
  let auto = parseInt(data[24], 10);
  if(!auto) {
    auto = 0;
  }
  if(turns || missed || auto) {
    const total = turns + missed + auto;

    stats.push(
      <Card.Content key='kctotal' >
        <b>Heads-Up, Multiplayer and Team Games</b><br />
        Turns Submitted:
        <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <b>{ turns + (total ? (' (' + Math.round(turns * 100 / total) + '%)') : '') }</b>
         { turns ? ' ranked ' + ordinalStr(data[21] + 1) + ' best. ' : '' }
        <br />
        Turns Missed:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <b>{ missed + (total ? (' (' + Math.round(missed * 100 / total) + '%)') : '') }</b>
          { missed ? ' ranked ' + ordinalStr(data[23] + 1) + ' worst. ' : '' }
        <br />
        Turns Auto-Played:&nbsp;&nbsp;&nbsp;<b>{ auto }</b>
      </Card.Content>
    );
  }

  return stats;
}

export default class BasePlayerDetail extends React.Component {
  state = {
    store: {},
  };

  constructor(props) {
    super(props);  // Always call the parent class' constructor.
    this.state.store = props.store;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store !== this.state.store) {
      this.setState({
        store: nextProps.store,
      });
    }
  }

  addToFriends = (e) => {
    const { playerID } = this.state.store;
    sendPacket('bf_get', {e: 'friendAdd', friend: playerID});
  };

  removeFromFriends = (e) => {
    const { playerID } = this.state.store;
    sendPacket('bf_get', {e: 'friendRemove', friend: playerID});
  };

  addToBlockList = (e) => {
    const { playerID } = this.state.store;
    sendPacket('bf_get', {e: 'blockAdd', block: playerID});
  };

  removeFromBlockList = (e) => {
    const { playerID } = this.state.store;
    sendPacket('bf_get', {e: 'blockRemove', block: playerID});
  };

  render() {
    const { blockList, friendFoeList, playerID, playerInfos, playerStats, playerStrings }
          = this.state.store;
    const { uid } = this.state.store.userSession;

    let textDiv = null;
    let uName = null;
    let membershipText = null;
    let cc = '';
    let rank = 0;

    let avatar = null;

    let stats = null;

    if(playerID < 0) {
      uName = 'COMPUTER PLAYER';
      avatar = computerAvatar;
      textDiv = null;
    }
    else if(playerID === LOCAL_NO_AUTH_UID) {
      uName = 'LocalGuest';
      // avatar = computerAvatar;
      textDiv = <div className='text-lg'>This player is you.</div>;
    }
    else if(playerID > 0 && playerInfos[playerID]) {
      const playerInfo = playerInfos[playerID];
      uName = playerInfo.uName;
      avatar = playerInfo.avatar;
      membershipText = bfH.membershipLevelAsString(playerInfo.membershipLevel) + ' Member';
      cc = playerInfo.cc;
      rank = playerInfo.rank;
      if(uName !== 'GameAdmin') {
        textDiv =
          <div>
            <BaseRank rank={ rank } /><br/>
            { BF.PLAYER_RANK_TITLE[rank] }<br/>
          </div>;
        stats = [
          { key: 'score', label: 'Score', value: playerInfo.score },
          { key: 'xp', label: 'XP', value: playerInfo.xp },
          { key: 'plays', label: 'Plays', value: playerInfo.plays },
        ];
      }
      else {
        membershipText = 'BATTLE FORCES Administrator';
      }
    }
    else if(playerID > 0 && playerStrings[playerID]) {
      const d = playerStrings[playerID].split('|');
      uName = d[1];
      rank = parseInt(d[2], 36);
      const membershipLevel = parseInt(d[3], 36);
      membershipText = bfH.membershipLevelAsString(membershipLevel) + ' Member';
      const score = parseInt(d[4], 36);
      const xp = parseInt(d[5], 36);
      const plays = parseInt(d[6], 36);
      if(d[7]) {
        cc = d[7];
      }
      if(uName !== 'GameAdmin') {
        textDiv =
          <div>
            <BaseRank rank={ rank } /><br/>
            { BF.PLAYER_RANK_TITLE[rank] }<br/>
          </div>;
        stats = [
          { key: 'score', label: 'Score', value: score },
          { key: 'xp', label: 'XP', value: xp },
          { key: 'plays', label: 'Plays', value: plays },
        ];
      }
      else {
        membershipText = 'BATTLE FORCES Administrator';
      }
    }

    // Later TODO: add display of their Friends And Foes list.

    // Low TODO: friend and block lists are not automatically fetched for a session, might have to
    // make an explicit call if not already fetched earlier in the session. This is low since it
    // auto resolves when the initial block or friend request is made.

    let friendAddButton = null;
    let blockAddButton = null;

    if(playerID > 0 && playerID !== uid && uName !== 'GameAdmin') {
      if(friendFoeList[playerID]) {
        friendAddButton =
          <Button color='olive' size='small' onPointerUp={ this.removeFromFriends } >
            Remove From Friends and Foes
          </Button>;
      }
      else {
        friendAddButton =
          <Button color='olive' size='small' onPointerUp={ this.addToFriends } >
            Add To Friends And Foes
          </Button>;
      }

      if(blockList[playerID]) {
        blockAddButton =
          <Button color='olive' size='small' onPointerUp={ this.removeFromBlockList } >
            Remove From Block List
          </Button>;
      }
      else {
        blockAddButton =
          <Button color='olive' size='small' onPointerUp={ this.addToBlockList } >
            Add To Block List
          </Button>;
      }
    }

    return (
      <BaseBackScroll background={ background } >
        <BaseMenuBasic pageName='Player Details and Stats' />
        <center>
          <br /><br /><br />
          <BaseAdSense />
        </center>
        <br />
        <Card.Group centered>
          <Card>
            <Image src={ avatar } wrapped ui={false} />
            <Card.Content>
              <Card.Header>{ uName }</Card.Header>
              <Card.Meta>
                { membershipText }
              </Card.Meta>
              <Card.Description>
                <center>
                  <div style={{display: 'flex',
                               alignItems: 'center',
                               justifyContent: 'center',
                             }}>
                    { textDiv }
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    { (cc && uName !== 'GameAdmin') &&
                      <BaseFlag cc={ cc } />
                    }
                  </div>
                </center>
                { (playerID > 0 && uName !== 'GameAdmin') &&
                  <Statistic.Group size='mini' widths='three' items={ stats } />
                }
              </Card.Description>
            </Card.Content>
            { (playerID > 0 && uName !== 'GameAdmin') &&
              <Card.Content extra>
                <div style={{margin: '3px'}}>{ friendAddButton }</div>
                <div style={{margin: '3px'}}>{ blockAddButton }</div>
              </Card.Content>
            }
          </Card>
          { (playerID > 0 && playerID !== LOCAL_NO_AUTH_UID && uName !== 'GameAdmin') &&
            <Card>
              <Card.Content key='kk0' >
                <Card.Header>{ uName } Statistics</Card.Header>
              </Card.Content>
              { playerStatsRender(playerStats[playerID]) }
            </Card>
          }
        </Card.Group>
        <br /><br /><br />
        <br /><br /><br />
      </BaseBackScroll>
    );
  }
}
