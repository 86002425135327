/*
  Force.tsx
  (c) Human Cube Inc.
*/

import { Sprite } from '@pixi/react';
import * as PIXI from 'pixi.js';

import fi1 from './img/forcetype/fi1.png';
import fi2 from './img/forcetype/fi2.png';
import fi3 from './img/forcetype/fi3.png';
import fi4 from './img/forcetype/fi4.png';
import fi5 from './img/forcetype/fi5.png';
import fi6 from './img/forcetype/fi6.png';
import fi7 from './img/forcetype/fi7.png';
import fi8 from './img/forcetype/fi8.png';
import fi9 from './img/forcetype/fi9.png';
import fi10 from './img/forcetype/fi10.png';
import fi11 from './img/forcetype/fi11.png';
import fi12 from './img/forcetype/fi12.png';
import fi13 from './img/forcetype/fi13.png';
import fi14 from './img/forcetype/fi14.png';
import fi15 from './img/forcetype/fi15.png';
import fi16 from './img/forcetype/fi16.png';
import fi17 from './img/forcetype/fi17.png';
import fi18 from './img/forcetype/fi18.png';
import fi19 from './img/forcetype/fi19.png';
import { NumberDictionary } from '../../helper/types';


const textures: NumberDictionary<any> = {
  1: fi1,
  2: fi2,
  3: fi3,
  4: fi4,
  5: fi5,
  6: fi6,
  7: fi7,
  8: fi8,
  9: fi9,
  10: fi10,
  11: fi11,
  12: fi12,
  13: fi13,
  14: fi14,
  15: fi15,
  16: fi16,
  17: fi17,
  18: fi18,
  19: fi19,
};

const centerAnchor = new PIXI.Point(0.5, 0.5);

export default function Force ({type, spriteProps}: {type: number, spriteProps: any}) {
  let texture = '';
  if(textures[type]) {
    texture = textures[type];
  }
  return (
    <Sprite
      anchor={ centerAnchor }
      texture={ PIXI.Texture.from(texture) }
      { ...spriteProps }
    />
  );
}
