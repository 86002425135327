/*
  GuiChatPlayerButton.tsx
  (c) Human Cube Inc.
*/

import { Button, Flag, FlagNameValues, Icon } from 'semantic-ui-react';

import { chatFetchMoreIfNeeded, chatMarkReadIfApplicable } from '../../helper/chat';


function GuiChatPlayerButton (
  {
    flag,
    gameID,
    index,
    name,
    selected,
    teamGame,
    unread,
    selectPlayer,
  }:
  {
    flag: string;
    gameID: number;
    index: number;
    name: string;
    selected: boolean;
    teamGame: boolean;
    unread: boolean;
    selectPlayer: any;
  }
) {

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  const choosePlayer = (e: any) => {
    stopPropagation(e);
    if(selectPlayer) {
      selectPlayer(index);
    }
    chatFetchMoreIfNeeded(gameID, teamGame, index);
    chatMarkReadIfApplicable(gameID, teamGame, index);
  };

  let notification = null;
  if(unread) {
    notification = <Icon corner='top right' name='circle' color='red' />;
  }

  if(selected) {
    if(index === 0) {
      return <Button color='green' onPointerUp={ stopPropagation } size='small'>
                <Icon.Group>
                  ALL
                  { notification }
                </Icon.Group>
              </Button>;
    }
    if(index === 1 && teamGame) {
      return <Button color='green' onPointerUp={ stopPropagation } size='small'>
                <Icon.Group>
                  TEAM
                  { notification }
                </Icon.Group>
              </Button>;
    }
    return <Button color='green' onPointerUp={ stopPropagation } size='small'>
              <Icon.Group>
                <Flag name={ flag as FlagNameValues } />
                { notification }
              </Icon.Group>
            </Button>;
  }

  if(index === 0) {
    return <Button inverted onPointerUp={ choosePlayer } size='small'>
              <Icon.Group>
                ALL
                { notification }
              </Icon.Group>
            </Button>;
  }
  if(index === 1 && teamGame) {
    return <Button inverted onPointerUp={ choosePlayer } size='small'>
              <Icon.Group>
                TEAM
                { notification }
              </Icon.Group>
            </Button>;
  }
  return <Button inverted onPointerUp={ choosePlayer } size='small'>
            <Icon.Group>
              <Flag name={ flag as FlagNameValues } />
              { notification }
            </Icon.Group>
          </Button>;
}

export default GuiChatPlayerButton;
