/*
  BaseFooter.js
  (c) Human Cube Inc.
*/

export default function BaseFooter () {
  return (
    <center style={{color: 'white'}}>
      BATTLE FORCES &copy; 2024 Human Cube Inc.
    </center>
  );
}
