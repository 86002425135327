/*
  solo/startgame/index.tsx
  (c) Human Cube Inc.
*/

import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { localSoloGameCreateLandWarAfrica, localSoloGameCreateStandardIndianOcean, localSoloGameCreateWorldWarII } from '../../../solo/localSoloGame';
import BaseAdSense from '../../../components/BaseGui/BaseAdSense';
import BaseMenuBasic from '../../../components/BaseGui/BaseMenuBasic';
import BaseMapIcon from '../../../components/BaseGui/BaseMapIcon';
import background from '../../../components/BaseGui/img/background/airforce77911.jpg';


export default function SoloStartGamePage ({ store }: { store: any }) {
  const navigate = useNavigate();

  const { uid, uName } = store.userSession;

  return (
    <div
      className='full-bg-size bg-slate-500'
      style={{
        backgroundImage: `url(${background})`,
        height: '100vh',
        width: '100vw',
      }}
    >
      <BaseMenuBasic pageName='Solo Game Start' />
      <div className='flex justify-center items-center h-screen w-screen'>
        <div className='flex flex-col'>
          <div className='p-6 border-slate-300 border-2 rounded-md bg-slate-400 bg-opacity-50'
            style={{ maxWidth: 728 }}>
            <div>
              <BaseAdSense />
            </div>
            <div className='p-3 rounded text-lg text-slate-50 bg-slate-500'>
              Select a game below to start. You may join up to three solo games at a time.<br />
              The games run locally in your browser and can be resumed at a later time only on this device.
            </div>  
            <div className='mt-2 flex flex-row flex-wrap'>
              <div className='m-1'>
                <Button
                  positive
                  onClick={ (e) => {
                    localSoloGameCreateLandWarAfrica(uid, uName);
                    navigate(-1);
                  }}
                >
                  <div className='flex flex-row items-center space-x-3 w-[286px]'>
                    <div><BaseMapIcon mapID={ 3 } /></div>
                    <div style={{width: 238}}>Land War Africa Intro<br />(try this easy one first!)</div>
                  </div>
                </Button>
              </div>  
              <div className='m-1'>
                <Button
                  positive
                  onClick={ (e) => {
                    localSoloGameCreateStandardIndianOcean(uid, uName);
                    navigate(-1);
                  }}
                >
                  <div className='flex flex-row items-center space-x-3 w-[286px]'>
                    <div><BaseMapIcon mapID={ 5 } /></div>
                    <div style={{width: 238}}>Indian Ocean</div>
                  </div>
                </Button>
              </div>  
              <div className='m-1'>
                <Button
                  positive
                  onClick={ (e) => {
                    localSoloGameCreateWorldWarII(uid, uName, 2);
                    navigate(-1);
                  }}
                >
                  <div className='flex flex-row items-center space-x-3 w-[286px]'>
                    <div><BaseMapIcon mapID={ 4 } /></div>
                    <div style={{width: 238}}>World War II<br />(team game, playing Germany)</div>
                  </div>
                </Button>
              </div>
              <div className='m-1'>
                <Button
                  positive
                  onClick={ (e) => {
                    localSoloGameCreateWorldWarII(uid, uName, 4);
                    navigate(-1);
                  }}
                >
                  <div className='flex flex-row items-center space-x-3 w-[286px]'>
                    <div><BaseMapIcon mapID={ 4 } /></div>
                    <div style={{width: 238}}>World War II<br />(team game, playing Japan)</div>
                  </div>
                </Button>
              </div>  
              <div className='m-1'>
                <Button
                  positive
                  onClick={ (e) => {
                    localSoloGameCreateWorldWarII(uid, uName, 1);
                    navigate(-1);
                  }}
                >
                  <div className='flex flex-row items-center space-x-2 w-[286px]'>
                    <div><BaseMapIcon mapID={ 4 } /></div>
                    <div style={{width: 238}}>World War II<br />(team game, playing Russia)</div>
                  </div>
                </Button>
              </div>  
              <div className='m-1'>
                <Button
                  positive
                  onClick={ (e) => {
                    localSoloGameCreateWorldWarII(uid, uName, 3);
                    navigate(-1);
                  }}
                >
                  <div className='flex flex-row items-center space-x-2 w-[286px]'>
                    <div><BaseMapIcon mapID={ 4 } /></div>
                    <div style={{width: 238}}>World War II<br />(team game, playing United Kingdom)</div>
                  </div>
                </Button>
              </div>  
              <div className='m-1'>
                <Button
                  positive
                  onClick={ (e) => {
                    localSoloGameCreateWorldWarII(uid, uName, 5);
                    navigate(-1);
                  }}
                >
                  <div className='flex flex-row items-center space-x-2 w-[286px]'>
                    <div><BaseMapIcon mapID={ 4 } /></div>
                    <div style={{width: 238}}>World War II<br />(team game, playing United States)</div>
                  </div>
                </Button>
              </div>  
            </div>  
          </div>
        </div>
      </div>
    </div>
  );
}
