import { store } from '../app/store';
import actions from '../reducers/actions';
import { sendPacket } from '../network/network';

const CHAT_PAGENEWSIZE = 10;  // Initial fetch count.
const CHAT_PAGEOLDSIZE = 20;  // Older posts fetch count.

export function chatTotalMessages (gameID, playerIndex) {
  // Returns total number of potential messages for a given channel.
  // 0 if unknown (if the meta had not been loaded yet).
  const chatMeta = store.getState().gameChatMeta;
  if(chatMeta[gameID] && Array.isArray(chatMeta[gameID].CID)) {
    return chatMeta[gameID].CID[playerIndex];
  }
  return 0;
}

export function chatAnyUnreadsForGame (gameID) {
  // Returns true if there are 1 or more unread chat messages for the game else false.
  const chatMeta = store.getState().gameChatMeta;
  if(chatMeta[gameID] && Array.isArray(chatMeta[gameID].CID) &&
      Array.isArray(chatMeta[gameID].RCID) ) {
    for(let i = 0; i < chatMeta[gameID].CID.length; i++) {
      if(chatMeta[gameID].CID[i]) {
        if(!chatMeta[gameID].RCID[i] || chatMeta[gameID].RCID[i] < chatMeta[gameID].CID[i]) {
          return true;
        }
      }
    }
  }
  return false;
}

export function chatUnreadsForGame (gameID) {
  // Returns an object keyed by playerIndex of true/undefined if there are unread chat messages.
  const ret = {};
  const chatMeta = store.getState().gameChatMeta;
  if(chatMeta[gameID] && Array.isArray(chatMeta[gameID].CID) &&
      Array.isArray(chatMeta[gameID].RCID)) {
    for(let i = 0; i < chatMeta[gameID].CID.length; i++) {
      if(chatMeta[gameID].CID[i]) {
        if(!chatMeta[gameID].RCID[i] || chatMeta[gameID].RCID[i] < chatMeta[gameID].CID[i]) {
          ret[i] = true;
        }
      }
    }
  }
  return ret;
}

export function chatFetchMoreIfNeeded (gameID, teamGame, playerIndex) {
  // Fetches most recent chats, if they exist (according to prefetched meta).
  const key = gameID + '|' + playerIndex;
  const chatData = store.getState().gameChatData;
  const chatMeta = store.getState().gameChatMeta;
  let start = 0;
  let count = 0;

  let fetchedCID = 0;
  if(Array.isArray(chatData[key]) && chatData[key].length > 0) {
    fetchedCID = chatData[key][chatData[key].length - 1][0];
  }
  if(chatMeta[gameID] && Array.isArray(chatMeta[gameID].CID)) {
    if(chatMeta[gameID].CID[playerIndex]) {
      let availableCID = chatMeta[gameID].CID[playerIndex];
      count = availableCID - fetchedCID;
      if(count > 0) {
        start = fetchedCID + 1;
        if(count > CHAT_PAGENEWSIZE) {
          if(start === 1) {
            start = availableCID - CHAT_PAGENEWSIZE + 1;
          }
          count = CHAT_PAGENEWSIZE;
        }
      }
    }
  }

  if(count > 0) {

    // TODO low: may need to prevent multiple fetches here if one is already in progress.

    if(playerIndex === 0) {
      sendPacket('bf_get', {e: 'chatGetPublic', gameID, start, count});
    }
    else if(teamGame) {
      sendPacket('bf_get', {e: 'chatGetTeam', gameID, start, count});
    }
    else {
      sendPacket('bf_get', {e: 'chatGetPlayer', gameID, playerIndex, start, count});
    }
  }
}

export function chatFetchOlder (gameID, teamGame, playerIndex) {
  // Fetch the next chunk of older chat for the given game and channel.
  const key = gameID + '|' + playerIndex;
  const chatData = store.getState().gameChatData;
  let start = 0;
  let count = 0;

  if(Array.isArray(chatData[key]) && chatData[key].length > 0) {
    const oldestFetchedCID = chatData[key][0][0];
    const available = oldestFetchedCID - 1;
    if(available > 0) {
      if(available < CHAT_PAGEOLDSIZE) {
        start = 1;
        count = available;
      }
      else {
        start = oldestFetchedCID - CHAT_PAGEOLDSIZE;
        count = CHAT_PAGEOLDSIZE;
      }
    }
  }

  if(count > 0) {

    // TODO low: may need to prevent multiple fetches here if one is already in progress.

    if(playerIndex === 0) {
      sendPacket('bf_get', {e: 'chatGetPublic', gameID, start, count});
    }
    else if(teamGame) {
      sendPacket('bf_get', {e: 'chatGetTeam', gameID, start, count});
    }
    else {
      sendPacket('bf_get', {e: 'chatGetPlayer', gameID, playerIndex, start, count});
    }
  }
}

export function parseChatString (chatString) {
  const chat = {
    cid: 0,
    version: 1,
    turnIndex: 0,
    time: 0,
    playerIndex: 0,
    uid: 0,
    uName: '',
    text: '',
  };

  if(chatString) {
    const t = chatString.split('|');
    chat.cid = parseInt(t[0], 36);
    chat.version = parseInt(t[1], 36);  // Chat format version string. Currently always 1.
    chat.turnIndex = parseInt(t[2], 36);
    chat.time = parseInt(t[3], 36);
    chat.playerIndex = parseInt(t[4], 36);
    chat.uid = parseInt(t[5], 36);
    chat.uName = t[6];
    chat.text = t[7]; // escapeHTML(t[7]).replace(/(?:\r\n|\r|\n)/g, '<br />');
  }

  return chat;
}

export function chatMarkReadIfApplicable (gameID, teamGame, playerIndex) {
  const key = gameID + '|' + playerIndex;
  const chatData = store.getState().gameChatData;
  const chatMeta = store.getState().gameChatMeta;

  if(!Array.isArray(chatData[key]) || chatData[key].length < 1 ||
      !chatMeta[gameID] || !Array.isArray(chatMeta[gameID].RCID)) {
    return;  // no data to work with.
  }
  const chatID = chatData[key][chatData[key].length - 1][0];
  if(chatID > chatMeta[gameID].RCID[playerIndex]) {
    store.dispatch({type: actions.CHAT_META_SET_SINGLE_RCID, gameID, playerIndex, rcid: chatID});
    if(playerIndex === 0) {
      sendPacket('bf_get', {e: 'chatReadPublic', gameID, chatID});
    }
    else if(teamGame) {
      sendPacket('bf_get', {e: 'chatReadTeam', gameID, chatID});
    }
    else {
      sendPacket('bf_get', {e: 'chatReadPlayer', gameID, playerIndex, chatID});
    }
  }
}
