/*
  reducers.js
  (c) Human Cube Inc.
*/

import cloneDeep from 'clone-deep';
import { combineReducers } from 'redux';
import sha256 from 'crypto-js/sha256';

import actions from './actions';
import mainModes from './mainModes';
import BF from '../bfcore/bfconst1';
import { gameChatData, gameChatMeta } from './reducer-chat';
import { mapEdit, mapEditSaved, mapEditSelectedZoneID, mapEditZoom } from './reducer-mapEdit';
import { scenarioEdit, scenarioEditSaved } from './reducer-scenarioEdit';
import working from './reducer-working';
import { sendPacket } from '../network/network';
import { getNewZoneXY } from '../bfcore/bf_hitmap';


const NEWS_MAX_ITEMS = 1000;

const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                 navigator.userAgent &&
                 navigator.userAgent.indexOf('CriOS') === -1 &&
                 navigator.userAgent.indexOf('FxiOS') === -1;

function version (state = process.env.REACT_APP_VERSION, action) {
  return state;
}

function soloNoAuthMode (
  state = (window.location.pathname === 'solo') ||
          (window.location.pathname === 'solo/') ||
          (window.location.pathname === '/solo') ||
          (window.location.pathname === '/solo/'),
  action,
) {
  return state;
}

function mainMode (state = mainModes.START_UP, action) {
  switch (action.type) {
    case actions.SET_MAIN_MODE:
      return action.mode;
    case actions.SET_GAME:
      if(state === mainModes.GAME_LOADING ||
          state === mainModes.GAME_DOING_TURN ||
          state === mainModes.GAME_SUBMITTING_TURN) {
        return mainModes.GAME_REPLAY;
      }
      return state;
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      return action.where.mode;
    case actions.GO_HOME:
      return mainModes.HOME;
    default:
      return state;
  }
}

function notifyModals (state = [], action) {
  // Array element 0 is the top one to display.
  // NOTIFY_MODAL_POP removes element 0.
  // NOTIFY_MODAL_PUSH adds to end of array.
  switch (action.type) {
    case actions.NOTIFY_MODAL_POP:
      if(state.length > 0) {
        return state.slice(1);
      }
      return state;
    case actions.NOTIFY_MODAL_PUSH:
      const newState = state.slice();
      newState.push({title: action.title, body: action.body});
      return newState;
    default:
      return state;
  }
}

function backStack (state = [], action) {
  switch (action.type) {
    case actions.NAVIGATE_TO:
      return state.concat(action.where);
    case actions.NAVIGATE_BACK:
      if(state.length > 0) {
        return(state.slice(0, -1));
      }
      return [];
    case actions.GO_HOME:
      // Flush back stack if jumping direct to home.
      return [];
    default:
      return state;
  }
}

function server (state = {
    serverSecondsOffset: 0,
    session: {},
  }, action) {
  switch (action.type) {
    case actions.SET_SERVER_SECONDS_OFFSET:
      return { ...state, serverSecondsOffset: action.offset };
    default:
      return state;
  }
}

function userSession (state = {}, action) {
  switch (action.type) {
    case actions.SET_USER_SESSION_DATA:
      // fetch some info about their games.
      // TODO: this should be optimized to not happen every connect.
      if(action.data.bf &&
          Array.isArray(action.data.bf.game) && action.data.bf.game.length > 0) {
        sendPacket('bf_get', {e: 'gameString', gameID: action.data.bf.game});
      }
      return action.data;
    case actions.SUBMIT_LOGIN:
      let password = action.password;
      if(password.length !== 64) {
        password = '' + sha256('SPX' + password);
      }
      sendPacket('hc_login', { uName: action.userName, password });
      return {};
    case actions.MEMBERSHIP_CHANGE:
      {const newState = cloneDeep(state);
      if(!newState.bf) {
        newState.bf = {};
      }
      newState.bf.rank = action.rank;
      newState.bf.membershipLevel = action.membershipLevel;
      newState.bf.membershipExpire = action.membershipExpire;
      return newState;}
    case actions.USER_SESSION_UPDATE_SOME_BF:
      {
        const newState = cloneDeep(state);
        if(!newState.bf) {
          newState.bf = {};
        }
        if(action.hasOwnProperty('flags')) {
          newState.bf.flags = action.flags;
        }
        if(action.hasOwnProperty('finishedCount')) {
          newState.bf.finishedCount = action.finishedCount;
        }
        if(action.hasOwnProperty('game')) {
          newState.bf.game = action.game;
        }
        if(action.hasOwnProperty('membershipExpire')) {
          newState.bf.membershipExpire = action.membershipExpire;
        }
        if(action.hasOwnProperty('membershipLevel')) {
          newState.bf.membershipLevel = action.membershipLevel;
        }
        if(action.hasOwnProperty('rank')) {
          newState.bf.rank = action.rank;
        }
        if(action.hasOwnProperty('score')) {
          newState.bf.score = action.score;
        }
        if(action.hasOwnProperty('xp')) {
          newState.bf.xp = action.xp;
        }
        return newState;
      }
    case actions.SCENARIO_EDIT_SAVED:
      {
        const newState = cloneDeep(state);
        if(!newState.bf) {
          newState.bf = {};
        }
        if(!Array.isArray(newState.bf.scenario)) {
          newState.bf.scenario = [];
        }
        if(action.scenarioID) {
          if(!newState.bf.scenario.includes(action.scenarioID)) {
            newState.bf.scenario.push(action.scenarioID);
          }
        }
        return newState;
      }

      case actions.MAP_EDIT_SAVED:
        {
          const newState = cloneDeep(state);
          if(!newState.bf) {
            newState.bf = {};
          }
          if(!Array.isArray(newState.bf.map)) {
            newState.bf.map = [];
          }
          if(action.mapID) {
            if(!newState.bf.map.includes(action.mapID)) {
              newState.bf.map.push(action.mapID);
            }
          }
          return newState;
        }

    default:
      return state;
  }
}

function finishedList (state = [], action) {
  switch (action.type) {
    case actions.SET_USER_FINISHED_LIST:
      if(Array.isArray(action.data)) {
        return action.data;
      }
      return state;
    default:
      return state;
  }
}

function playerID(state = 0, action) {
  switch (action.type) {
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      if(action.where.playerID) {
        return action.where.playerID;
      }
      return state;
    default:
      return state;
  }
}

function playerStrings (state = {}, action) {
  switch (action.type) {
    case actions.ADD_PLAYER_STRINGS:
      const data = action.data;
      if(Array.isArray(data)) {
        const newState = Object.assign({}, state);
        for(let i = 0; i < data.length; i++) {
          const uid = parseInt(data[i].split('|')[0], 36);
          newState[uid] = data[i];
        }
        return newState;
      }
      return state;
    default:
      return state;
  }
}

function playerInfos (state = {}, action) {
  // Dictionary of playerInfos indexed by uid.
  switch (action.type) {
    case actions.ADD_PLAYER_INFOS:
      const data = action.data;
      if(Array.isArray(data)) {
        const newState = Object.assign({}, state);
        for(let i = 0; i < data.length; i++) {
          const uid = data[i].uid;
          if(uid) {
            newState[uid] = data[i];
          }
        }
        return newState;
      }
      return state;

    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      const { playerID } = action.where;
      if(playerID) {
        if(!state[playerID]) {
          sendPacket('bf_get', {e: 'playerInfo', uid: playerID});
        }
      }
      return state;

    default:
      return state;
  }
}

function playerStats (state = {}, action) {
  // Dictionary of playerStats indexed by uid.
  switch (action.type) {
    case actions.ADD_PLAYER_STAT:
      const { data, uid } = action;
      if(uid && Array.isArray(data)) {
        const newState = Object.assign({}, state);
        newState[uid] = data;
        return newState;
      }
      return state;
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      const { playerID } = action.where;
      if(playerID) {
        if(!state[playerID]) {
          sendPacket('bf_get', {e: 'playerStats', uid: playerID});
        }
      }
      return state;
    default:
      return state;
  }
}

function leaderboardScore (state = [], action) {
  switch (action.type) {
    case actions.SET_LEADERBOARD_SCORE:
      return action.data;
    default:
      return state;
  }
}

function leaderboardXP (state = [], action) {
  switch (action.type) {
    case actions.SET_LEADERBOARD_XP:
      return action.data;
    default:
      return state;
  }
}

function blockList (state = {}, action) {
  switch (action.type) {
    case actions.SET_BLOCK_LIST:
      const t0 = {};
      if(Array.isArray(action.data)) {
        for(let i = 0 ; i < action.data.length; i++) {
          t0[action.data[i]] = action.data[i];
        }
      }
      return t0;
    case actions.ADD_BLOCK_ITEM:
      const t1 = Object.assign({}, state);
      t1[action.item] = action.item;
      return t1;
    case actions.REMOVE_BLOCK_ITEM:
      const t2 = Object.assign({}, state);
      delete t2[action.item];
      return t2;
    default:
      return state;
  }
}

function friendFoeList (state = {}, action) {
  switch (action.type) {
    case actions.SET_FRIEND_FOE_LIST:
      const t0 = {};
      if(Array.isArray(action.data)) {
        for(let i = 0 ; i < action.data.length; i++) {
          t0[action.data[i]] = action.data[i];
        }
      }
      return t0;
    case actions.ADD_FRIEND_FOE_ITEM:
      const t1 = Object.assign({}, state);
      t1[action.item] = action.item;
      return t1;
    case actions.REMOVE_FRIEND_FOE_ITEM:
      const t2 = Object.assign({}, state);
      delete t2[action.item];
      return t2;
    default:
      return state;
  }
}

function soloScenarios (state =
    {
      standard: {basic: [], premium: [], extreme: []},
      user: {basic: [], premium: [], extreme: []},
    },
    action) {
  switch (action.type) {
    case actions.SET_SOLO_SCENARIOS:
      return action.data;
    default:
      return state;
  }
}

function registeringGames (state =
    {
      standard: {basic: [], premium: [], extreme: []},
      user: {basic: [], premium: [], extreme: []},
    },
    action) {
  switch (action.type) {
    case actions.SET_REGISTERING_GAMES:
      return action.data;
    default:
      return state;
  }
}

function soundOn (state = true, action) {
  switch (action.type) {
    case actions.SET_SOUND_ON_OFF:
      return action.on;
    default:
      return state;
  }
}

function soundVolume (state = 1.0, action) {
  switch (action.type) {
    case actions.SET_SOUND_VOLUME:
      if(action.volume >= 0.0 && action.volume <= 1.0) {
        return action.volume;
      }
      return state;
    default:
      return state;
  }
}

function musicOn (state = true, action) {
  switch (action.type) {
    case actions.SET_MUSIC_ON_OFF:
      return action.on;
    default:
      return state;
  }
}

function musicVolume (state = 0.5, action) {
  switch (action.type) {
    case actions.SET_MUSIC_VOLUME:
      if(action.volume >= 0.0 && action.volume <= 1.0) {
        return action.volume;
      }
      return state;
    default:
      return state;
  }
}

function news (state = [], action) {
  switch (action.type) {
    case actions.NEWS_ADD:
      const newState = [...state];
      const unaddedList = [];
      // First try to add newest items to beginning of list:
      for(let i = action.data.length - 1; i >= 0; i--) {
        const item = action.data[i];
        if(newState.length === 0 || newState[0]._id < item._id) {
          newState.unshift(item);  // Only add newer items (to the top).
        }
        else {
          unaddedList.push(item);
        }
        if(newState.length > NEWS_MAX_ITEMS) {
          newState.pop();  // Remove oldest item, if news list getting too long.
        }
      }
      // Then try to add unadded items to end of list if they are older:
      for(let i = unaddedList.length - 1; i >= 0; i--) {
        const item = unaddedList[i];
        if(newState[newState.length - 1]._id > item._id) {
          newState.push(item);  // Only add older items (to the bottom).
        }
        if(newState.length > NEWS_MAX_ITEMS) {
          newState.pop();  // Remove oldest item, if news list getting too long.
          break;  // No reason to add any more older items.
        }
      }
      return newState;
    default:
      return state;
  }
}

function loginErrorMessage (state = '', action) {
  switch (action.type) {
    case actions.SET_LOGIN_ERROR_MESSAGE:
      return action.message;
    case actions.SUBMIT_LOGIN:
      return '';
    default:
      return state;
  }
}

function replayStage (state = BF.REPLAY_STAGE_ADJUST, action) {
  switch (action.type) {
    case actions.SET_REPLAY_STAGE:
      return action.stage;
    case actions.SET_GAME:
    case actions.SET_MAIN_MODE:
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      // Set replay stage back to default beginning.
      return BF.REPLAY_STAGE_ADJUST;
    default:
      return state;
  }
}

function replayPlay (play = true, action) {
  switch (action.type) {
    case actions.SET_REPLAY_PLAY:
      return action.play;
    default:
      return play;
  }
}

function showForcePurchaseWindow (state = false, action) {
  switch (action.type) {
    case actions.SHOW_FORCE_PURCHASE_WINDOW:
      return true;
    case actions.HIDE_FORCE_PURCHASE_WINDOW:
      return false;
    case actions.SET_SELECTED_ZONEIDS:
      return false;   // Autohide if selected zone changes.
    case actions.SET_MAIN_MODE:
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
    case actions.SET_REPLAY_STAGE:
      return false;   // Autohide if mode changes.
    default:
      return state;
  }
}

function showLandingListWindow (state = false, action) {
  switch (action.type) {
    case actions.SHOW_LANDING_LIST_WINDOW:
      return true;
    case actions.HIDE_LANDING_LIST_WINDOW:
      return false;
    case actions.SET_SELECTED_ZONEIDS:
      return false;   // Autohide if selected zone changes.
    case actions.SET_MAIN_MODE:
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
    case actions.SET_REPLAY_STAGE:
      return false;   // Autohide if mode changes.
    default:
      return state;
  }
}

function showLandingSpecifyWindow (state = {show: false}, action) {
  switch (action.type) {
    case actions.SHOW_LANDING_SPECIFY_WINDOW:
      return {
        show: true,
        forceTypeIndex: action.forceTypeIndex,
        range: action.range,
        destination: action.zoneID,
      };
      case actions.SET_LANDING_SPECIFY_ZONE:
        return {
          show: state.show,
          forceTypeIndex: state.forceTypeIndex,
          range: state.range,
          destination: action.zoneID,
        };
    case actions.HIDE_LANDING_SPECIFY_WINDOW:
      return {show: false};
    case actions.SET_SELECTED_ZONEIDS:
      return {show: false};   // Autohide if selected zone changes.
    case actions.SET_MAIN_MODE:
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
    case actions.SET_REPLAY_STAGE:
      return {show: false};   // Autohide if mode changes.
    default:
      return state;
  }
}

function showLandingHereWindow (state = false, action) {
  switch (action.type) {
    case actions.SHOW_LANDING_HERE_WINDOW:
      return true;
    case actions.HIDE_LANDING_HERE_WINDOW:
      return false;
    case actions.SET_SELECTED_ZONEIDS:
      return false;   // Autohide if selected zone changes.
    case actions.SET_MAIN_MODE:
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
    case actions.SET_REPLAY_STAGE:
      return false;   // Autohide if mode changes.
    default:
      return state;
  }
}

function showForceRetreatWindow (state = false, action) {
  switch (action.type) {
    case actions.SHOW_FORCE_RETREAT_WINDOW:
      return true;
    case actions.HIDE_FORCE_RETREAT_WINDOW:
      return false;
    case actions.SET_SELECTED_ZONEIDS:
      return false;   // Autohide if selected zone changes.
    case actions.SET_MAIN_MODE:
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
    case actions.SET_REPLAY_STAGE:
      return false;   // Autohide if mode changes.
    default:
      return state;
  }
}

function showLoadTransportsWindow (state = false, action) {
  switch (action.type) {
    case actions.SHOW_LOAD_TRANSPORTS_WINDOW:
      return true;
    case actions.HIDE_LOAD_TRANSPORTS_WINDOW:
      return false;
    case actions.SET_SELECTED_ZONEIDS:
      return false;   // Autohide if selected zone changes.
    case actions.SET_MAIN_MODE:
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
    case actions.SET_REPLAY_STAGE:
      return false;   // Autohide if mode changes.
    default:
      return state;
  }
}

function showUnloadTransportsWindow (state = false, action) {
  switch (action.type) {
    case actions.SHOW_UNLOAD_TRANSPORTS_WINDOW:
      return true;
    case actions.HIDE_UNLOAD_TRANSPORTS_WINDOW:
      return false;
    case actions.SET_SELECTED_ZONEIDS:
      return false;   // Autohide if selected zone changes.
    case actions.SET_MAIN_MODE:
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
    case actions.SET_REPLAY_STAGE:
      return false;   // Autohide if mode changes.
    default:
      return state;
  }
}

function selectedZoneIDs (state = [], action) {
  switch (action.type) {
    case actions.SET_SELECTED_ZONEIDS:
      return action.selectedZoneIDs;
    case actions.SET_MAIN_MODE:
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
    // case actions.SET_REPLAY_STAGE:
      return [];  // Unselect if mode changes.
    default:
      return state;
  }
}

function mouseoverZoneID (state = 0, action) {
  switch (action.type) {
    case actions.SET_MOUSEOVER_ZONEID:
      return action.zoneID;
    case actions.SET_MAIN_MODE:
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      return 0;
    default:
      return state;
  }
}

function camera(state = {zoom: 1, x: 0, y: 0}, action) {
  let {x, y, zoom} = state;
  switch (action.type) {
    case actions.MAP_ZOOM:
      if(action.delta < 0) {
        if(zoom / 1.1 >= 0.1) {
          x /= 1.1;
          y /= 1.1;
          zoom /= 1.1;
        }
      }
    	else if(action.delta > 0) {
  	    if(zoom * 1.1 <= 10) {
          x *= 1.1;
          y *= 1.1;
          zoom *= 1.1;
  	    }
    	}
      return { ...state, x, y, zoom };
    case actions.MAP_PAN:
      x -= action.dx;
      y -= action.dy;
      return { ...state, x, y }
   // case actions.SET_MAIN_MODE:
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      return {zoom: 1, x: 0, y: 0};
    default:
      return state;
  }
}

function mapID(state = 0, action) {
  // The current mapID for the map details page.
  switch (action.type) {
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      if(action.where.mapID) {
        return action.where.mapID;
      }
      return state;
    default:
      return state;
  }
}

function map(state = {}, action) {
  // map is a dictionary of map objects indexed by _id.
  switch (action.type) {
    case actions.MAP_ADD:
      {
      const t = Object.assign({}, state);
      t[action.map._id] = action.map;
      return t;
      }
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      if(action.where.mapID) {
        const mapID = action.where.mapID;
        if(!state[mapID]) {
          sendPacket('bf_getMap', { mapID });
        }
      }
      return state;

    case actions.MAP_EDIT_SAVED:
      // Clear the cache of the matching map, since it has changed on the server.
      if(state[action.scenarioID]) {
        const t = Object.assign({}, state);
        t[action.map._id] = undefined;
        return t;
      }
      return state;

    default:
      return state;
  }
}

function leaderboardMapPlays (state = {}, action) {
  // leaderboardMapPlays is the number of plays leaderboard for the given mapID.
  switch (action.type) {
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      const { mapID } = action.where;
      if(mapID) {
        if(!state.mapID || state.mapID !== mapID) {
          sendPacket('bf_get', {e: 'leaderboard',
                                key: 'bfz_mapPlays:' + mapID,
                                start: 0,
                                count: 100,
                                meta: 1});
          return {};
        }
      }
      return state;
    case actions.SET_LEADERBOARD_MAP_PLAYS:
      // Set the leaderboardMapPlays data, assumes the mapID already matches the active mapID.
      return {
        mapID: action.mapID,
        data: action.data,
      };
    default:
      return state;
  }
}

function scenarioID(state = 0, action) {
  switch (action.type) {
    case actions.SET_SCENARIOID:
      return action.scenarioID;
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      if(action.where.scenarioID) {
        return action.where.scenarioID;
      }
      return state;
    case actions.SCENARIO_EDIT_SAVED:
      // Clear the cache of the matching scenario, since it has changed on the server.
      if(state === action.scenarioID) {
        return 0;
      }
      return state;
    default:
      return state;
  }
}

function scenario(state = {}, action) {
  switch (action.type) {
    case actions.SET_SCENARIO:
      return action.scenario;
    case actions.SET_SCENARIOID:
      if(state._id !== action.scenarioID) {
        return {};
      }
      return state;
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      if(action.where.scenarioID) {
        if(state._id !== action.where.scenarioID) {
          sendPacket('bf_getScenario', { scenarioID: action.where.scenarioID });
          return {};
        }
      }
      return state;
    case actions.SCENARIO_EDIT_SAVED:
      // Clear the cache of the matching scenario, since it has changed on the server.
      if(state._id === action.scenarioID) {
        return {};
      }
      return state;
    default:
      return state;
  }
}

function leaderboardScenarioPlays (state = {}, action) {
  // leaderboardScenarioPlays is the number of plays leaderboard for the given scenarioID.
  switch (action.type) {
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      const { scenarioID } = action.where;
      if(scenarioID) {
        if(!state.scenarioID || state.scenarioID !== scenarioID) {
          sendPacket('bf_get', {e: 'leaderboard',
                                key: 'bfz_scenarioPlays:' + scenarioID,
                                start: 0,
                                count: 100,
                                meta: 1});
          return {};
        }
      }
      return state;
    case actions.SET_LEADERBOARD_SCENARIO_PLAYS:
      // Set the leaderboardScenarioPlays data, assumes the scenarioID already matches the active
      // scenarioID.
      return {
        scenarioID: action.scenarioID,
        data: action.data,
      };
    default:
      return state;
  }
}

function gameID(state = 0, action) {
  switch (action.type) {
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      if(action.where.gameID) {
        return action.where.gameID;
      }
      return state;
    default:
      return state;
  }
}

function game(state = {}, action) {
  switch (action.type) {
    case actions.SET_GAME:
      return action.game;

    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      if(action.where.gameID) {
        if(state._id !== action.where.gameID || action.where.mode === mainModes.GAME_LOADING) {
          // Request new gameCore since gameID has changed or it is forced to load due to
          // GAME_LOADING that is needed for playFor state reload:
          sendPacket('bf_get', {e: 'gameCore',
                                gameID: action.where.gameID,
                                encode: (isSafari ? 1 : 0)});
          return {};
        }
      }
      return state;

    case actions.SET_GAME_STATUS:
      if(state._id && state.turn) {
        for(let i = 0; i < action.data.length; i++) {
          if(state._id === action.data[i]._id &&
              action.data[i].turn &&
              (state.turn !== action.data[i].turn || state.state !== action.data[i].state)) {
            // Request new gameCore if turn index has changed for the active game:
            sendPacket('bf_get', {e: 'gameCore',
                                  gameID: state._id,
                                  encode: (isSafari ? 1 : 0)});
          }
        }
      }
      return state;

    case actions.UPDATE_GAME_STATUS:
      if(state._id && state.turn && state._id === action.data._id &&
          action.data.turn &&
          (state.turn !== action.data.turn || state.state !== action.data.state)) {
           // state.turn !== action.data.turn) {
        // Request new gameCore if turn index has changed for the active game:
        sendPacket('bf_get', {e: 'gameCore',
                              gameID: state._id,
                              encode: (isSafari ? 1 : 0)});
      }
      return state;

    case actions.GAME_UPDATE_AUTOPLAY:
      if(state._id !== action.gameID) {
        return state;
      }
      return Object.assign({}, state, {autoPlay: action.autoPlay,
                                       autoSpend: action.autoSpend,
                                       autoMove: action.autoMove,
                                      });

    case actions.GAME_UPDATE_TEAMMATES_SUBMIT:
      if(state._id !== action.gameID) {
        return state;
      }
      return Object.assign({}, state, {teammatesSubmit: action.teammatesSubmit});

    case actions.SCENARIO_EDIT_CAPITAL:
      const { capitalID, playerIndex } = action;
      const game1 = cloneDeep(state);
      const capitalPos = getNewZoneXY(game1, capitalID);
      game1.capital[playerIndex] = capitalID;
      game1.capitalX[playerIndex] = capitalPos.x;
      game1.capitalY[playerIndex] = capitalPos.y;
      return game1;
    case actions.SCENARIO_EDIT_ZONE_INCOME:
      {
        const { zoneID, income } = action;
        const game1 = cloneDeep(state);
        game1.zoneIncome[zoneID] = income;
        return game1;
      }
    case actions.SCENARIO_EDIT_ZONE_OWNER:
      {
        const { zoneID, owner } = action;
        const game1 = cloneDeep(state);
        game1.zoneOwner[zoneID] = owner;
        return game1;
      }

    default:
      return state;
  }
}

function gameStatus (state = {}, action) {
  // A local data store of expanded game statuses indexed by gameID.
  // These values are provided by gameStrings and gameStatus'es.
  // These are dynamic values for the game object, and maintained separately.
  switch (action.type) {
    case actions.SET_GAME_STATUS:
      // This action replaces an entire object for a given gameID, can be multiple in an array.
      const newState = Object.assign({}, state);
      for(let i = 0; i < action.data.length; i++) {
        if(action.data[i]._id) {
          newState[action.data[i]._id] = action.data[i];
        }
      }
      return newState;
    case actions.UPDATE_GAME_STATUS:
      // Only updates a subset of the fields in the object for a single given gameID.
      if(state[action.data._id]) {
        // There is already an entry for this gameID, so we can update parts of it.
        const t = Object.assign({}, state);
        t[action.data._id] = Object.assign({}, t[action.data._id], action.data);
        return t;
      }
      return state;
    default:
      return state;
  }
}

function playFor (state = {uid: 0, uName: ''}, action) {
// Used for allowing teammates to play for a teammate.
  switch (action.type) {
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      if(action.where.playFor) {
        return action.where.playFor;
      }
      return {uid: 0, uName: ''};
    default:
      return state;
  }
}


const forcesApp = combineReducers({
  version,
  soloNoAuthMode,
  mainMode,
  notifyModals,
  backStack,
  userSession,
  finishedList,

  leaderboardScore, leaderboardXP, leaderboardMapPlays, leaderboardScenarioPlays,
  blockList, friendFoeList,

  soloScenarios, registeringGames,

  soundOn, soundVolume,
  musicOn, musicVolume,

  news,
  loginErrorMessage,
  server,

  playerID, playerStrings, playerInfos, playerStats,

  mapID, map,

  scenarioID, scenario,

  gameID, game, gameStatus,
  working,

  replayStage, replayPlay,
  camera,

  selectedZoneIDs, mouseoverZoneID,
  showForcePurchaseWindow,
  showLandingListWindow, showLandingSpecifyWindow, showLandingHereWindow,
  showForceRetreatWindow,
  showLoadTransportsWindow, showUnloadTransportsWindow,

  gameChatData, gameChatMeta,

  playFor,

  scenarioEdit, scenarioEditSaved,

  mapEdit, mapEditSaved, mapEditSelectedZoneID, mapEditZoom,
});

export default forcesApp;
