import React from "react";
import { Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";

const centerAnchor = new PIXI.Point(0.5, 0.5);

export default function BufferSprite (props) {
  return (
    <Sprite
      anchor={ centerAnchor }
      { ...props }
    />
  );
}
