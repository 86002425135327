/*
  BaseBackScroll.js
  (c) Human Cube Inc.
*/

export default function BaseBackScroll ({background, children}) {
  return (
    <div
      className="full-bg-size"
      style={{
        backgroundImage: `url(${ background })`,
        width: "100%",
        height: "100vh",
        "overflowY": "scroll",
        "paddingRight": "20px",     /* Increase/decrease for cross-browser compatibility */
        "boxSizing": "content-box", /* So the width will be 100% + 20px */
      }}
    >
      { children }
    </div>
  );
}
