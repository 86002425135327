/*
  BaseMyScenarios.js
  (c) Human Cube Inc.
*/

import { Segment } from 'semantic-ui-react';

import BaseAdSense from './BaseAdSense';
import BaseBackScroll from './BaseBackScroll';
import BaseMenuBasic from './BaseMenuBasic';
import BaseScenarioTile from './BaseScenarioTile';
import background from './img/background/map267241.jpg';


export default function BaseMyScenarios ({store}) {
  const { scenario } = store.userSession.bf;

  const scenarioTiles = [];
  for(let i = 0; i < scenario.length; i++) {
    const scenarioID = scenario[i];
    scenarioTiles.push(
      <div key={scenarioID} >
        <br />
        <BaseScenarioTile
          scenarioID={ scenarioID }
          store={ store }
        />
      </div>
    );
  }

  return (
    <BaseBackScroll background={ background } >
      <BaseMenuBasic pageName='My Custom Scenarios' />
      <center>
        <br /><br /><BaseAdSense />
        <Segment compact style={{ opacity: 0.86 }}>
          Maps are used to create Scenarios. Games are started from a Scenario.<br />
          Extreme and Premium members may create their own unique custom Maps and Scenarios.<br />
          <br />
          Goto any Map or Scenario page to create your own version of a custom Map or Scenario.
          <br />
          All custom scenarios you create will be listed here.<br />
          <center>
            { scenarioTiles }
          </center>
        </Segment>
        <br /><br /><br />
        <br /><br /><br />
      </center>
    </BaseBackScroll>
  );
}
