/*
  BaseConduct.js
  (c) Human Cube Inc.
*/

import { Segment } from 'semantic-ui-react';

import BaseBackScroll from './BaseBackScroll';
import BaseFooter from './BaseFooter';
import BaseMenuBasic from './BaseMenuBasic';
import background from './img/background/fleet1920x1200.jpg';


export default function BaseConduct () {
  return (
    <BaseBackScroll background={ background } >
      <BaseMenuBasic pageName='Rules of Conduct' />
      <center>
        <br />
        <br />
        <br />
        <Segment compact>
          <div>
            <h1>Rules of Conduct</h1>
          </div>
          <div style={{margin: '12px'}} >
            All players are required to follow the following rules of conduct:<br />
            <br />
            - Treat everyone with respect.<br />
            - One account per person only.<br />
          </div>
        </Segment>
        <br />
        <br />
        <br />
        <BaseFooter />
        <br /><br /><br />
        <br /><br /><br />
      </center>
    </BaseBackScroll>
  );
}
