/*
  BaseScenarioTile.js
  (c) Human Cube Inc.
*/

import { Link } from "react-router-dom";
import { Button } from 'semantic-ui-react';


export default function BaseScenarioTile ({scenarioID, store}) {
  return (
    <Link to={'/scenario/' + scenarioID}>
      <Button color='olive'>
        <div>
          { 'Scenario #' + scenarioID }
        </div>
      </Button>
    </Link>
  );
}
