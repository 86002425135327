import { Game } from "../../../bfcore/gameTypes";


export const landWarAfricaGame: Game = {
    "_id": 0,  // To be filled in.
    "state": 5,
    "flags": 1077,
    "name": "Land War Africa Intro",
    "uid": 2,
    "uName": 'GameAdmin',
    "scenarioID": 30201,
    "scenarioName": "Land War Africa Intro",
    "scenarioUID": 2,
    "scenarioUName": "GameAdmin",
    "factories": 4,
    "mapID": 3,
    "mapName": "Africa",
    "mapUID": 2,
    "mapUName": "GameAdmin",
    "mapFlags": 35,
    "mapXS": 512,
    "mapYS": 512,
    "mapData": "",
    "zones": 21,
    "zonesLand": 21,
    "zonesWater": 0,
    "zoneBonus": [],
    "zoneKey": [
        0,
        34,
        27,
        17,
        24,
        8,
        31,
        22,
        36,
        30,
        26,
        28,
        11,
        1,
        33,
        37,
        19,
        16,
        25,
        20,
        3,
        14
    ],
    "teams": 0,
    "players": 3,
    "humanPlayers": 1,
    "computerPlayers": 2,
    "pickingOrder": 1,
    "victoryCapitals": 0,
    "victoryIncome": 0,
    "victoryLandZones": 0,
    "victoryCash": 0,
    "maxDuration": 2592000,
    "maxTurns": 100,
    "regScore": [
        0,
        1000000
    ],
    "regMembership": [
        0,
        10
    ],
    "playTimes": [],
    "timeTurn": 1209600,
    "timeZonePicking": 1209600,
    "timeCapitalPicking": 1209600,
    "sourceGameID": 0,
    "privatePIN": 0,
    "turn": 400000000,
    "timeLimit": 1731358429123,
    "required": 14,
    "noWait": 0,
    "submitted": 0,
    "autoPlay": 0,
    "teammatesSubmit": 0,
    "autoSpend": [
        100,
        100,
        100,
        100
    ],
    "autoMove": [
        100,
        100,
        100,
        100
    ],
    "slotName": [],
    "slotFlag": [],
    "playerID": [
        0,
        0,  // To be filled in.
        -2,
        -3
    ],
    "playerName": [
        "",
        "",  // To be filled in.
        "CYBERMAN",
        "BORG"
    ],
    "playerFlags": [
        0,
        1,
        2,
        2
    ],
    "playerFlag": [
        "",
        "us",
        "sa",
        "gb"
    ],
    "playerColor": [
        16777215,
        12544851,
        8158332,
        16382385
    ],
    "playerScore": [
        0,
        0,
        0,
        0
    ],
    "canBuy": [
        0,
        7,
        7,
        7
    ],
    "maxBuy": [
        0,
        377,
        388,
        383
    ],
    "showBuy": [
        0,
        7,
        7,
        7
    ],
    "finished": [
        [
            0,
            0
        ],
        [
            0,
            0
        ],
        [
            0,
            0
        ],
        [
            0,
            0
        ]
    ],
    "playerAutoTotal": [
        0,
        0,
        0,
        0
    ],
    "playerAutoCurrent": [
        0,
        0,
        0,
        0
    ],
    "playerMissedTotal": [
        0,
        0,
        0,
        0
    ],
    "playerMissedCurrent": [
        0,
        0,
        0,
        0
    ],
    "capital": [
        0,
        21,
        1,
        4
    ],
    "capitalX": [
        0,
        5376,
        1920,
        5760
    ],
    "capitalY": [
        0,
        7168,
        1152,
        1664
    ],
    "cash": [
        0,
        28,
        18,
        29
    ],
    "team": [],
    "fixedAlly": [
        1,
        2,
        4,
        8
    ],
    "declaredAlly": [
        0,
        0,
        0,
        0
    ],
    "force": [
        [],
        [
            [
                21,
                0,
                9,
                9,
                0,
                1,
                0,
                0,
                6272,
                3264,
                6272,
                3264
            ],
            [
                22,
                1,
                9,
                9,
                0,
                2,
                0,
                0,
                6592,
                3264,
                6592,
                3264
            ],
            [
                23,
                0,
                10,
                10,
                0,
                1,
                0,
                0,
                7232,
                3520,
                7232,
                3520
            ],
            [
                33,
                0,
                15,
                15,
                0,
                1,
                0,
                0,
                4864,
                4992,
                4864,
                4992
            ],
            [
                34,
                0,
                15,
                15,
                0,
                1,
                0,
                0,
                4864,
                4992,
                4864,
                4992
            ],
            [
                35,
                0,
                15,
                15,
                0,
                1,
                0,
                0,
                4608,
                4224,
                4608,
                4224
            ],
            [
                36,
                0,
                15,
                15,
                0,
                1,
                0,
                0,
                4352,
                4096,
                4352,
                4096
            ],
            [
                37,
                0,
                15,
                15,
                0,
                1,
                0,
                0,
                6016,
                4096,
                6016,
                4096
            ],
            [
                38,
                0,
                16,
                16,
                0,
                1,
                0,
                0,
                6528,
                4608,
                6528,
                4608
            ],
            [
                39,
                1,
                16,
                16,
                0,
                2,
                0,
                0,
                6080,
                4544,
                6080,
                4544
            ],
            [
                40,
                1,
                16,
                16,
                0,
                2,
                0,
                0,
                6144,
                4096,
                6144,
                4096
            ],
            [
                41,
                0,
                17,
                17,
                0,
                1,
                0,
                0,
                4480,
                5248,
                4480,
                5248
            ],
            [
                42,
                0,
                17,
                17,
                0,
                1,
                0,
                0,
                3968,
                5248,
                3968,
                5248
            ],
            [
                43,
                0,
                18,
                18,
                0,
                1,
                0,
                0,
                5120,
                5824,
                5120,
                5824
            ],
            [
                44,
                1,
                18,
                18,
                0,
                2,
                0,
                0,
                5632,
                5760,
                5632,
                5760
            ],
            [
                45,
                0,
                19,
                19,
                0,
                1,
                0,
                0,
                6144,
                5504,
                6144,
                5504
            ],
            [
                46,
                1,
                19,
                19,
                0,
                2,
                0,
                0,
                6400,
                5888,
                6400,
                5888
            ],
            [
                47,
                0,
                20,
                20,
                0,
                1,
                0,
                0,
                4480,
                6464,
                4480,
                6464
            ],
            [
                48,
                0,
                21,
                21,
                0,
                1,
                0,
                0,
                5632,
                7168,
                5632,
                7168
            ],
            [
                49,
                1,
                21,
                21,
                0,
                2,
                0,
                0,
                5504,
                6784,
                5504,
                6784
            ],
            [
                50,
                1,
                21,
                21,
                0,
                2,
                0,
                0,
                5568,
                6912,
                5568,
                6912
            ],
            [
                51,
                1,
                21,
                21,
                0,
                2,
                0,
                0,
                4736,
                7168,
                4736,
                7168
            ],
            [
                52,
                2,
                21,
                21,
                0,
                0,
                0,
                0,
                5056,
                7168,
                5056,
                7168
            ]
        ],
        [
            [
                1,
                0,
                1,
                1,
                0,
                1,
                0,
                0,
                1920,
                960,
                1920,
                960
            ],
            [
                2,
                0,
                1,
                1,
                0,
                1,
                0,
                0,
                1920,
                1088,
                1920,
                1088
            ],
            [
                3,
                0,
                1,
                1,
                0,
                1,
                0,
                0,
                1792,
                1344,
                1792,
                1344
            ],
            [
                4,
                1,
                1,
                1,
                0,
                2,
                0,
                0,
                1920,
                960,
                1920,
                960
            ],
            [
                5,
                2,
                1,
                1,
                0,
                0,
                0,
                0,
                1984,
                1280,
                1984,
                1280
            ],
            [
                6,
                1,
                2,
                2,
                0,
                2,
                0,
                0,
                3328,
                1408,
                3328,
                1408
            ],
            [
                7,
                1,
                2,
                2,
                0,
                2,
                0,
                0,
                3072,
                1536,
                3072,
                1536
            ],
            [
                15,
                0,
                5,
                5,
                0,
                1,
                0,
                0,
                1088,
                2304,
                1088,
                2304
            ],
            [
                16,
                0,
                6,
                6,
                0,
                1,
                0,
                0,
                1728,
                2112,
                1728,
                2112
            ],
            [
                17,
                0,
                7,
                7,
                0,
                1,
                0,
                0,
                4608,
                2560,
                4608,
                2560
            ],
            [
                24,
                0,
                11,
                11,
                0,
                1,
                0,
                0,
                704,
                2816,
                704,
                2816
            ],
            [
                25,
                0,
                12,
                12,
                0,
                1,
                0,
                0,
                2368,
                3328,
                2368,
                3328
            ]
        ],
        [
            [
                8,
                1,
                3,
                3,
                0,
                2,
                0,
                0,
                4480,
                1920,
                4480,
                1920
            ],
            [
                9,
                1,
                3,
                3,
                0,
                2,
                0,
                0,
                3584,
                1280,
                3584,
                1280
            ],
            [
                10,
                0,
                4,
                4,
                0,
                1,
                0,
                0,
                5632,
                1536,
                5632,
                1536
            ],
            [
                11,
                0,
                4,
                4,
                0,
                1,
                0,
                0,
                5120,
                1728,
                5120,
                1728
            ],
            [
                12,
                1,
                4,
                4,
                0,
                2,
                0,
                0,
                5184,
                1472,
                5184,
                1472
            ],
            [
                13,
                1,
                4,
                4,
                0,
                2,
                0,
                0,
                4992,
                1472,
                4992,
                1472
            ],
            [
                14,
                2,
                4,
                4,
                0,
                0,
                0,
                0,
                5760,
                1600,
                5760,
                1600
            ],
            [
                18,
                0,
                8,
                8,
                0,
                1,
                0,
                0,
                5632,
                2944,
                5632,
                2944
            ],
            [
                19,
                0,
                8,
                8,
                0,
                1,
                0,
                0,
                5120,
                2560,
                5120,
                2560
            ],
            [
                20,
                0,
                8,
                8,
                0,
                1,
                0,
                0,
                6272,
                2304,
                6272,
                2304
            ],
            [
                26,
                0,
                13,
                13,
                0,
                1,
                0,
                0,
                3712,
                3584,
                3712,
                3584
            ],
            [
                27,
                0,
                13,
                13,
                0,
                1,
                0,
                0,
                3968,
                4096,
                3968,
                4096
            ],
            [
                28,
                0,
                13,
                13,
                0,
                1,
                0,
                0,
                3968,
                4480,
                3968,
                4480
            ],
            [
                29,
                0,
                13,
                13,
                0,
                1,
                0,
                0,
                3200,
                3200,
                3200,
                3200
            ],
            [
                30,
                1,
                13,
                13,
                0,
                2,
                0,
                0,
                4224,
                4224,
                4224,
                4224
            ],
            [
                31,
                2,
                13,
                13,
                0,
                0,
                0,
                0,
                3584,
                4096,
                3584,
                4096
            ],
            [
                32,
                0,
                14,
                14,
                0,
                1,
                0,
                0,
                3264,
                2368,
                3264,
                2368
            ]
        ]
    ],
    "losses": [
        [
            0,
            0,
            0,
            0
        ],
        [
            0,
            0,
            0,
            0
        ],
        [
            0,
            0,
            0,
            0
        ],
        [
            0,
            0,
            0,
            0
        ]
    ],
    "missedAllowed": 2,
    "autoTotal": 0,
    "autoCurrent": 0,
    "autoAllowed": 2,
    "teamName": [
        ""
    ],
    "zoneFlags": [
        0,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1
    ],
    "zoneName": [
        "",
        "Morocco",
        "Algeria",
        "Libya",
        "Egypt",
        "Mauritania",
        "Mali",
        "Chad",
        "Sudan",
        "Ethiopia",
        "Somalia",
        "Senegal",
        "Ivory Coast",
        "Nigeria",
        "Niger",
        "Congo",
        "Kenya",
        "Angola",
        "Zimbabwe",
        "East Africa",
        "Namibia",
        "South Africa"
    ],
    "zoneIncome": [
        0,
        8,
        5,
        5,
        9,
        1,
        1,
        1,
        4,
        1,
        1,
        1,
        1,
        9,
        2,
        7,
        2,
        2,
        1,
        3,
        1,
        10
    ],
    "bordersLand": [
        [],
        [
            5,
            2
        ],
        [
            6,
            14,
            3,
            5,
            1
        ],
        [
            7,
            8,
            4,
            14,
            2
        ],
        [
            8,
            3
        ],
        [
            11,
            12,
            6,
            1,
            2
        ],
        [
            12,
            13,
            14,
            5,
            2
        ],
        [
            13,
            15,
            8,
            14,
            3
        ],
        [
            15,
            16,
            9,
            7,
            3,
            4
        ],
        [
            16,
            10,
            8
        ],
        [
            16,
            9
        ],
        [
            12,
            5
        ],
        [
            11,
            13,
            6,
            5
        ],
        [
            17,
            15,
            7,
            12,
            6,
            14
        ],
        [
            13,
            6,
            7,
            2,
            3
        ],
        [
            18,
            17,
            13,
            19,
            16,
            8,
            7
        ],
        [
            19,
            10,
            15,
            9,
            8
        ],
        [
            20,
            18,
            15,
            13
        ],
        [
            21,
            19,
            20,
            17,
            15
        ],
        [
            21,
            18,
            16,
            15
        ],
        [
            21,
            18,
            17
        ],
        [
            20,
            19,
            18
        ]
    ],
    "bordersWater": [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        []
    ],
    "zoneOwner": [
        0,
        2,
        2,
        3,
        3,
        2,
        2,
        2,
        3,
        1,
        1,
        2,
        2,
        3,
        3,
        1,
        1,
        1,
        1,
        1,
        1,
        1
    ],
    "zoneOwnerPrev": [
        0,
        2,
        2,
        3,
        3,
        2,
        2,
        2,
        3,
        1,
        1,
        2,
        2,
        3,
        3,
        1,
        1,
        1,
        1,
        1,
        1,
        1
    ],
    "forceID": [
        1,
        2,
        10
    ],
    "forceName": [
        "Troop",
        "Tank",
        "Factory"
    ],
    "forceNamePlural": [
        "Troops",
        "Tanks",
        "Factories"
    ],
    "forceDescription": [
        "Standard Troop",
        "Standard Tank",
        "Standard Factory"
    ],
    "forceFlags": [
        198485,
        198485,
        260046849
    ],
    "forceCost": [
        10,
        15,
        45
    ],
    "forceRange": [
        1,
        2,
        0
    ],
    "forceAttack": [
        15,
        50,
        0
    ],
    "forceAttackMultiple": [
        1,
        1,
        1
    ],
    "forceDefense": [
        30,
        30,
        0
    ],
    "forceDefenseMultiple": [
        1,
        1,
        1
    ],
    "forceCargoCapacity": [
        0,
        0,
        0
    ],
    "forceCargoSpace": [
        1,
        1,
        -1
    ],
    "forceCarrierCapacity": [
        0,
        0,
        0
    ],
    "forceCarrierSpace": [
        -1,
        -1,
        -1
    ],
    "forceLiftCapacity": [
        0,
        0,
        0
    ],
    "forceLiftSpace": [
        1,
        -1,
        -1
    ],
    "forceProduction": [
        0,
        0,
        1
    ],
    "forceZ": [
        1,
        1,
        1
    ],
    "forceScale": [
        1,
        1,
        1
    ],
    "forceSfxMake": [
        0,
        0,
        0
    ],
    "forceSfxMove": [
        0,
        0,
        0
    ],
    "forceSfxDie": [
        0,
        0,
        0
    ],
    "forceVersion": [
        0,
        0,
        0
    ],
    "nextForceID": 53,
    "year": 2000,
    "replay": {},
    "created": 1730148829103,
    "started": 1730148829123,
    "ended": "",
    "zoneBoxXSize": 16,
    "zoneBoxYSize": 16,
    "mapXScale": 1,
    "mapYScale": 1,
    "zoneBoxX": [
        0,
        104,
        168,
        256,
        332,
        80,
        128,
        272,
        352,
        412,
        460,
        52,
        120,
        216,
        224,
        312,
        404,
        256,
        344,
        384,
        272,
        332
    ],
    "zoneBoxY": [
        0,
        92,
        96,
        104,
        96,
        140,
        152,
        200,
        176,
        196,
        224,
        200,
        200,
        240,
        152,
        280,
        272,
        324,
        352,
        344,
        396,
        440
    ],
    "navyZone": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "startingForces": [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        []
    ]
};
