/*
  BaseLeaderboardPlayerPlays.js
  (c) Human Cube Inc.
*/

import { Link } from "react-router-dom";
import { Flag, List } from 'semantic-ui-react';

import BaseRankImage from './BaseRankImage';


export default function BaseLeaderboardPlayerPlays (props) {
  const uid = props.uid;
  const plays = Math.floor(props.plays / 1000000);
  const wins = Math.floor(props.plays % 1000000);
  const percent = Math.floor(wins * 100 / plays);

  let uName = 'Player ' + uid;
  let uRank = 0;
  let cc = '';
  if(props.playerString) {
    const d = props.playerString.split('|');
    // uid = parseInt(d[0], 36);
    uName = d[1];
    uRank = parseInt(d[2], 36);
    // const membershipLevel = parseInt(d[3], 36);
    // const score = parseInt(d[4], 36);
    // const xp = parseInt(d[5], 36);
    // const plays = parseInt(d[6], 36);
    if(d[7]) {
      cc = d[7];
    }
  }

  return (
    <List.Item>
      <List.Content>
        <Link to={'/player/' + uid}>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', width: 48, paddingTop: 6 }}>
              &nbsp;&nbsp;<b>#{props.rank}</b>
            </div>
            <BaseRankImage rank={uRank} />
            <div style={{ display: 'flex', paddingTop: 6 }}>
              &nbsp;&nbsp;&nbsp;
              <List.Header>
                <Flag name={cc} />&nbsp;{uName}&nbsp;&nbsp;
              </List.Header>
              Wins: {wins} of {plays} ({percent}%)
            </div>
          </div>
        </Link>
      </List.Content>
    </List.Item>
  );
}
