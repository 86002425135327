import React from 'react';
import { Segment } from 'semantic-ui-react';
import BaseAdSense from './BaseAdSense';
import BaseBackScroll from './BaseBackScroll';
import BaseMenuBasic from './BaseMenuBasic';
import BF from '../../bfcore/bfconst1';
import background from './img/background/medals2662694.jpg';

import rank0 from './img/rank/rank00.png';
import rank1 from './img/rank/rank01.png';
import rank2 from './img/rank/rank02.png';
import rank3 from './img/rank/rank03.png';
import rank4 from './img/rank/rank04.png';
import rank5 from './img/rank/rank05.png';
import rank6 from './img/rank/rank06.png';
import rank7 from './img/rank/rank07.png';
import rank8 from './img/rank/rank08.png';
import rank9 from './img/rank/rank09.png';
import rank10 from './img/rank/rank10.png';
import rank11 from './img/rank/rank11.png';
import rank12 from './img/rank/rank12.png';
import rank13 from './img/rank/rank13.png';
import rank14 from './img/rank/rank14.png';
import rank15 from './img/rank/rank15.png';
import rank16 from './img/rank/rank16.png';
import rank17 from './img/rank/rank17.png';
import rank18 from './img/rank/rank18.png';
import rank19 from './img/rank/rank19.png';


export default function BaseRanks(props) {
  return (
    <BaseBackScroll background={ background } >
      <BaseMenuBasic pageName='Rank Progressions' />
      <center>
        <br /><br /><br />
        <BaseAdSense />

        <Segment compact style={{ opacity: 0.9 }}>
          <h3>Enlisted Personnel Rank Progression</h3>
          <h5>Basic and Premium Membership Players</h5>

          <table border="3" width="600">
            <thead>
              <tr bgcolor="#E1E4EF">
                <th width="100"><center>Symbol</center></th>
                <th width="200"><center>Rank Title</center></th>
                <th width="100"><center>Minimum Score</center></th>
                <th width="100"><center>Minimum XP</center></th>
              </tr>
            </thead>
            <tbody>
              <tr bgcolor="#EEE">
                <td width="100"><center><img src={ rank0 } alt='rank00' /></center></td>
                <td width="200"><center>Private</center></td>
                <td width="100"><center>0</center></td>
                <td width="100"><center>{ BF.PLAYER_RANK_XP[0] }</center></td>
              </tr>

              <tr bgcolor="#EEE">
                <td width="100"><center><img src={ rank1 } alt='rank01' /></center></td>
                <td width="200"><center>Private 1st Class</center></td>
                <td width="100"><center>9500</center></td>
                <td width="100"><center>{ BF.PLAYER_RANK_XP[1] }</center></td>
              </tr>

              <tr bgcolor="#EEE">
                <td width="100"><center><img src={ rank2 } alt='rank02' /></center></td>
                <td width="200"><center>Corporal</center></td>
                <td width="100"><center>9900</center></td>
                <td width="100"><center>{ BF.PLAYER_RANK_XP[2] }</center></td>
              </tr>

              <tr bgcolor="#EEE">
                <td width="100"><center><img src={ rank3 } alt='rank03' /></center></td>
                <td width="200"><center>Sergeant</center></td>
                <td width="100"><center>10000</center></td>
                <td width="100"><center>{ BF.PLAYER_RANK_XP[3] }</center></td>
              </tr>

              <tr bgcolor="#EEE">
                <td width="100"><center><img src={ rank4 } alt='rank04' /></center></td>
                <td width="200"><center>Staff Sergeant</center></td>
                <td width="100"><center>10100</center></td>
                <td width="100"><center>{ BF.PLAYER_RANK_XP[4] }</center></td>
              </tr>

              <tr bgcolor="#EEE">
                <td width="100"><center><img src={ rank5 } alt='rank05' /></center></td>
                <td width="200"><center>Sergeant 1st Class</center></td>
                <td width="100"><center>10200</center></td>
                <td width="100"><center>{ BF.PLAYER_RANK_XP[5] }</center></td>
              </tr>

              <tr bgcolor="#EEE">
                <td width="100"><center><img src={ rank6 } alt='rank06' /></center></td>
                <td width="200"><center>Master Sergeant</center></td>
                <td width="100"><center>10300</center></td>
                <td width="100"><center>{ BF.PLAYER_RANK_XP[6] }</center></td>
              </tr>

              <tr bgcolor="#EEE">
                <td width="100"><center><img src={ rank7 } alt='rank07' /></center></td>
                <td width="200"><center>First Sergeant</center></td>
                <td width="100"><center>10500</center></td>
                <td width="100"><center>{ BF.PLAYER_RANK_XP[7] }</center></td>
              </tr>

              <tr bgcolor="#EEE">
                <td width="100"><center><img src={ rank8 } alt='rank08' /></center></td>
                <td width="200"><center>Sergeant Major</center></td>
                <td width="100"><center>11000</center></td>
                <td width="100"><center>{ BF.PLAYER_RANK_XP[8] }</center></td>
              </tr>

              <tr bgcolor="#EEE">
                <td width="100"><center><img src={ rank9 } alt='rank09' /></center></td>
                <td width="200"><center>Command SGT Major</center></td>
                <td width="100"><center>12000</center></td>
                <td width="100"><center>{ BF.PLAYER_RANK_XP[9] }</center></td>
              </tr>
            </tbody>
          </table>
        </Segment>

        <br />
        <Segment compact style={{ opacity: 0.9 }}>
          <h3>Commissioned Officer Rank Progression</h3>
          <h5>Extreme Membership Players</h5>

          <table border="3" width="600">
            <thead>
              <tr bgcolor="#E1E4EF">
                <th width="100"><center>Symbol</center></th>
                <th width="200"><center>Rank Title</center></th>
                <th width="100"><center>Minimum Score</center></th>
                <th width="100"><center>Minimum XP</center></th>
              </tr>
            </thead>
            <tbody>
              <tr bgcolor="#EEE">
                <td width="100"><center><img src={ rank10 } alt='rank10' /></center></td>
                <td width="200"><center>Second Lieutenant</center></td>
                <td width="100"><center>0</center></td>
                <td width="100"><center>{ BF.PLAYER_RANK_XP[0] }</center></td>
              </tr>

              <tr bgcolor="#EEE"><td width="100"><center><img src={ rank11 } alt='rank11' /></center></td>
              <td width="200"><center>First Lieutenant</center></td><td width="100"><center>9500</center></td><td width="100"><center>
              { BF.PLAYER_RANK_XP[1] }</center></td></tr>

              <tr bgcolor="#EEE"><td width="100"><center><img src={ rank12 } alt='rank12' /></center></td>
              <td width="200"><center>Captain</center></td><td width="100"><center>9900</center></td><td width="100"><center>
              { BF.PLAYER_RANK_XP[2] }</center></td></tr>

              <tr bgcolor="#EEE"><td width="100"><center><img src={ rank13 } alt='rank13' /></center></td>
              <td width="200"><center>Major</center></td><td width="100"><center>10000</center></td><td width="100"><center>
              { BF.PLAYER_RANK_XP[3] }</center></td></tr>

              <tr bgcolor="#EEE"><td width="100"><center><img src={ rank14 } alt='rank14' /></center></td>
              <td width="200"><center>Colonel</center></td><td width="100"><center>10100</center></td><td width="100"><center>
              { BF.PLAYER_RANK_XP[4] }</center></td></tr>

              <tr bgcolor="#EEE"><td width="100"><center><img src={ rank15 } alt='rank15' /></center></td>
              <td width="200"><center>Brigadier General</center></td><td width="100"><center>10200</center></td><td width="100"><center>
              { BF.PLAYER_RANK_XP[5] }</center></td></tr>

              <tr bgcolor="#EEE"><td width="100"><center><img src={ rank16 } alt='rank16' /></center></td>
              <td width="200"><center>Major General</center></td><td width="100"><center>10300</center></td><td width="100"><center>
              { BF.PLAYER_RANK_XP[6] }</center></td></tr>

              <tr bgcolor="#EEE"><td width="100"><center><img src={ rank17 } alt='rank17' /></center></td>
              <td width="200"><center>Lieutenant General</center></td><td width="100"><center>10500</center></td><td width="100"><center>
              { BF.PLAYER_RANK_XP[7] }</center></td></tr>

              <tr bgcolor="#EEE"><td width="100"><center><img src={ rank18 } alt='rank18' /></center></td>
              <td width="200"><center>4 Star General</center></td><td width="100"><center>11000</center></td><td width="100"><center>
              { BF.PLAYER_RANK_XP[8] }</center></td></tr>

              <tr bgcolor="#EEE"><td width="100"><center><img src={ rank19 } alt='rank19' /></center></td>
              <td width="200"><center>5 Star General</center></td><td width="100"><center>12000</center></td><td width="100"><center>
              { BF.PLAYER_RANK_XP[9] }</center></td></tr>
            </tbody>
          </table>
        </Segment>
        <br /><br /><br />
        <br /><br /><br />
      </center>
    </BaseBackScroll>
  );
}
