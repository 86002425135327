/*
  BaseRankImage.js
  (c) Human Cube Inc.
*/

import { Image } from 'semantic-ui-react';

import rank0 from './img/rank/rank00.png';
import rank1 from './img/rank/rank01.png';
import rank2 from './img/rank/rank02.png';
import rank3 from './img/rank/rank03.png';
import rank4 from './img/rank/rank04.png';
import rank5 from './img/rank/rank05.png';
import rank6 from './img/rank/rank06.png';
import rank7 from './img/rank/rank07.png';
import rank8 from './img/rank/rank08.png';
import rank9 from './img/rank/rank09.png';
import rank10 from './img/rank/rank10.png';
import rank11 from './img/rank/rank11.png';
import rank12 from './img/rank/rank12.png';
import rank13 from './img/rank/rank13.png';
import rank14 from './img/rank/rank14.png';
import rank15 from './img/rank/rank15.png';
import rank16 from './img/rank/rank16.png';
import rank17 from './img/rank/rank17.png';
import rank18 from './img/rank/rank18.png';
import rank19 from './img/rank/rank19.png';


export default function BaseRankImage ({ rank }) {
  let image;
  switch(rank) {
    case 0: image = rank0; break;
    case 1: image = rank1; break;
    case 2: image = rank2; break;
    case 3: image = rank3; break;
    case 4: image = rank4; break;
    case 5: image = rank5; break;
    case 6: image = rank6; break;
    case 7: image = rank7; break;
    case 8: image = rank8; break;
    case 9: image = rank9; break;
    case 10: image = rank10; break;
    case 11: image = rank11; break;
    case 12: image = rank12; break;
    case 13: image = rank13; break;
    case 14: image = rank14; break;
    case 15: image = rank15; break;
    case 16: image = rank16; break;
    case 17: image = rank17; break;
    case 18: image = rank18; break;
    case 19: image = rank19; break;
    default: image = ''; break;
  }

  return <Image src={ image }
                width='46px' height='29px'
                alt={'rank' + rank}
         />;
}
