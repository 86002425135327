/*
  GuiForceTypeDetail.tsx
  (c) Human Cube Inc.
*/

import { Card } from 'semantic-ui-react';

import { Game } from '../../bfcore/gameTypes';
import GuiForce from './GuiForce';


const forceTypeDescription = [   // Indexed by ForceID not forceIndex.
  <Card.Description>
  </Card.Description>,

  <Card.Description>
    <p>
    The basic millitary unit. Cheap, but like a marine, they get the job done.
    Troops perform better in defensive rather than offensive roles.</p>
    <p>
    Troops can only move on land. To move across water, they must be loaded onto a Transport Ship.
    Up to two Troops can be loaded onto a Transport Ship at one time.</p>
    <p>
    Troops on a Transport Ship do not contribute to combat. If a Transport Ship is destroyed, any
    Troops it is carrying are also destroyed.</p>
  </Card.Description>,

  <Card.Description>
    <p>
    The dominant land assault force, Tanks bring range and fire power to the battlefield.</p>
    <p>
    Tanks can only move on land. Transport Ships are required to move a Tank across water.
    Transport Ships can carry up to two Tanks at a time.</p>
    <p>
    While loaded on a Transport Ship, a Tank will be unable to contribute to combat. If a Transport
    Ship is destroyed while carrying a Tank, the Tank will also be destroyed.</p>
  </Card.Description>,

  <Card.Description>
    <p>
    Feared by all pilots, Surface To Air Missile (SAM) Launchers defend home airspace from invading
    aircraft.</p>
    <p>
    SAM Launchers block both land and air forces movement and are blocked by both land and air
    forces.</p>
    <p>
    SAM Launchers are a unique air defence only force. SAM Launchers can only fire when they are
    in their own land zones. SAM Launchers cannot fire on enemy soil, nor can they defend against
    land forces such as Troops or Tanks.</p>
    <p>
    SAM Launchers get a shot at each attacking Fighter or Bomber. The more enemy forces, the more
    they are able to fire. This makes them particularly useful for defending valuable locations
    such as capitals and production zones.</p>
    <p>
    SAM Launchers can only move over land with a range of 1. To move across water, they must be
    loaded on to a Transport Ship. A Transport Ship may carry up to two SAM Launchers at a time.</p>
    <p>
    SAM Launchers on Transport Ships cannot participate in combat. If a Transport Ship is
    destroyed while transporting a SAM Launcher, the SAM Launcher will also be destoryed.</p>
  </Card.Description>,

  <Card.Description>
    <p>
    Excellent multi-purpose force.</p>
    <p>
    Fighters block land and surface water forces but are not by blocked them, with the exception of
    the SAM Launcher which blocks all aircraft. Enemy aircraft will also block Fighters.</p>
    <p>
    Fighters must land at the end of every turn. They must land on an Aircraft Carrier or on a
    land territory that was owned since the beginning of the turn.</p>
    <p>
    Up to four Fighters may land on a single Aircraft Carrier.</p>
    <p>
    Fighters that do not land at a safe location at the end of a turn are destroyed.</p>
    <p>
    Fighters have a maximum range of 4. Care must be taken to ensure they have enough range to
    reach a safe landing spot.</p>
  </Card.Description>,

  <Card.Description>
    <p>
    Bombers are much stronger at attacking than defending.</p>
    <p>
    Bombers block land and surface water forces but are not by blocked them, with the exception of
    the SAM Launcher which blocks all aircraft. Enemy aircraft will also block Bombers.</p>
    <p>
    Bombers must land at the end of every turn. They must land on a land territory that was owned
    since the beginning of the turn. Bombers cannot land on Aircraft Carriers.</p>
    <p>
    Bombers that do not land at a safe location at the end of a turn are destroyed.</p>
    <p>
    Bombers have a maximum range of 6. Care must be taken to ensure they have enough range to reach
    a safe landing spot.</p>
  </Card.Description>,

  <Card.Description>
    <p>
    Useful for disrupting shipping supply lines, Submarines are the silent hunters of the deep.</p>
    <p>
    Submarines are never blocked, but they block other ships, and can disrupt shipping movement by
    blocking them.</p>
    <p>
    Submarines can only attack forces that are in or on the water. This makes them vulnerable and
    unable to retaliate against aircraft.</p>
  </Card.Description>,

  <Card.Description>
    <p>
    Transport Ships do not attack nor defend. Hard working
    and under respected, their main usefulness is in transporting land forces over water.</p>
    <p>
    Transport Ships can carry up to two units of cargo.
    Troops, Tanks and SAM Launchers each take up one unit of cargo space.
    In other words, a Transport Ship can, at most, carry two land forces at a time.
    These do not have to be of the same type.</p>
    <p>
    Transport Ships can load so long as they have enough Load Points and the force that is being
    loaded onto the transport is not blocked and has not yet moved.</p>
    <p>
    Transport ships cannot move or load after unloading.
    Transport ships do not block other forces, but can be blocked.
    If a Transport Ship has been blocked, it will be prevented from unloading.
    Unloading a Transport Ship does not require Load Points.
    </p>
    <p>
    Cargo that is unloaded may not move any further that turn.
    Cargo cannot be directly transfered between Transport Ships.</p>
    <p>
    Any Transport Ships destroyed while carrying cargo, will result in the cargo also being
    destroyed. Any forces that are carried as cargo cannot assist in combat until they are unloaded
    onto land.</p>
  </Card.Description>,

  <Card.Description>
    <p>
    A lifeline for Fighter aircraft, Aircraft Carriers can transport deadly force into enemy
    waters.</p>
    <p>
    Aircraft Carriers can carry up to four Fighters each.</p>
    <p>
    Bombers cannot land on Aircraft Carriers.</p>
  </Card.Description>,

  <Card.Description>
    <p>
    King of the seas, destroyers are the dominant force on the water.</p>
    <p>
    Primarily used to escort aircraft carriers and transport ships, destroyers can attack anything
    in the air or water, but cannot attack land.</p>
  </Card.Description>,

  <Card.Description>
    <p>
    New forces are created in zones with a factory. Factories can also produce new naval forces in
    directly adjacent water zones, however the water zone must be empty or contain at least one
    friendly force. Water zones containing only enemy forces will block production.</p>
    <p>
    Factories cannot move or be destroyed. If a zone is captured with a factory in it, ownership of
    the factory is automatically transfered to the new owner of the zone.</p>
    <p>
    Factories located at a player's capital has unrestricted production. All other factories are
    restricted to producing a maximum number of forces equal to the income count for the zone. For
    example, a factory on a zone with 3 income can only produce at most three forces per turn.</p>
    <p>
    New factories that are purchased, may only be placed on land zones that a player had already
    owned at the beginning of the turn. A maximum of one factory is permitted per zone.</p>
    <p>
    New forces cannot be placed at a new factory on the same turn that the factory is built or
    captured.</p>
  </Card.Description>,
];

const GuiForceTypeDetail = ({game, forceTypeIndex}: {game: Game, forceTypeIndex: number}) => {
  const forceTypeID = game.forceID[forceTypeIndex];
  return(
    <Card>
      <Card.Content>
        <Card.Header >
          <GuiForce forceType={ forceTypeID } />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          { game.forceName[forceTypeIndex] }
        </Card.Header>
        { forceTypeDescription[forceTypeID] ?
          forceTypeDescription[forceTypeID] : 
          <Card.Description>
            { game.forceDescription[forceTypeIndex].split('\n').map((item, index, array) => {
                return <p key={ 'p' + index } >{ item }</p>;
              })
            }
          </Card.Description>
        }
      </Card.Content>
    </Card>
  );
};


export default GuiForceTypeDetail;
