/*
  BaseForceTypes.js
  (c) Human Cube Inc.
*/

import { Grid } from 'semantic-ui-react';

import BaseCamoBackScroll from './BaseCamoBackScroll';
import BaseMenuBasic from './BaseMenuBasic';
import BasePlayerTile from './BasePlayerTile';


export default function BaseFriendFoeList ({store}) {

  const { friendFoeList, playerStrings } = store;

  const playerTiles = [];
  for (let uid in friendFoeList) {
    playerTiles.push(
      <Grid.Column key={ uid }>
        <BasePlayerTile
          uid={ uid }
          playerString={ playerStrings[uid] }
        />
      </Grid.Column>
    );
  }
  if(playerTiles.length < 1) {
    playerTiles.push(
      <Grid.Column key='none'>This list is currently empty.</Grid.Column>
    );
  }

  return (
    <BaseCamoBackScroll>
      <BaseMenuBasic pageName='Friends and Foes' />
      <center>
        <br /><br />
        <h5 key='one'>
          Keep your friends close and your enemies closer.
        </h5>
        <h5 key='two'>
          From a player's profile page, you may add/remove them to this list.
        </h5>
        <Grid centered doubling columns={6} style={{padding: '3px'}}>
          { playerTiles }
        </Grid>
        <br /><br /><br />
        <br /><br /><br />
      </center>
    </BaseCamoBackScroll>
  );
}
