import React from "react";
import { Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";

import texture from './img/explosionbg.png';

const centerAnchor = new PIXI.Point(0.5, 0.5);

export default function ExplosionBG(props) {
  return (
    <Sprite
      anchor={ centerAnchor }
      texture={ PIXI.Texture.from(texture) }
      { ...props }
    />
  );
}
