/*
  BaseNewsItem.js
  (c) Human Cube Inc.
*/

import { Link } from 'react-router-dom';

import BF from '../../bfcore/bfconst1';


export default function BaseNewsItem ({newsItem}) {

  const newsGameTitle = () => {
    const gameName = newsItem.gName;
    const gameID = newsItem.gid;
    return(
      <Link to={'/game/' + gameID}>
        <span style={{ color:'#0645AD', cursor: 'pointer', borderBottom: '1px solid #FFFFFF' }}>
          { gameName }
        </span>
      </Link>
    );
  };

  const newsPlayerTitle = () => {
    const userName = newsItem.uName;
    const playerID = newsItem.uid;
    return(
      <Link to={'/player/' + playerID}>
        <span style={{ color:'#0645AD', cursor: 'pointer', borderBottom: '1px solid #FFFFFF' }}>
          { userName }
        </span>
      </Link>
    );
  };

  switch(newsItem.e) {
    case 'userNew':
      return(
        <li>
          Welcome new player { newsPlayerTitle() }!
        </li>
      );
    case 'userMembership':
      return(
        <li>
          { newsPlayerTitle() } has boosted their membership.
        </li>
      );
    case 'userRankChange':
      if(newsItem.oldRank < newsItem.newRank) {
        return(
          <li>
            { newsPlayerTitle() } has been promoted from <b>
            { BF.PLAYER_RANK_TITLE[newsItem.oldRank] }</b> to <b>
            { BF.PLAYER_RANK_TITLE[newsItem.newRank] }</b>.
          </li>
        );
      }
      if(newsItem.oldRank > newsItem.newRank) {
        return(
          <li>
            { newsPlayerTitle() } has been demoted from <b>
            { BF.PLAYER_RANK_TITLE[newsItem.oldRank] }</b> to <b>
            { BF.PLAYER_RANK_TITLE[newsItem.newRank] }</b>.
          </li>
        );
      }
      return null;
    case 'gameReg':
      if(newsItem.uName) {
        return(
          <li>
            { newsGameTitle() } started by { newsPlayerTitle() } is now accepting new players.
          </li>
        );
      }
      return(
        <li>
          { newsGameTitle() } is now accepting new players.
        </li>
      );
    case 'gameStart':
      return(
        <li>
          { newsGameTitle() } is full and is starting.
        </li>
      );
    case 'gameFinish':
      return(
        <li>
          { newsGameTitle() } has finished.
        </li>
      );
    case 'gameCancel':
      return(
        <li>
          { newsGameTitle() } has been canceled by { newsPlayerTitle() }.
        </li>
      );
    case 'gameExpired':
      return(
        <li>
          { newsGameTitle() } has expired.
        </li>
      );
    case 'gameJoin':
      return(
        <li>
          { newsPlayerTitle() } has joined { newsGameTitle() }.
        </li>
      );
    case 'gameUnjoin':
      return(
        <li>
          { newsPlayerTitle() } has unregistered from { newsGameTitle() }.
        </li>
      );
    default:
      console.log('Unsupported News Item', newsItem);
      return null;
  }
}
