/*
  bf_forceTypeFixtures.ts
  (c) Human Cube Inc.
*/

import BF from './bfconst1';
import { Game } from './gameTypes';


interface ForceType {
  _id: number;    // Force Type ID.
  uid: number;    // UID of creator of the force type, currently always the GameAdmin (2).
  uName: string;  // Currently always 'GameAdmin'.
  name: string;
  namePlural: string;
  description: string;  // Description, line feeds will be converted to paragraphs on render.
  flags: number;    // FORCE_FLAG_* values.
  cost: number;
  range: number;
  attack: number;   // 0 to 100.
  attackMultiple: number;  // number of attack 'dice', usually 1.
  defense: number;  // 0 to 100.
  defenseMultiple: number;  // number of defense 'dice', usually 1.
  cargoCapacity: number;
  cargoSpace: number;    // Space it takes up, -1 for cannot be cargo.
  carrierCapacity: number;
  carrierSpace: number;  // Carrier space it takes up, -1 for cannot land on carrier.
  liftCapacity: number;
  liftSpace: number;     // Lift space it takes up, -1 for cannot be lifted.
  production: number;
  casualtyOrder: number;  // Lower values are selected as casualties first (greater than 0).
  scale: number;  // Render scale, 1.0 for regular, smaller for smaller.
  version: number;
  created: number;  // Unix epoch milliseconds.
  updated: number;  // Unix epoch milliseconds.
};

interface ForceTypeCache {
  [Key: number]: ForceType;
}

// Array of all individual force types indexed by _id:
export const forceTypeCache: ForceTypeCache = {};


export function assignForceTypesInfo (game: Game) {
  // Modifies game and adds details of its force types.
  // Assumes game.forceID[] array is already filled with the desired forceTypeIDs for the game.
  for(let f = 0; f < game.forceID.length; f++) {
    const fid = game.forceID[f];
    if(fid && forceTypeCache[fid]) {
      game.forceName[f] = forceTypeCache[fid].name;
      game.forceNamePlural[f] = forceTypeCache[fid].namePlural;
      game.forceDescription[f] = forceTypeCache[fid].description;
      game.forceFlags[f] = forceTypeCache[fid].flags;
      game.forceCost[f] = forceTypeCache[fid].cost;
      game.forceRange[f] = forceTypeCache[fid].range;
      game.forceAttack[f] = forceTypeCache[fid].attack;
      game.forceAttackMultiple![f] = forceTypeCache[fid].attackMultiple;
      game.forceDefense[f] = forceTypeCache[fid].defense;
      game.forceDefenseMultiple![f] = forceTypeCache[fid].defenseMultiple;
      game.forceCargoCapacity[f] = forceTypeCache[fid].cargoCapacity;
      game.forceCargoSpace[f] = forceTypeCache[fid].cargoSpace;
      game.forceCarrierCapacity[f] = forceTypeCache[fid].carrierCapacity;
      game.forceCarrierSpace[f] = forceTypeCache[fid].carrierSpace;
      game.forceLiftCapacity[f] = forceTypeCache[fid].liftCapacity;
      game.forceLiftSpace[f] = forceTypeCache[fid].liftSpace;
      game.forceProduction[f] = forceTypeCache[fid].production;
      game.forceZ[f] = 1;
      game.forceScale[f] = forceTypeCache[fid].scale;
      game.forceSfxMake[f] = 0;
      game.forceSfxMove[f] = 0;
      game.forceSfxDie[f] = 0;
      game.forceVersion[f] = forceTypeCache[fid].version;
    }
    else {
      console.error('Error: Unable to find', fid, 'in forceTypeCache for assignForceTypesInfo!');
    }
  }
}


forceTypeCache[1] = {
  _id: 1,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Troop',
  namePlural: 'Troops',
  description: 'Standard Troop',
  flags: 
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_LAND |
    BF.FORCE_FLAG_IS_SURFACE_ONLY_TARGET |
    BF.FORCE_FLAG_BLOCKS_SURFACE |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_SURFACE | BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_CAN_HOLD |
    BF.FORCE_FLAG_CAN_CLAIM,
  cost: 10,
  range: 1,
  attack: 15,
  attackMultiple: 1,
  defense: 30,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: 1,
  carrierCapacity: 0,
  carrierSpace: -1,
  liftCapacity: 0,
  liftSpace: 1,
  production: 0,
  casualtyOrder: 100,
  scale: 1,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[2] = {
  _id: 2,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Tank',
  namePlural: 'Tanks',
  description: 'Standard Tank',
  flags: 
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_LAND |
    BF.FORCE_FLAG_IS_SURFACE_ONLY_TARGET |
    BF.FORCE_FLAG_BLOCKS_SURFACE |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_SURFACE | BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_CAN_HOLD |
    BF.FORCE_FLAG_CAN_CLAIM,
  cost: 15,
  range: 2,
  attack: 50,
  attackMultiple: 1,
  defense: 30,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: 1,
  carrierCapacity: 0,
  carrierSpace: -1,
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 200,
  scale: 1,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[3] = {
  _id: 3,
  uid: 2,
  uName: 'GameAdmin',
  name: 'SAM Launcher',
  namePlural: 'SAM Launchers',
  description: 'Standard SAM Launcher',
  flags:
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_LAND |
    BF.FORCE_FLAG_IS_SURFACE_ONLY_TARGET |
    BF.FORCE_FLAG_BLOCKS_SURFACE | BF.FORCE_FLAG_BLOCKS_AIR |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_SURFACE | BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_HITS_ONLY_AIR_TARGETS |
    BF.FORCE_FLAG_ONESHOTPERTARGET |
    BF.FORCE_FLAG_CAN_HOLD |
    BF.FORCE_FLAG_CAN_CLAIM,
  cost: 15,
  range: 1,
  attack: 0,
  attackMultiple: 1,
  defense: 20,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: 1,
  carrierCapacity: 0,
  carrierSpace: -1,
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 200,
  scale: 1,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[4] = {
  _id: 4,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Fighter',
  namePlural: 'Fighters',
  description: 'Standard Fighter',
  flags:
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_LAND | BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_AIR_ONLY_TARGET |
    BF.FORCE_FLAG_BLOCKS_SURFACE | BF.FORCE_FLAG_BLOCKS_AIR |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_MUST_LAND_FRIENDLY |
    BF.FORCE_FLAG_CAN_HOLD,
  cost: 30,
  range: 4,
  attack: 50,
  attackMultiple: 1,
  defense: 65,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: 1,
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 400,
  scale: 1,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[5] = {
  _id: 5,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Bomber',
  namePlural: 'Bombers',
  description: 'Standard Bomber',
  flags:
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_LAND | BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_AIR_ONLY_TARGET |
    BF.FORCE_FLAG_BLOCKS_SURFACE | BF.FORCE_FLAG_BLOCKS_AIR |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_MUST_LAND_FRIENDLY |
    BF.FORCE_FLAG_CAN_HOLD,
  cost: 35,
  range: 6,
  attack: 65,
  attackMultiple: 1,
  defense: 15,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: -1,
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 500,
  scale: 1,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[6] = {
  _id: 6,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Submarine',
  namePlural: 'Submarines',
  description: 'Standard Submarine',
  flags:
    BF.FORCE_FLAG_STARTS_ON_WATER |
    BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_SURFACE_ONLY_TARGET |
    BF.FORCE_FLAG_BLOCKS_SURFACE |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_HITS_ONLY_SURFACE_TARGETS,
  cost: 20,
  range: 2,
  attack: 30,
  attackMultiple: 1,
  defense: 30,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: -1,
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 300,
  scale: 1,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[7] = {
  _id: 7,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Transport',
  namePlural: 'Transports',
  description: 'Standard Transport',
  flags:
    BF.FORCE_FLAG_STARTS_ON_WATER |
    BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_SURFACE_ONLY_TARGET |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_SURFACE | BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_CAN_LOAD_WHEN_BLOCKED,
  cost: 20,
  range: 2,
  attack: 0,
  attackMultiple: 1,
  defense: 0,
  defenseMultiple: 1,
  cargoCapacity: 2,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: -1,
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 300,
  scale: 1,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[8] = {
  _id: 8,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Carrier',
  namePlural: 'Carriers',
  description: 'Standard Aircraft Carrier',
  flags:
    BF.FORCE_FLAG_STARTS_ON_WATER |
    BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_SURFACE_ONLY_TARGET |
    BF.FORCE_FLAG_BLOCKS_SURFACE |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_SURFACE | BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION,
  cost: 35,
  range: 2,
  attack: 35,
  attackMultiple: 1,
  defense: 35,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 4,
  carrierSpace: -1,
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 600,
  scale: 1,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[9] = {
  _id: 9,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Destroyer',
  namePlural: 'Destroyers',
  description: 'Standard Destroyer',
  flags:
    BF.FORCE_FLAG_STARTS_ON_WATER |
    BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_SURFACE_ONLY_TARGET |
    BF.FORCE_FLAG_BLOCKS_SURFACE |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_SURFACE | BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION,
  cost: 25,
  range: 2,
  attack: 50,
  attackMultiple: 1,
  defense: 50,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: -1,
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 400,
  scale: 1,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[10] = {
  _id: 10,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Factory',
  namePlural: 'Factories',
  description: 'Standard Factory',
  flags: 
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAPITAL_PROD_UNLIMITED |
    BF.FORCE_FLAG_PROD_SCALE_ZONE_INCOME |
    BF.FORCE_FLAG_CAN_PRODUCE_NEIGHBOUR |
    BF.FORCE_FLAG_PROD_MAX_ONE_PER_ZONE |
    BF.FORCE_FLAG_OWNED_BY_ZONE_OWNER,
  cost: 45,
  range: 0,
  attack: 0,
  attackMultiple: 1,
  defense: 0,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: -1,
  liftCapacity: 0,
  liftSpace: -1,
  production: 1,
  casualtyOrder: -1,  // Cannot be a casualty anyways.
  scale: 1,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[11] = {
  _id: 11,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Cargo Plane',
  namePlural: 'Cargo Planes',
  description:
`Cargo Planes are unarmed. They carry and deploy single troop as a paratrooper.
They do not block other forces, but are blocked by other aircraft and SAM launchers.`,
  flags: 
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_LAND | BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_AIR_ONLY_TARGET |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_MUST_LAND_FRIENDLY | 
    BF.FORCE_FLAG_CAN_HOLD |
    BF.FORCE_FLAG_CAN_MOVE_AFTER_UNLOADING |
    BF.FORCE_FLAG_LOAD_SAME_ZONE | BF.FORCE_FLAG_UNLOAD_SAME_ZONE,
  cost: 25,
  range: 6,  // Currently cannot be more than 6 as 1 stage load and 1 stage unload (8 max stages).
  attack: 0,
  attackMultiple: 1,
  defense: 0,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: -1,  // Cannot land on carriers.
  liftCapacity: 1,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 399,
  scale: 0.35,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[12] = {
  _id: 12,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Heavy Bomber',
  namePlural: 'Heavy Bombers',
  description:
`Heavy Bombers, when attacking, do three times the potential damage of regular Bombers.
Like all Bombers, they must land on a friendly land zone at the end of each turn.`,
  flags:
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_LAND | BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_AIR_ONLY_TARGET |
    BF.FORCE_FLAG_BLOCKS_SURFACE | BF.FORCE_FLAG_BLOCKS_AIR |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_MUST_LAND_FRIENDLY |
    BF.FORCE_FLAG_CAN_HOLD,
  cost: 35,
  range: 5,  // The range is a little less than regular Bombers.
  attack: 65,
  attackMultiple: 3,
  defense: 15,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: -1,
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 502,
  scale: 0.4,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[13] = {
  _id: 13,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Stealth Fighter',
  namePlural: 'Stealth Fighters',
  description:
'Stealth fighter, cannot be blocked by other forces. It also does not block any forces.',
  flags:
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_LAND | BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_AIR_ONLY_TARGET |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_MUST_LAND_FRIENDLY | 
    BF.FORCE_FLAG_CAN_HOLD,
  cost: 40,
  range: 4,
  attack: 50,
  attackMultiple: 1,
  defense: 65,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: 1,  // Can land on carriers.
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 550,
  scale: 0.25,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[14] = {
  _id: 14,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Stealth Bomber',
  namePlural: 'Stealth Bombers',
  description:
`Stealth bomber, cannot be blocked by other forces. It also does not block any forces.
Like all Bombers, they must land on a friendly land zone at the end of each turn.`,
  flags: 
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_LAND | BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_AIR_ONLY_TARGET |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_MUST_LAND_FRIENDLY | 
    BF.FORCE_FLAG_CAN_HOLD,
  cost: 50,
  range: 6,
  attack: 65,
  attackMultiple: 1,
  defense: 15,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: -1,  // Cannot land on carriers.
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 551,
  scale: 0.25,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[15] = {
  _id: 15,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Long Range Bomber',
  namePlural: 'Long Range Bombers',
  description:
`Long Range Bombers have superior range to all other forces.
Like all Bombers, they must land on a friendly land zone at the end of each turn.`,
  flags:
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_AIR_ONLY_TARGET |
    BF.FORCE_FLAG_BLOCKS_SURFACE |
    BF.FORCE_FLAG_BLOCKS_AIR |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_MUST_LAND_FRIENDLY |
    BF.FORCE_FLAG_CAN_HOLD,
  cost: 35,
  range: 8,
  attack: 65,
  attackMultiple: 1,
  defense: 15,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: -1,
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 501,
  scale: 1,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[16] = {
  _id: 16,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Cambat Helicopter',
  namePlural: 'Combat Helicopters',
  description:
'Combat Helicopters. Must finish turn on any land or on a friendly carrier.',
  flags: 
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_LAND | BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_AIR_ONLY_TARGET |
    BF.FORCE_FLAG_BLOCKS_SURFACE | BF.FORCE_FLAG_BLOCKS_AIR |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_MUST_LAND_ANY | // Must finish turn on any land zone or on a carrier.
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_CAN_HOLD | BF.FORCE_FLAG_CAN_CLAIM,
  cost: 25,
  range: 2,
  attack: 40,
  attackMultiple: 1,
  defense: 40,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: 1,  // Can land on carriers.
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 350,
  scale: 0.36,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[17] = {
  _id: 17,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Lift Helicopter',
  namePlural: 'Lift Helicopters',
  description:
'Lift Helicopters. Lightly armed but can carry one troop into battle.',
  flags: 
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_LAND | BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_AIR_ONLY_TARGET |
    BF.FORCE_FLAG_BLOCKS_SURFACE | BF.FORCE_FLAG_BLOCKS_AIR |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_MUST_LAND_ANY | // Must finish turn on any land or on carrier.
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_CAN_HOLD | BF.FORCE_FLAG_CAN_CLAIM |
    BF.FORCE_FLAG_CAN_MOVE_AFTER_UNLOADING |
    BF.FORCE_FLAG_LOAD_SAME_ZONE | BF.FORCE_FLAG_UNLOAD_SAME_ZONE |
    BF.FORCE_FLAG_CAN_UNLOAD_WHEN_BLOCKED,
  cost: 25,
  range: 2,
  attack: 10,
  attackMultiple: 1,
  defense: 10,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: 1,  // Can land on carriers.
  liftCapacity: 1,  // Can carry one lift amount.
  liftSpace: -1,
  production: 0,
  casualtyOrder: 349,
  scale: 0.4,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[18] = {
  _id: 18,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Scud Missile',
  namePlural: 'Scud Missile',
  description:
'Scud Missile. Explodes as soon as it moves or gets attacked. Effective only in offensive combat.',
  flags: 
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_LAND | BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_AIR_ONLY_TARGET |
    BF.FORCE_FLAG_BLOCKS_SURFACE | BF.FORCE_FLAG_BLOCKS_AIR |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_CAN_HOLD |
    BF.FORCE_FLAG_TERMINATES_IF_ATTACKED |
    BF.FORCE_FLAG_TERMINATES_IF_MOVED,
  cost: 15,
  range: 2,
  attack: 100,
  attackMultiple: 1,
  defense: 0,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: -1,
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 10,  // Not really a casualty as it explodes in combat (but still chosen firstish).
  scale: 0.25,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};

forceTypeCache[19] = {
  _id: 19,
  uid: 2,
  uName: 'GameAdmin',
  name: 'Nuke ICBM',
  namePlural: 'Nuke ICBMs',
  description:
'Nuclear Intercontinental Balistic Missile. Destroys all forces (friends and foes) in a zone. Explodes as soon as it moves or gets attacked.',
  flags: 
    BF.FORCE_FLAG_STARTS_ON_LAND |
    BF.FORCE_FLAG_CAN_MOVE_ON_LAND | BF.FORCE_FLAG_CAN_MOVE_ON_WATER |
    BF.FORCE_FLAG_IS_AIR_ONLY_TARGET |
    BF.FORCE_FLAG_BLOCKS_SURFACE | BF.FORCE_FLAG_BLOCKS_AIR |
    BF.FORCE_FLAG_CAN_BE_BLOCKED_BY_AIR |
    BF.FORCE_FLAG_REQUIRES_PRODUCTION |
    BF.FORCE_FLAG_CAN_HOLD |
    BF.FORCE_FLAG_TERMINATES_IF_ATTACKED |
    BF.FORCE_FLAG_TERMINATES_IF_MOVED |
    BF.FORCE_FLAG_DESTROYS_ALL,
  cost: 200,
  range: 5,
  attack: 100,
  attackMultiple: 1,
  defense: 100,
  defenseMultiple: 1,
  cargoCapacity: 0,
  cargoSpace: -1,
  carrierCapacity: 0,
  carrierSpace: -1,
  liftCapacity: 0,
  liftSpace: -1,
  production: 0,
  casualtyOrder: 11,  // Not really a casualty as it explodes in combat (but still chosen firstish).
  scale: 0.3,
  version: 0,
  created: Date.now(),
  updated: Date.now(),
};


// Assault Ship (amphibious carrier) maybe 1 load, and 1 carrier space?

// Field Artillery (could make it only attack neigbouring zones, maybe have two attack dice?...)
