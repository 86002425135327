/*
  BaseJoinSolo.js
  (c) Human Cube Inc.
*/

import { useNavigate } from 'react-router-dom';
import { Message, Table } from 'semantic-ui-react';

import BaseAdSense from './BaseAdSense';
import BaseBackScroll from './BaseBackScroll';
import BaseMenuBasic from './BaseMenuBasic';
import background from './img/background/submarine1107176.jpg';


function JoinSoloTableRow ({ color, scenarioID, name, uName, uid, membership}) {
  const navigate = useNavigate();
  return (
    <Table.Row
      key={ scenarioID }
      onPointerUp={() => { navigate('/scenario/' + scenarioID); }}
      style={{cursor: 'pointer'}}
    >
      <Table.Cell><u>{ scenarioID }</u></Table.Cell>
      <Table.Cell>{ name }</Table.Cell>
      <Table.Cell>{ uName }</Table.Cell>
      <Table.Cell>{ membership }</Table.Cell>
    </Table.Row>
  );
}

export default function BaseJoinSolo ({store}) {

  const { soloScenarios } = store;

  const scenarioTiles = [];

  for(let i = 0; i < soloScenarios.standard.basic.length; i++) {
    const item = soloScenarios.standard.basic[i];
    const scenarioID = item.id;
    scenarioTiles.push(
      <JoinSoloTableRow
        key={ scenarioID }
        color='olive'
        scenarioID={ scenarioID }
        name={ item.name }
        uName={ item.uName }
        uid={ item.uid }
        membership='Anyone'
      />
    );
  }

  for(let i = 0; i < soloScenarios.standard.premium.length; i++) {
    const item = soloScenarios.standard.premium[i];
    const scenarioID = item.id;
    scenarioTiles.push(
      <JoinSoloTableRow
        key={ scenarioID }
        color='yellow'
        scenarioID={ scenarioID }
        name={ item.name }
        uName={ item.uName }
        uid={ item.uid }
        membership='Premium or Extreme'
      />
    );
  }

  for(let i = 0; i < soloScenarios.standard.extreme.length; i++) {
    const item = soloScenarios.standard.extreme[i];
    const scenarioID = item.id;
    scenarioTiles.push(
      <JoinSoloTableRow
        key={ scenarioID }
        color='red'
        scenarioID={ scenarioID }
        name={ item.name }
        uName={ item.uName }
        uid={ item.uid }
        membership='Extreme Only'
      />
    );
  }

  for(let i = 0; i < soloScenarios.user.basic.length; i++) {
    const item = soloScenarios.user.basic[i];
    const scenarioID = item.id;
    scenarioTiles.push(
      <JoinSoloTableRow
        key={ scenarioID }
        color='olive'
        scenarioID={ scenarioID }
        name={ item.name }
        uName={ item.uName }
        uid={ item.uid }
        membership='Anyone'
      />
    );
  }

  for(let i = 0; i < soloScenarios.user.premium.length; i++) {
    const item = soloScenarios.user.premium[i];
    const scenarioID = item.id;
    scenarioTiles.push(
      <JoinSoloTableRow
        key={ scenarioID }
        color='yellow'
        scenarioID={ scenarioID }
        name={ item.name }
        uName={ item.uName }
        uid={ item.uid }
        membership='Premium or Extreme'
      />
    );
  }

  for(let i = 0; i < soloScenarios.user.extreme.length; i++) {
    const item = soloScenarios.user.extreme[i];
    const scenarioID = item.id;
    scenarioTiles.push(
      <JoinSoloTableRow
        key={ scenarioID }
        color='red'
        scenarioID={ scenarioID }
        name={ item.name }
        uName={ item.uName }
        uid={ item.uid }
        membership='Extreme Only'
      />
    );
  }

  return (
    <BaseBackScroll background={ background } >
      <BaseMenuBasic pageName='Solo Games Available to Join' />
      <center>
        <br /><br /><br />
        <BaseAdSense />
        <br />
        <Message compact style={{ opacity: 0.9 }}>
          <p>
            Below is the list of available solo games.<br />
            Some are available for all players, others are for higher memberships only.<br />
            Tap any one to view futher details and to play.<br />
          </p>
        </Message>
        <Table collapsing striped selectable style={{ opacity: 0.9 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Scenario ID
              </Table.HeaderCell>
              <Table.HeaderCell>
                Scenario Name
              </Table.HeaderCell>
              <Table.HeaderCell>
                Created By
              </Table.HeaderCell>
              <Table.HeaderCell>
                Membership
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { scenarioTiles }
          </Table.Body>
        </Table>
        <br /><br /><br />
        <br /><br /><br />
      </center>
    </BaseBackScroll>
  );
}
