/*
  BaseLeaderboardScore.js
  (c) Human Cube Inc.
*/

import { Card, List } from 'semantic-ui-react';

import BaseAdSense from './BaseAdSense';
import BaseCamoBackScroll from './BaseCamoBackScroll';
import BaseLeaderboardPlayerValue from './BaseLeaderboardPlayerValue';
import BaseMenuBasic from './BaseMenuBasic';
import backgroundVideo from './vid/1b1takeoff.mp4';


export default function BaseLeaderboardScore ({ store }) {

  const { leaderboardScore, playerStrings } = store;

  const leaderboard = [];
  for(let i = 0; i < leaderboardScore.length / 2; i++) {
    const uid = leaderboardScore[i * 2];
    const score = leaderboardScore[i * 2 + 1];
    leaderboard.push(
      <BaseLeaderboardPlayerValue
        key={ 'k' + i }
        uid={ uid }
        rank={ i + 1 }
        value={ score }
        playerString={ playerStrings[uid] }
      />
    );
  }

  return (
    <div>
      <video autoPlay muted loop playsInline={true} disablePictureInPicture={true}
        style={{
          objectFit: 'cover',
          position: 'fixed',
          right: '0',
          bottom: '0',
          minWidth: '100%',
          minHeight: '100%',
      }}>
        <source src={ backgroundVideo } type="video/mp4" />
      </video>
      <BaseMenuBasic pageName='Score Leaderboard' />
      <BaseCamoBackScroll>
        <center>
          <br /><br /><br />
          <BaseAdSense />
        </center>
        <Card.Group centered>
          <Card style={{ opacity: 0.75 }}>
            <List selection>
              { leaderboard }
            </List>
          </Card>
        </Card.Group>
        <br /><br /><br />
        <br /><br /><br />
      </BaseCamoBackScroll>
    </div>
  );
}
