/*
  BaseFinishedGames.js
  (c) Human Cube Inc.
*/

import { useNavigate } from 'react-router-dom';
import { Message, Table } from 'semantic-ui-react';

import BaseAdSense from './BaseAdSense';
import BaseBackScroll from './BaseBackScroll';
import BaseMapIcon from './BaseMapIcon';
import BaseMenuBasic from './BaseMenuBasic';
import BF from '../../bfcore/bfconst1';
import background from './img/background/ship746283.jpg';


function FinishedGameTableRow ({gameID, gameStatus, uid}) {
  const navigate = useNavigate();

  if(!gameStatus || !gameStatus.name) {
    return (
      <Table.Row
        onPointerUp={() => { navigate('/game/' + gameID); }}
        style={{cursor: 'pointer'}}
      >
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <u>{ gameID }</u>
        </Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          Game { gameID }
        </Table.Cell>
      </Table.Row>
    );
  }

  const playerList = [];
  for(let p = 0; p < gameStatus.playerID.length; p++) {
    if(gameStatus.playerID[p] > 0 && gameStatus.playerID[p] !== uid) {
      playerList.push(gameStatus.playerName[p]);
    }
  }

  return (
    <Table.Row
      onPointerUp={() => { navigate('/game/' + gameID); }}
      style={{cursor: 'pointer'}}
    >
      <Table.Cell textAlign='center'>{ BF.GAME_STATE_STRING[gameStatus.state] }</Table.Cell>
      <Table.Cell>
        <u>{gameID}</u>
      </Table.Cell>
      <Table.Cell>
        <BaseMapIcon mapID={ gameStatus.mapID } />
      </Table.Cell>
      <Table.Cell>
        {gameStatus.name}<br />
        <font color='grey'><small>{playerList.join(', ')}</small></font>
      </Table.Cell>
    </Table.Row>
  );
}


export default function BaseFinishedGames ({store}) {

  const {finishedList, gameStatus} = store;
  const uid = store.userSession.uid;

  const gameTiles = [];
  for(let i = 0; i < finishedList.length; i++) {
    const gameID = finishedList[i];
    gameTiles.push(
      <FinishedGameTableRow
        key={ gameID }
        gameID={ gameID }
        gameStatus={ gameStatus[gameID] }
        uid={ uid }
      />
    );
  }

  return (
    <BaseBackScroll background={ background } >
      <BaseMenuBasic pageName='Finished Games' />
      <center>
        <br /><br /><BaseAdSense />
        <br />
        <Message compact style={{ opacity: 0.85 }}>
          <p>
            All of your completed games are listed below.<br />
            Tap any one to view futher details.<br />
          </p>
        </Message>
        <Table collapsing striped selectable style={{ opacity: 0.85 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign='center'>
                State
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>
                Game ID
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>
                Map
              </Table.HeaderCell>
              <Table.HeaderCell>
                Game Name and Players
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { gameTiles }
          </Table.Body>
        </Table>
        <br /><br /><br />
        <br /><br /><br />
      </center>
    </BaseBackScroll>
  );
}
