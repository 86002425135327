/*
  BaseScenarioDetailStartGame.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { Button, Checkbox, Form, Modal, Segment } from 'semantic-ui-react';

import { sendPacket } from '../../network/network';
import actions from '../../reducers/actions';
import { store } from '../../app/store';
import BF from '../../bfcore/bfconst1';


export default class BaseScenarioDetailStartGame extends React.Component {

  state = {
    closeHandle: null,
    membershipLevel: 0,
    scenarioID: 0,
    scoringGame: true,
    joinGame: 0,
  };

  constructor(props) {
    super(props);  // Always call the parent class' constructor.
    this.state.closeHandle = props.closeHandle;
    this.state.membershipLevel = props.membershipLevel;
    this.state.scenarioID = props.scenarioID;
    this.state.scoringGame = true;
    this.state.joinGame = 0;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.closeHandle !== this.state.closeHandle ||
        nextProps.membershipLevel !== this.state.membershipLevel ||
        nextProps.scenarioID !== this.state.scenarioID) {
      this.setState({
        closeHandle: nextProps.closeHandle,
        membershipLevel: nextProps.membershipLevel,
        scenarioID: nextProps.scenarioID,
        scoringGame: true,
        joinGame: 0,
      });
    }
  }

  startGameUpdateClose = () => {
    const { scenarioID } = this.state;
    if(scenarioID > 0) {
      let flags = 0;
      if(!this.state.scoringGame) {
        flags |= BF.GAME_FLAG_DO_NOT_SCORE;
      }
      switch(this.state.joinGame) {
        case 1:
          flags |= BF.GAME_FLAG_PRIVATE;
          break;
        case 2:
          flags |= BF.GAME_FLAG_PREMIUM_MEMBERS_ONLY;
          break;
        case 3:
          flags |= BF.GAME_FLAG_EXTREME_MEMBERS_ONLY;
          break;
        default:
          break;
      }

      // TODO low: Min Score (maybe later...)
      // TODO low: Max Score (maybe later...)
      // TODO low: Play Times (maybe later...)

      sendPacket('bf_gameCreate', {scenarioID, flags});
    }
    if(this.state.closeHandle) {
      this.state.closeHandle();
    }
    // Jump all the way home so they can see the game show up in their active list:
    store.dispatch({type: actions.GO_HOME});
  };

  cancelClose = () => {
    if(this.state.closeHandle) {
      this.state.closeHandle();
    }
  }

  scoringGameOff = () => {
    this.setState({
      scoringGame: false,
    });
  }

  scoringGameOn = () => {
    this.setState({
      scoringGame: true,
    });
  }

  joinGamePublic = () => {
    this.setState({
      joinGame: 0,
    });
  }

  joinGamePrivate = () => {
    this.setState({
      joinGame: 1,
    });
  }

  joinGamePremium = () => {
    this.setState({
      joinGame: 2,
    });
  }

  joinGameExtreme = () => {
    this.setState({
      joinGame: 3,
    });
  }

  render() {
    const { joinGame, membershipLevel, scoringGame } = this.state;

    return (
      <Modal
          closeOnDimmerClick={ false }
          closeOnEscape={ false }
          dimmer='inverted'
          open={ true }
          size='small'>
        <Modal.Content>
          <Modal.Description>
            <p>
              <b>New Game Options</b>
            </p>
            <p>
              Select whether the game will affect player scores and who is allowed to join.
            </p>
            <Segment key='ranked' compact>
              <Form>
                <Form.Field>
                  <Checkbox
                    radio
                    label='Ranked Game'
                    name='checkboxRank'
                    value='rankedOn'
                    checked={ scoringGame === true }
                    onChange={ this.scoringGameOn }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    radio
                    label='Unranked Game'
                    name='checkboxRank'
                    value='rankedOff'
                    checked={ scoringGame === false }
                    onChange={ this.scoringGameOff }
                  />
                </Form.Field>
              </Form>
            </Segment>

            <Segment key='join' compact>
              <Form>
                <Form.Field>
                  <Checkbox
                    radio
                    label='Public (Anyone)'
                    name='checkboxJoin'
                    value={ 0 }
                    checked={ joinGame === 0 }
                    onChange={ this.joinGamePublic }
                  />
                </Form.Field>
                { /*
                <Form.Field>
                  <Checkbox
                    radio
                    label='Private (PIN Required)'
                    name='checkboxJoin'
                    value={ 1 }
                    checked={ joinGame === 1 }
                    onChange={ this.joinGamePrivate }
                  />
                </Form.Field>
                */ }
                { membershipLevel >= 2 &&
                  <Form.Field>
                    <Checkbox
                      radio
                      label='Premium and Extreme Members Only'
                      name='checkboxJoin'
                      value={ 2 }
                      checked={ joinGame === 2 }
                      onChange={ this.joinGamePremium }
                    />
                  </Form.Field>
                }
                { membershipLevel >= 3 &&
                  <Form.Field>
                    <Checkbox
                      radio
                      label='Extreme Members Only'
                      name='checkboxJoin'
                      value={ 3 }
                      checked={ joinGame === 3 }
                      onChange={ this.joinGameExtreme }
                    />
                  </Form.Field>
                }
              </Form>
            </Segment>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ this.cancelClose } negative>
            Cancel
          </Button>
          <Button
            onClick={ this.startGameUpdateClose }
            positive
            labelPosition='right'
            icon='checkmark'
            content='Start New Game'
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
