import actions from './actions'

// Single chat item data from server format:
// 'chatID base36 | 1 | turnIndex base36 | milliseconds since Unix Epoch base36 |
//   playerIndex base36 | uid base36 | uName | Message';

function insertChatData (seedArray, items) {
  // Insert or assign the new chat item at the appropriate place sorted by cid.
  // Does not modify the source array (which can be undefined),
  // returns a new array (shallow copied if needed).
  // playerIndex is 0 for public, or index of other player, or 1 if a team chat in a team game.
  if(!Array.isArray(items)) {
    return seedArray;
  }
  let array = [];
  if(Array.isArray(seedArray)) {
    array = [...seedArray];
  }
  for(let c = 0; c < items.length; c++) {
    const chatString = items[c];
    const t = chatString.split('|');
    const cid = parseInt(t[0], 36);
    const storeItem = [cid, chatString];

    if(array.length === 0 || array[array.length - 1][0] < cid) {
      array.push(storeItem);  // Just add it.
      continue;
    }
    if(array[0][0] > cid) {
      array.unshift(storeItem);  // Add to front.
      continue;
    }

    let i = array.length - 1;
    while(i >= 0) {
      if(array[i][0] === cid) {
        array[i] = storeItem;
        break;
      }
      if(array[i][0] < cid) {
        array.splice(i + 1, 0, storeItem);
        break;
      }
      i--;
    }
    if(i < 0) {
      array.unshift(storeItem);  // Add to front.
    }
  }
  return array;
}

export function gameChatData (state = {}, action) {
  // Game player chat actual chat lines.
  // State is all cached private (and public) game chat data indexed by 'gameID|playerIndex' of an
  // array for each. Items can be undefined if no chat items added yet for it.
  //
  switch (action.type) {
    case actions.CHAT_ADD:
      const newState = Object.assign({}, state);
      const key = action.gameID + '|' + action.playerIndex;
      newState[key] = insertChatData(newState[key], action.data);
      return newState;
    default:
      return state;
  }
}

export function gameChatMeta (state = {}, action) {
  // Game player chat meta data indexed by gameID.
  // Each has {CID: [], RCID: []} which are arrays of integers for each playerIndex (0 for public).
  switch (action.type) {
    case actions.CHAT_META_ADD:
      const newState = Object.assign({}, state);
      newState[action.gameID] = action.data;
      return newState;

    case actions.CHAT_META_SET_SINGLE_CID:
      // Update a single CID specified by gameID and playerIndex.
      const key = action.gameID;
      const index = action.playerIndex;
      const cid = action.cid;

      // Create a new CID array (and copy if needed) and update our entry:
      let newCID = [];
      if(state[key] && Array.isArray(state[key].CID)) {
        newCID = [...state[key].CID];
      }
      newCID[index] = cid;

      // Just create a new RCID array (and copy if needed) to make sure it exists:
      let newRCID = [];
      if(state[key] && Array.isArray(state[key].RCID)) {
        newRCID = [...state[key].RCID];
      }

      const newState2 = Object.assign({}, state);
      newState2[key] = {CID: newCID, RCID: newRCID};
      return newState2

    case actions.CHAT_META_SET_SINGLE_RCID:
      // Update a single RCID specified by gameID and playerIndex.
      const key3 = action.gameID;
      const index3 = action.playerIndex;
      const rcid = action.rcid;

      // Just create a new CID array (and copy if needed) to make sure it exists:
      let newCID3 = [];
      if(state[key3] && Array.isArray(state[key3].CID)) {
        newCID3 = [...state[key3].CID];
      }

      // Create a new RCID array (and copy if needed) and update our entry:
      let newRCID3 = [];
      if(state[key3] && Array.isArray(state[key3].RCID)) {
        newRCID3 = [...state[key3].RCID];
      }
      newRCID3[index3] = rcid;

      const newState3 = Object.assign({}, state);
      newState3[key3] = {CID: newCID3, RCID: newRCID3};
      return newState3

    default:
      return state;
  }
}
