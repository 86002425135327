/*
  GuiForceTypes.tsx
  (c) Human Cube Inc.
*/

import { useState } from 'react';
import { Segment, Table } from 'semantic-ui-react';

import GuiForceTypeRow from './GuiForceTypeRow';
import GuiForceTypeDetail from './GuiForceTypeDetail';


export default function GuiForceTypes ({ close, store }: { close: any, store: any }) {

  const [selectedForceTypeIndex, setSelectedForceTypeIndex] = useState(-1);

  const hideForceTypes = (e: any) => {
    e.stopPropagation();
    setSelectedForceTypeIndex(-1);
    if(close) {
      close();
    }
  };

  const selectForceType = (forceTypeIndex: number) => {
    setSelectedForceTypeIndex(forceTypeIndex);
  };

  const hideForceType = (e: any) => {
    e.stopPropagation();
    setSelectedForceTypeIndex(-1);
  };

  const { gameID, game } = store;
  if(game._id !== gameID) {
    return null;
  }

  if(selectedForceTypeIndex >= 0) {
    return (
      <Segment compact inverted onPointerDown={ hideForceType }
          style={{ position: 'absolute', top: '30px', left: '30px', opacity: '90%' }}>
        <GuiForceTypeDetail
          key={ 'ftd' + selectedForceTypeIndex }
          forceTypeIndex={ selectedForceTypeIndex }
          game={ game }
        />
      </Segment>
    );
  }

  const forceTypeRows = [];
  for(let f = 0; f < game.forceID.length; f++) {
    forceTypeRows.push(
      <GuiForceTypeRow
        key={ 'ft' + f }
        forceTypeIndex={ f }
        game={ game }
        select={ selectForceType }
      />
    );
  }

  return (
    <Segment compact inverted onPointerDown={ hideForceTypes }
        style={{ position: 'absolute', top: '30px', left: '30px', opacity: '90%' }}>
      <Table inverted attached='top' basic verticalAlign='top'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign='center'>Force Type</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'></Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Cost</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Range</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Attack</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Defend</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Cargo</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Lift</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Landing</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Blocks</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Blocked By</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { forceTypeRows }
        </Table.Body>
      </Table>
    </Segment>
  );
}
