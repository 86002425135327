/*
  network.js
*/

import io from '../lib/socket.io.client.dev.0.9.17';
import { hookPackets } from './packet';
import { log } from '../helper/log';

// var vars = getUrlVars();
var host = 'https://bf3.battleforces.com:443';
// if( vars.host /* == 'local' */ ) {
//     host = 'http://127.0.0.1:8088';
// }

var socket = null;

export function networkInit () {
  socket = io.connect(host, {'max reconnection attempts': 10000});
  hookPackets(socket);
}

export function sendPacket (packetString, payload) {
  if(socket) {
    log('sendPacket ' + packetString);
    log(payload);
    socket.emit(packetString, payload);
  }
}
