/*
  BaseMapTile.js
  (c) Human Cube Inc.
*/

import { Link } from "react-router-dom";
import { Button } from 'semantic-ui-react';


export default function BaseMapTile ({mapID}) {
  return (
    <Link to={'/map/' + mapID}>
      <Button color='olive'>
        <div>
          { 'Map #' + mapID }
        </div>
      </Button>
    </Link>
  );
}
