import cloneDeep from 'clone-deep';
import actions from './actions'
import { fillScenarioTotals } from '../helper/scenario';

// function cloneShallow (obj) {
//   return Object.assign({}, obj);
// }

export function scenarioEdit (state = {}, action) {
  // Current scenario data being edited.
  const { playerIndex, zoneID } = action;

  switch (action.type) {
    case actions.SCENARIO_EDIT_SET_SCENARIO:
      return action.scenario;

    case actions.SCENARIO_EDIT_SAVED:
      // Update the _id of the scenarioEdit object so future saves will not create new entries.
      if(action.scenarioID) {
        return Object.assign({}, state, { _id: action.scenarioID });
      }
      return state;

    case actions.SCENARIO_EDIT_CAPITAL:
      // Update starting capital for a single player.
      const { capitalID } = action;
      if(playerIndex > 0 && playerIndex <= state.players) {
        const scenario1 = cloneDeep(state);
        scenario1.capital[playerIndex] = capitalID;
        fillScenarioTotals(scenario1);
        return scenario1;
      }
      return state;

    case actions.SCENARIO_EDIT_CASH:
      // Update starting cash for a single player.
      const { cash } = action;
      if(playerIndex > 0 && playerIndex <= state.players) {
        const scenario1 = cloneDeep(state);
        scenario1.cash[playerIndex] = cash;
        fillScenarioTotals(scenario1);
        return scenario1;
      }
      return state;

    case actions.SCENARIO_EDIT_DESCRIPTION:
      const { description } = action;
      return Object.assign({}, state, { description });

    case actions.SCENARIO_EDIT_MAX_DURATION:
      const { maxDuration } = action;
      return Object.assign({}, state, { maxDuration });

    case actions.SCENARIO_EDIT_MAX_TURNS:
      const { maxTurns } = action;
      return Object.assign({}, state, { maxTurns });

    case actions.SCENARIO_EDIT_NAME:
      const { name } = action;
      return Object.assign({}, state, { name });

    case actions.SCENARIO_EDIT_NAVY_ZONE:
      // Update navy zone for a single zone.
      const { navyZone } = action;
      if(zoneID > 0 && zoneID <= state.zones) {
        const scenario1 = cloneDeep(state);
        scenario1.navyZone[zoneID] = navyZone;
        fillScenarioTotals(scenario1);
        return scenario1;
      }
      return state;

    case actions.SCENARIO_EDIT_PLAYER_FLAG:
      // Update player flag for a single player.
      const { playerFlag } = action;
      if(playerIndex > 0 && playerIndex <= state.players) {
        const scenario1 = cloneDeep(state);
        scenario1.playerFlag[playerIndex] = playerFlag;
        fillScenarioTotals(scenario1);
        return scenario1;
      }
      return state;

    case actions.SCENARIO_EDIT_PLAYER_FLAGS:
      // Update player type for a single player.
      const { playerFlags } = action;
      if(playerIndex > 0 && playerIndex <= state.players) {
        const scenario1 = cloneDeep(state);
        scenario1.playerFlags[playerIndex] = playerFlags;
        fillScenarioTotals(scenario1);
        return scenario1;
      }
      return state;

    case actions.SCENARIO_EDIT_PLAYER_NAME:
      // Update player slot name for a single player.
      const { playerName } = action;
      if(playerIndex > 0 && playerIndex <= state.players) {
        const scenario1 = cloneDeep(state);
        if(!Array.isArray(scenario1.playerName) || scenario1.playerName.length === 0) {
          scenario1.playerName = Array(scenario1.players + 1).fill('');
        }
        scenario1.playerName[playerIndex] = playerName;
        fillScenarioTotals(scenario1);
        return scenario1;
      }
      return state;

    case actions.SCENARIO_EDIT_STARTING_FORCES:
      // Update all starting forces for a single zone.
      if(Array.isArray(state.startingForces)) {
        const { startingForces } = action;
        if(zoneID > 0 && zoneID <= state.zones &&
            state.startingForces[zoneID].length === startingForces.length) {
          const scenario1 = cloneDeep(state);
          scenario1.startingForces[zoneID] = startingForces;
          fillScenarioTotals(scenario1);
          return scenario1;
        }
      }
      return state;

    case actions.SCENARIO_EDIT_TEAM:
      // Update the team membership for a single player.
      const { team } = action;
      if(playerIndex > 0 && playerIndex <= state.players) {
        const scenario1 = cloneDeep(state);
        scenario1.team[playerIndex] = team;
        fillScenarioTotals(scenario1);
        return scenario1;
      }
      return state;

    case actions.SCENARIO_EDIT_TEAM_NAME:
      // Update the teamName for a single team.
      const { teamIndex, teamName } = action;
      if(teamIndex > 0 && teamIndex <= state.teams) {
        const scenario1 = cloneDeep(state);
        if(!Array.isArray(scenario1.teamName) || scenario1.teamName.length === 0) {
          scenario1.teamName = Array(scenario1.teams + 1).fill('');
        }
        scenario1.teamName[teamIndex] = teamName;
        fillScenarioTotals(scenario1);
        return scenario1;
      }
      return state;

    case actions.SCENARIO_EDIT_TURN_TIME:
      const { turnTime } = action;
      return Object.assign({}, state, { turnTime });

    case actions.SCENARIO_EDIT_ZONE_INCOME:
      // Update zone income for a single zone.
      const { income } = action;
      if(zoneID > 0 && zoneID <= state.zones) {
        const scenario1 = cloneDeep(state);
        scenario1.income[zoneID] = income;
        fillScenarioTotals(scenario1);
        return scenario1;
      }
      return state;

    case actions.SCENARIO_EDIT_ZONE_OWNER:
      // Update navy zone for a single zone.
      const { owner } = action;
      if(zoneID > 0 && zoneID <= state.zones) {
        const scenario1 = cloneDeep(state);
        scenario1.owner[zoneID] = owner;
        fillScenarioTotals(scenario1);
        return scenario1;
      }
      return state;

    default:
      return state;
  }
}

export function scenarioEditSaved (state = true, action) {
  // Has the current scenario data been saved.
  switch (action.type) {
    case actions.SCENARIO_EDIT_SAVED:
      return true;
    case actions.SCENARIO_EDIT_SET_SCENARIO:
    case actions.SCENARIO_EDIT_CAPITAL:
    case actions.SCENARIO_EDIT_CASH:
    case actions.SCENARIO_EDIT_DESCRIPTION:
    case actions.SCENARIO_EDIT_MAX_DURATION:
    case actions.SCENARIO_EDIT_MAX_TURNS:
    case actions.SCENARIO_EDIT_NAME:
    case actions.SCENARIO_EDIT_NAVY_ZONE:
    case actions.SCENARIO_EDIT_PLAYER_FLAG:
    case actions.SCENARIO_EDIT_PLAYER_FLAGS:
    case actions.SCENARIO_EDIT_PLAYER_NAME:
    case actions.SCENARIO_EDIT_STARTING_FORCES:
    case actions.SCENARIO_EDIT_TEAM:
    case actions.SCENARIO_EDIT_TEAM_NAME:
    case actions.SCENARIO_EDIT_TURN_TIME:
    case actions.SCENARIO_EDIT_ZONE_INCOME:
    case actions.SCENARIO_EDIT_ZONE_OWNER:
      return false;
    default:
      return state;
  }
}
