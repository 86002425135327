/*
  GuiGame.tsx
  (c) Human Cube Inc.
*/

import { useState } from 'react';

import actions from '../../reducers/actions';
import mainModes from '../../reducers/mainModes';
import { Icon, Label, Message, Menu, Button } from 'semantic-ui-react';
import GuiCountdown from './GuiCountdown';
import GuiChat from './GuiChat';
import GuiForceTypes from './GuiForceTypes';
import GuiModal from './GuiModal';
import GuiPlayers from './GuiPlayers';
import GuiPurchasesSummary from './GuiPurchasesSummary';
import GuiScenarioEditPlayers from './GuiScenarioEditPlayers';
import GuiScenarioEditSave from './GuiScenarioEditSave';
import GuiScenarioEditStats from './GuiScenarioEditStats';
import GuiScenarioEditZone from './GuiScenarioEditZone';
import GuiVictory from './GuiVictory';
import GuiZone from './GuiZone';
import { sendPacket } from '../../network/network';
import { goBack } from '../../reducers/commands';
import BF from '../../bfcore/bfconst1';
import bfH from '../../bfcore/bf_helper';
import { generateTurnFromWorking, didMovement, didPurchase } from '../../helper/turn';
import { isBetaTester } from '../../helper/user';
import { aiMyTurn } from '../../ai';
import { localSoloGameCalculate } from '../../solo/localSoloGame';
import GuiMouseoverInfo from './GuiMouseoverInfo';
import { zoneIDFromXY } from '../../bfcore/bf_hitmap';
import { Game } from '../../bfcore/gameTypes';
import { WorkingData } from '../../bfworking/workingTypes';
import { storeDispatch } from '../../app/store';
import GuiTile from './GuiTile';
import GuiTurnsRequired from './GuiTurnsRequired';


const MAP_RENDER_WIDTH = 1024;
const MAP_RENDER_HEIGHT = 1024;

function screenToMapCoord (
  camera: any, screenX: number, screenY: number, mapXScale: number, mapYScale: number,
) {
   return {
     x: ((screenX - MAP_RENDER_WIDTH / 2) + camera.x) /
        (camera.zoom * mapXScale) + MAP_RENDER_WIDTH / 2,
     y: ((screenY - MAP_RENDER_HEIGHT / 2) + camera.y) /
        (camera.zoom * mapYScale) + MAP_RENDER_HEIGHT / 2,
  };
}


// Fast synced mouse state:
let isMouseDown = false;
let mouseDownX = 0;
let mouseDownY = 0;
let mouseLastX = 0;
let mouseLastY = 0;
let mouseMoved = false;

export default function GuiGame ({store}: {store: any}) {

  const [backModalMessage, setBackModalMessage] = useState('');
  const [submitModalMessage, setSubmitModalMessage] = useState('');
  const [showForceTypes, setShowForceTypes] = useState(false);
  const [showPlayers, setShowPlayers] = useState(false);
  const [showPurchases, setShowPurchases] = useState(false);
  const [showScenarioEditSave, setShowScenarioEditSave] = useState(false);
  const [showScenarioEditStats, setShowScenarioEditStats] = useState(false);

  const {
    camera, gameID, game, gameStatus, mainMode, musicOn, playFor, replayPlay,
    replayStage, scenarioEditSaved, selectedZoneIDs, server, soundOn, working,
  } = store;

  const handleZoneTap = (zoneID: number) => {
    // A zone, or no zone has been tapped.
    if(zoneID < 0) {
      zoneID = 0;
    }

    if(store.mainMode === mainModes.SCENARIO_PREVIEW ||
        store.mainMode === mainModes.SCENARIO_EDIT ) {
      if(zoneID &&
          (store.selectedZoneIDs.length !== 1 ||
          store.selectedZoneIDs[0] !== zoneID)) {
        storeDispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: [zoneID]});
      }
      else {
        storeDispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: []});
      }
      return;
    }

    if(store.showLandingSpecifyWindow.show) {
      // In aircraft landing mode, use the selection to choose a potential landing location.
      if(store.showLandingSpecifyWindow.destination !== zoneID) {
        storeDispatch({type: actions.SET_LANDING_SPECIFY_ZONE, zoneID});
      }
      return;
    }

    if(zoneID <= 0) {
      storeDispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: []});
      return;
    }

    const turnIndex = game.turn;
    if(store.mainMode === mainModes.GAME_REPLAY) {
      if(store.selectedZoneIDs.length === 1 &&
          store.selectedZoneIDs[0] === zoneID) {
        storeDispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: []});
      }
      else {
        storeDispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: [zoneID]});
      }
    }
    else if((turnIndex >= BF.TURN_PICK_ZONE && turnIndex <= BF.TURN_PICK_CAPITAL) ||
        store.selectedZoneIDs.length === 0 ||
        store.selectedZoneIDs.length === 2) {
      storeDispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: [zoneID]});
    }
    else if(store.selectedZoneIDs[0] === zoneID) {
      storeDispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: []});
    }
    else if(bfH.isNeighbour(store.game, store.selectedZoneIDs[0],
        zoneID)) {
      storeDispatch({
        type: actions.SET_SELECTED_ZONEIDS,
        selectedZoneIDs: [store.selectedZoneIDs[0], zoneID],
      });
    }
    else {
      storeDispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: [zoneID]});
    }
  }

  function doActualSubmitTurn (game: Game, working: WorkingData, localPlayerIndex: number) {
    storeDispatch({type: actions.SET_MAIN_MODE, mode: mainModes.GAME_SUBMITTING_TURN});

    const playersTurn =
      generateTurnFromWorking(game.zones, game.forceID.length, working, localPlayerIndex);
    if(game.flags & BF.GAME_FLAG_CLIENT_CALCULATED) {
      // Game advancement is calculated on the client.
      const { uid } = store.userSession;
      localSoloGameCalculate(game, uid, localPlayerIndex, playersTurn);
      return;
    }

    // else Game advancement is calculated on the server.
    const { playFor } = store;
    if(playFor.uid) {  // Playing for a teammate.
      sendPacket(
        'bf_submitTurn',
        {gameID: game._id, turn: game.turn, data: playersTurn, playForUID: playFor.uid}
      );
    }
    else {
      sendPacket(
        'bf_submitTurn',
        {gameID: game._id, turn: game.turn, data: playersTurn}
      );
    }
  }

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  }

  const clickBack = (e: any) => {
    e.stopPropagation();
    if(store.mainMode === mainModes.GAME_DOING_TURN) {
      setBackModalMessage(
        'You have not submitted your turn. Tap [SUBMIT TURN] before exiting to send your turn to the server.'
      );
      return;
    }

    if(store.mainMode === mainModes.SCENARIO_EDIT &&
        !store.scenarioEditSaved) {
      setBackModalMessage(
        'You have not saved your changes to the scenario. Tap [SAVE SCENARIO] before exiting to save your changes.'
      );
      return;
    }

    goBack();
  };

  const clickBackModalCancel = (e: any) => {
    setBackModalMessage('');
  }

  const clickBackModalOK = (e: any) => {
    goBack();
  }

  const clickStartTurn = (e: any) => {
    e.stopPropagation();
    storeDispatch({type: actions.SET_MAIN_MODE, mode: mainModes.GAME_DOING_TURN});
  };

  const clickSubmitTurnModalCancel = (e: any) => {
    setSubmitModalMessage('');
  }

  const clickSubmitTurnModalOK = (e: any) => {
    setSubmitModalMessage('');
    doActualSubmitTurn(game, working, localPlayerIndex);
  }

  const clickSubmitTurn = (e: any) => {
    e.stopPropagation();

    const working = store.working;
    const localPlayerIndex = working.localPlayerIndex;

    if(!didMovement(game.zones, game.forceID.length, working, localPlayerIndex)) {
      setSubmitModalMessage('You did not move any of your forces.');
      return;
    }
    if(!didPurchase(game.zones, game.forceID.length, working, localPlayerIndex)) {
      setSubmitModalMessage('You did not purchase any new forces.');
      return;
    }

    doActualSubmitTurn(game, working, localPlayerIndex);
  };

  const toggleMusic = (e: any) => {
    e.stopPropagation();
    let on = true;
    if(store.musicOn) {
      on = false;
    }
    if(on) {
      localStorage.setItem('musicOn', '1');
    }
    else {
      localStorage.setItem('musicOn', '0');
    }
    storeDispatch({type: actions.SET_MUSIC_ON_OFF, on});
  };

  const toggleSound = (e: any) => {
    e.stopPropagation();
    let on = true;
    if(store.soundOn) {
      on = false;
    }
    if(on) {
      localStorage.setItem('soundOn', '1');
    }
    else {
      localStorage.setItem('soundOn', '0');
    }
    storeDispatch({type: actions.SET_SOUND_ON_OFF, on});
  };

  const closeScenarioEditSave = () => {
    setShowScenarioEditSave(false);
  };

  const closeForceTypes = () => {
    setShowForceTypes(false);
  };

  const closePlayers = () => {
    setShowPlayers(false);
  };

  const closePurchases = () => {
    setShowPurchases(false);
  };

  const toggleForceTypes = (e: any) => {
    e.stopPropagation();
    if(showForceTypes) {
      setShowForceTypes(false);
      return;
    }
    setShowForceTypes(true);
    setShowPlayers(false);
    setShowPurchases(false);
    setShowScenarioEditStats(false);
  };

  const togglePlayers = (e: any) => {
    e.stopPropagation();
    if(showPlayers) {
      setShowPlayers(false);
      return;
    }
    setShowForceTypes(false);
    setShowPlayers(true);
    setShowPurchases(false);
    setShowScenarioEditStats(false);
  };

  const togglePurchases = (e: any) => {
    e.stopPropagation();
    if(showPurchases) {
      setShowPurchases(false);
      return;
    }
    setShowForceTypes(false);
    setShowPlayers(false);
    setShowPurchases(true);
    setShowScenarioEditStats(false);
  };

  const closeScenarioEditStats = (e: any) => {
    setShowScenarioEditStats(false);
  };

  const toggleScenarioEditStats = (e: any) => {
    e.stopPropagation();
    if(showScenarioEditStats) {
      setShowScenarioEditStats(false);
      return;
    }
    setShowForceTypes(false);
    setShowPlayers(false);
    setShowPurchases(false);
    setShowScenarioEditStats(true);
  };

  const clickReplayPlay = (e: any) => {
    e.stopPropagation();
    storeDispatch({type: actions.SET_REPLAY_PLAY, play: true});
  };

  const clickReplayPause = (e: any) => {
    e.stopPropagation();
    storeDispatch({type: actions.SET_REPLAY_PLAY, play: false});
  };

  const clickReplayStage = (stage: number) => (e: any) => {
    e.stopPropagation();
    storeDispatch({type: actions.SET_REPLAY_STAGE, stage: stage});
  };

  const scenarioSave = (e: any) => {
    e.stopPropagation();
    setShowScenarioEditSave(true);
  }

  const onMouseDown = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if(!submitModalMessage) {
      isMouseDown = true;
      mouseLastX = e.clientX;
      mouseLastY = e.clientY;
      mouseDownX = e.clientX;
      mouseDownY = e.clientY;
      mouseMoved = false;
    }
    else {
      isMouseDown = false;
    }
  };

  const onMouseMove = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const mouseX = e.clientX;
    const mouseY = e.clientY;

    let { mapFlags, mapXS, mapYS, mapXScale, mapYScale} = game;
    if(!mapXScale) {
      mapXScale = 1;
    }
    if(!mapYScale) {
      mapYScale = 1;
    }
    const {x, y} = screenToMapCoord(camera, mouseX, mouseY, mapXScale, mapYScale);
    let hitX = Math.floor(x / MAP_RENDER_WIDTH * BF.WORLD_WIDTH);
    let hitY = Math.floor(y / MAP_RENDER_HEIGHT * BF.WORLD_HEIGHT);
    if(mapFlags & BF.MAP_FLAG_HEXMAP) {
      hitX = Math.floor(x / 16 / mapXS * BF.WORLD_WIDTH);
      hitY = Math.floor(y / 16 / mapYS * BF.WORLD_HEIGHT);
    }
    const zoneID = zoneIDFromXY(game, hitX, hitY);
    if(store.mouseoverZoneID !== zoneID) {
      storeDispatch({type: actions.SET_MOUSEOVER_ZONEID, zoneID});
    }

    if(!submitModalMessage && isMouseDown) {
      const dx = mouseX - mouseLastX;
      const dy = mouseY - mouseLastY;
      if(mouseMoved || Math.abs(dx) >= 5 || Math.abs(dy) >= 5) {
        storeDispatch({
          type: actions.MAP_PAN,
          dx,
          dy,
        });
        mouseLastX = e.clientX;
        mouseLastY = e.clientY;
        mouseMoved = true;
      }
    }
    else {
      isMouseDown = false;
    }
  };

  const onMouseUp = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if(!submitModalMessage && isMouseDown && !mouseMoved) {
      if(store.mainMode === mainModes.GAME_REPLAY ||
          store.mainMode === mainModes.GAME_DOING_TURN ||
          store.mainMode === mainModes.SCENARIO_PREVIEW ||
          store.mainMode === mainModes.SCENARIO_EDIT ) {
        const mouseX = e.clientX;
        const mouseY = e.clientY;

        if(Math.abs(mouseDownX - mouseX) < 5 || Math.abs(mouseDownY - mouseY) < 5) {
          let {mapFlags, mapXS, mapYS, mapXScale, mapYScale} = game;
          if(!mapXScale) {
            mapXScale = 1;
          }
          if(!mapYScale) {
            mapYScale = 1;
          }
          const {x, y} = screenToMapCoord(camera, mouseX, mouseY, mapXScale, mapYScale);
          let hitX = Math.floor(x / MAP_RENDER_WIDTH * BF.WORLD_WIDTH);
          let hitY = Math.floor(y / MAP_RENDER_HEIGHT * BF.WORLD_HEIGHT);
          if(mapFlags & BF.MAP_FLAG_HEXMAP) {
            hitX = Math.floor(x / 16 / mapXS * BF.WORLD_WIDTH);
            hitY = Math.floor(y / 16 / mapYS * BF.WORLD_HEIGHT);
          }
          const zoneID = zoneIDFromXY(game, hitX, hitY);
          handleZoneTap(zoneID);
        }
      }
    }
    isMouseDown = false;
  };

  const zoomIn = (e: any) => {
    e.stopPropagation();
    storeDispatch({type: actions.MAP_ZOOM, delta: 5});
  }

  const zoomOut = (e: any) => {
    e.stopPropagation();
    storeDispatch({type: actions.MAP_ZOOM, delta: -5});
  }

  const serverTimeOffsetMS = server.serverSecondsOffset;

  const localPlayerIndex = working.localPlayerIndex;
  const inGame = localPlayerIndex ? true : false;

  let modeText = null;
  let infoWidget = null;
  let aiButton = null;
  let replayStuff = [];
  let soloGame = true;
  let showVictory = false;

  if(game._id === gameID && gameStatus[gameID]) {
    const { /* name, players, playerID, turn, */ state, timeLimit, required, submitted, year } =
        gameStatus[gameID];

    if(game.humanPlayers > 1) {
      soloGame = false;
    }

    if(mainMode === mainModes.GAME_REPLAY) {
      if(game.turn > BF.TURN_NORMAL) {
        replayStuff.push(
          <Button key={'butt-1'} icon labelPosition='right'
             onPointerUp={ replayPlay ? clickReplayPause : clickReplayPlay }>
            <Icon name={ replayPlay ? 'pause' : 'play' } />
            R e p l a y
          </Button>
        );

        for(let i = 0; i < working.replayStage.length; i++) {
          const r = working.replayStage[i];
          let stageName = BF.REPLAY_STAGE_STRING[r];
          if(r === 0) {
            stageName = 'Commence';
          }
          if(r === replayStage) {
            replayStuff.push(
              <Button key={ 'butt' + r } inverted onPointerUp={ clickReplayStage(r) } >
                { stageName.toUpperCase() }
              </Button>
            );
          }
          else {
            replayStuff.push(
              <Button key={ 'butt' + r } onPointerUp={ clickReplayStage(r) } >
                { stageName }
              </Button>
            );
          }
        }

        if(replayStage === BF.MAX_REPLAY_STAGES - 1 && state === BF.GAME_STATE_FINISHED) {
          showVictory = true;
        }
      }

      if(state === BF.GAME_STATE_STARTED) {
        if(inGame && (required & (1<<localPlayerIndex)) &&
            (submitted & (1<<localPlayerIndex)) === 0) {
          if(game.turn >= BF.TURN_PICK_ZONE && game.turn < BF.TURN_PICK_CAPITAL) {
            infoWidget =
              <GuiTile edge='right'>
                It is your turn to<br />
                select a zone to own.<br />
                <br />
                <Button onPointerUp={ clickStartTurn } color='green'>
                  Start Turn
                </Button>
              </GuiTile>;
          }
          else if(game.turn === BF.TURN_PICK_CAPITAL) {
            infoWidget =
              <GuiTile edge='right'>
                It is your turn<br />
                to select a zone<br />
                to be your Capital.<br />
                <br />
                <Button onPointerUp={ clickStartTurn } color='green'>
                  Start Turn
                </Button>
              </GuiTile>;
          }
          else if(game.turn >= BF.TURN_NORMAL) {
            infoWidget =
              <GuiTile edge='right'>
                <b>It is your turn.</b><br />
                <br />
                <Button onPointerUp={ clickStartTurn } color='green'>
                  Start Turn
                </Button><br />
                <br />
                After you have<br />
                completed your<br />
                turn, tap the<br />
                <b>SUBMIT TURN</b><br />
                command.
              </GuiTile>;
          }
        }
        else if(required && required !== submitted) {
          infoWidget = <GuiTurnsRequired game={game} required={required} submitted={submitted} />;
        }
        else {
          infoWidget =
            <GuiTile edge='right'>
              Server is calculating<br />
              turn. Please wait.<br />
              <br />
              Previous turn<br />
              replay shown.
            </GuiTile>;
        }
      }

    }
    else if(mainMode === mainModes.GAME_DOING_TURN) {
      if(game && game.turn === BF.TURN_PICK_CAPITAL) {
        infoWidget =
          <GuiTile edge='right'>
            Tap a zone you<br />
            own to select as<br />
            your capital.
          </GuiTile>;
      }
      else if(game && game.turn === BF.TURN_PICK_ZONE) {
        infoWidget =
          <GuiTile edge='right'>
            Tap an unowned<br />
            land zone you<br />
            wish to own.
          </GuiTile>;
      }
    }

    else if(mainMode === mainModes.GAME_SUBMITTING_TURN) {
      if(inGame && (required & (1<<localPlayerIndex)) &&
          (submitted & (1<<localPlayerIndex)) === 0) {
        infoWidget =
          <GuiTile edge='right'>
            Submitting Turn To Server...
          </GuiTile>;
      }
      else if(required && required !== submitted) {
        infoWidget = <GuiTurnsRequired game={game} required={required} submitted={submitted} />;
      }
      else {
        infoWidget =
          <GuiTile edge='right'>
            Server is calculating turn.
          </GuiTile>;
      }
    }

    if(mainMode === mainModes.SCENARIO_PREVIEW) {
      modeText = <Menu.Item>PREVIEW MODE</Menu.Item>;
    }
    else if(mainMode === mainModes.SCENARIO_EDIT) {
      modeText = <Menu.Item>EDIT MODE</Menu.Item>;
    }
    else {
      let countDown = null;
      if(state === BF.GAME_STATE_STARTED) {
        countDown = <GuiCountdown date={ timeLimit } offsetMS={ serverTimeOffsetMS } />;
      }
      modeText = <Menu.Item>
                    { bfH.turnIndexAsString(game.turn, year) }
                    { countDown }
                  </Menu.Item>;
    }

  }

  let modeCommand = <Menu.Item />;
  if(mainMode === mainModes.GAME_DOING_TURN) {

    if( game.turn >= BF.TURN_PICK_ZONE && game.turn < BF.TURN_PICK_CAPITAL) {
      if(isBetaTester()) {
        aiButton =
          <div style={{ position: 'absolute', bottom: 3, right: 0 }}>
            <Button
              onPointerDown={stopPropagation}
              onPointerUp={(e: any) => {
                e.stopPropagation();
                aiMyTurn(storeDispatch, working, game);
              }
              }>
              <small>
                Have AI Suggest<br />
                a Zone Pick
              </small>
            </Button>
          </div>;
      }
    }
    else if( game.turn >= BF.TURN_PICK_CAPITAL && game.turn < BF.TURN_NORMAL) {
      if(isBetaTester()) {
        aiButton =
          <div style={{ position: 'absolute', bottom: 3, right: 0 }}>
            <Button
              onPointerDown={stopPropagation}
              onPointerUp={(e: any) => {
                e.stopPropagation();
                aiMyTurn(storeDispatch, working, game);
              }
              }>
              <small>
                Have AI Suggest a<br />
                Capital Selection
              </small>
            </Button>
          </div>;
      }
    }
    else if( game.turn >= BF.TURN_NORMAL) {
      modeCommand = <Menu.Item onPointerUp={ clickSubmitTurn }>
                      <Label color ='green'>SUBMIT TURN</Label>
                    </Menu.Item>;
      if(isBetaTester()) {
        if (working.ai.moveStage >= 0 && working.ai.moveStage < BF.FORCE_MAX_MOVES) {
          aiButton =
            <div style={{ position: 'absolute', bottom: 3, right: 0 }}>
              <Button
                onPointerDown={stopPropagation}
                onPointerUp={(e: any) => {
                  e.stopPropagation();
                  aiMyTurn(storeDispatch, working, game);
                }
                }>
                <small>
                  Use AI for Your<br />
                  Move Phase {working.ai.moveStage + 1}
                </small>
              </Button>
            </div>;
        }
        else {
          aiButton =
            <div
              className='bg-slate-300 py-1 px-3'
              style={{ position: 'absolute', bottom: 3, right: 3 }}
            >
              <small>
                Tap SUBMIT TURN to End Your Turn
              </small>
            </div>;
        }
      }
    }
  }
  else if(mainMode === mainModes.SCENARIO_EDIT) {
    modeCommand = <Menu.Item onPointerUp={ scenarioSave }>
                    <Label color ='green'>SAVE SCENARIO{ scenarioEditSaved ? '' : '*' }</Label>
                  </Menu.Item>;
  }

  let productionCommand = <Menu.Item />;
  if(mainMode === mainModes.GAME_DOING_TURN) {
    productionCommand = <Menu.Item onPointerUp={ togglePurchases }>
                          <Icon.Group>
                            <Icon name='factory' />
                          </Icon.Group>
                        </Menu.Item>;
  } else if(mainMode === mainModes.SCENARIO_EDIT) {
    productionCommand = <Menu.Item onPointerUp={ toggleScenarioEditStats }>
                          STATS
                        </Menu.Item>;
  }

  let zoneComponent = null;
  if(mainMode !== mainModes.SCENARIO_EDIT) {
    zoneComponent = <GuiZone store={ store } />;
  }
  else if(selectedZoneIDs[0]) {
    zoneComponent = <GuiScenarioEditZone game={ store.game }
                                          scenarioEdit={ store.scenarioEdit }
                                          zoneID={ selectedZoneIDs[0] } />
  }

  let playersComponent = null;
  if(showPlayers) {
    if(mainMode !== mainModes.SCENARIO_EDIT) {
      playersComponent = <GuiPlayers store={ store }
                                      close={ closePlayers } />;
    }
    else {
      playersComponent = <GuiScenarioEditPlayers store={ store } />;
    }
  }

  let soundMenuItem = <Menu.Item name='sound'>&nbsp;</Menu.Item>;
  if(mainMode !== mainModes.SCENARIO_EDIT && mainMode !== mainModes.SCENARIO_PREVIEW) {
    soundMenuItem = <Menu.Item name='sound'>
                      { soundOn ?
                          <Icon.Group onPointerUp={ toggleSound } >
                            <Icon name='volume up' />
                          </Icon.Group>
                        :
                          <Icon.Group onPointerUp={ toggleSound } >
                            <Icon name='volume off' />
                          </Icon.Group>
                      }
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      { musicOn ?
                          <Icon.Group onPointerUp={ toggleMusic } >
                            <Icon name='music' />
                          </Icon.Group>
                        :
                          <Icon.Group onPointerUp={ toggleMusic } >
                            <Icon name='music' />
                            <Icon color='red' name='dont' />
                          </Icon.Group>
                      }
                    </Menu.Item>;
  }

  return (
    <div style={{height: '100%', width: '100%', border: 0, padding: 0}}
          onPointerDown={ onMouseDown }
          onPointerMove={ onMouseMove }
          onPointerUp={ onMouseUp }
      >
      {/* <GuiMusic on={ musicOn }
                mainMode={ mainMode }
                turnIndex={ game.turn }
                volume={ musicVolume }
      /> */}

      <Menu inverted fluid widths={ 8 }
            style={{opacity: 0.75}}
            onPointerDown={ stopPropagation } >
        <Menu.Item name='BACK' onPointerUp={ clickBack } />
        { soundMenuItem }

        <Menu.Item name='zoom'>
          <Icon.Group onPointerUp={ zoomOut }>
            <Icon name='zoom out' />
          </Icon.Group>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Icon.Group onPointerUp={ zoomIn }>
            <Icon name='zoom in' />
          </Icon.Group>
        </Menu.Item>;

        <Menu.Item onPointerUp={ toggleForceTypes }>
          <Icon.Group>
            <Icon name='list alternate' />
          </Icon.Group>
        </Menu.Item>

        <Menu.Item onPointerUp={ togglePlayers } >
          <Icon.Group>
            <Icon name='user' />
          </Icon.Group>
        </Menu.Item>

        { productionCommand }
        { modeCommand }
        { modeText }
      </Menu>

      <div
        className='flex flex-col flex-wrap items-end'
        style={{ position: 'absolute', top: 100, right: 0 }}
      >
        { showVictory && <GuiVictory game={ game } /> }
        { infoWidget }
        { replayStuff.length > 0 &&
          <Button.Group vertical compact color='black'
            style={{ opacity: 0.666, maxWidth: 160 }}
              onPointerDown={ stopPropagation }>
            { replayStuff }
          </Button.Group>
        }
      </div>

      <div style={{ position: 'absolute', top: 100, left: 0 }}>
        { zoneComponent }
      </div>

      { aiButton }

      { store.mouseoverZoneID ?
        <GuiMouseoverInfo
          game={game}
          working={working}
          zoneID={store.mouseoverZoneID}
          replayStage={mainMode === mainModes.GAME_REPLAY ? replayStage : -1}
        />
        : null
      }

      { playersComponent }

      { playFor.uid
          ? <Message style={{ position: 'absolute', bottom: 0, left: 0 }} color='red'>
              You are Playing<br />for { playFor.uName }
            </Message>
          : (!soloGame && <GuiChat store={ store } />)
      }

      { showPurchases &&
          <GuiPurchasesSummary store={ store } closeCallback={ closePurchases } />
      }

      { showForceTypes &&
          <GuiForceTypes store={ store } close={ closeForceTypes } />
      }

      { showScenarioEditStats &&
        <GuiScenarioEditStats store={ store }
                              closeHandle={ closeScenarioEditStats } />
      }

      { showScenarioEditSave &&
        <GuiScenarioEditSave store={ store }
                              closeHandle={ closeScenarioEditSave } />
      }

      { backModalMessage &&
        <GuiModal
          title='Exit Warning'
          body={ backModalMessage }
          cancelCallback={ clickBackModalCancel }
          okCallback={ clickBackModalOK }
          okText= {mainMode === mainModes.SCENARIO_EDIT ?
                  'Exit Without Saving' : 'Exit Without Submitting Turn' }
        />
      }
      { submitModalMessage &&
        <GuiModal
          title='Submit Turn Warning'
          body={ submitModalMessage }
          cancelCallback={ clickSubmitTurnModalCancel }
          okCallback={ clickSubmitTurnModalOK }
          okText='Submit Turn Anyways'
        />
      }
    </div>
  );
}
