/*
  BaseLogin.js
  (c) Human Cube Inc.
*/

import React from 'react'
import { Button, Form, Grid, Header, Icon, Image, Label, Message, Modal, Segment }
        from 'semantic-ui-react'

import { store } from '../../app/store';
import actions from '../../reducers/actions'
import BaseAdSense from './BaseAdSense';
import BaseFooter from './BaseFooter';
// import background from './img/background/raptor1920x1200.jpg';
import backgroundVideo from './vid/4f35flight.mp4';
import screenshot1 from './img/screenshots/indianoceanbattle.png';


export default class BaseLogin extends React.Component {

  state = {
    displayWhatIs: false,
  };

  constructor(props) {
    super(props);

    let userName = localStorage.getItem('loginUserName');
    if(!userName) {
      userName = '';
    }
    let password = localStorage.getItem('loginPassword');
    if(!password) {
      password = '';
    }
    let remember = true;
    if(localStorage.getItem('loginRemember') !== null &&
        localStorage.getItem('loginRemember') !== '1') {
      remember = false;
    }

    this.state = {
      userName,
      password,
      remember,
      loginErrorMessage: props.store.loginErrorMessage,
      version: props.store.version,
    };

    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleRememberChange = this.handleRememberChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store.loginErrorMessage !== this.state.loginErrorMessage) {
      this.setState({
        loginErrorMessage: nextProps.store.loginErrorMessage,
      });
    }
  }

  handleUserNameChange(event) {
    this.setState({userName: event.target.value});
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }

  handleRememberChange(event) {
    this.setState({remember: event.target.checked});
  }

  handleSubmit(event) {
    event.preventDefault();
    if(this.state.remember) {
      localStorage.setItem('loginRemember', '1');
    }
    else {
      localStorage.setItem('loginRemember', '0');
    }

    store.dispatch({
      type: actions.SUBMIT_LOGIN,
      userName: this.state.userName,
      password: this.state.password,
      remember: this.state.remember,
    });
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  eatHideWhatIs = (e) => {
    e.stopPropagation();
    this.setState({ displayWhatIs: false });
  };

  hideWhatIs = (e) => {
    this.setState({ displayWhatIs: false });
  };

  showWhatIs = (e) => {
    this.setState({ displayWhatIs: true });
  };

  render() {
    return (
      <div
        className='full-bg-size bg-slate-500'
        style={{
          // backgroundImage: `url(${background})`,
          height: '100vh',
        }}>

        <video autoPlay muted loop playsInline={true} disablePictureInPicture={true}
          style={{
            objectFit: 'cover',
            position: 'fixed',
            right: '0',
            bottom: '0',
            minWidth: '100%',
            minHeight: '100%',
        }}>
          <source src={ backgroundVideo } type="video/mp4" />
        </video>

        <center>
          <BaseAdSense />
        </center>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <center>
              <Grid textAlign='center' verticalAlign='middle'
                    style={{ height: '70vh', opacity:'0.85' }} >
                <Grid.Column style={{ maxWidth: 450 }}>
                  <Form size='large' onSubmit={ this.handleSubmit }>
                    <Segment stacked>
                      <Header as='h2' textAlign='center'>
                        Welcome to BATTLE FORCES!
                      </Header>
                      <Form.Input autoFocus={ this.state.userName ? false : true }
                        fluid icon='user' iconPosition='left' placeholder='Username'
                        value={this.state.userName}
                        onChange={this.handleUserNameChange}
                      />
                      <Form.Input
                        autoFocus={ (this.state.userName && !this.state.password) ? true : false }
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Password'
                        type='password'
                        value={ this.state.password }
                        onChange={ this.handlePasswordChange }
                      />
                      <div className="ui checked checkbox" style={{ marginBottom: '13px' }}>
                        <input type="checkbox"
                          checked={ this.state.remember }
                          onChange={ this.handleRememberChange }
                        />
                        <label>Remember Password</label>
                      </div>
                      <Button
                        autoFocus={ (this.state.userName && this.state.password) ? true : false }
                        color='green'
                        fluid
                        size='large'
                      >
                        Login
                      </Button>
                    </Segment>
                  </Form>
                  {this.state.loginErrorMessage &&
                    <Message color='red'>{ this.state.loginErrorMessage }</Message>
                  }
                  <Message>
                    Play in your browser, no install needed, not even Flash!<br />
                    <a href='https://forum.battleforces.com/register'>
                      <u>Sign Up and Play for Free</u>
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No credit card required.
                  </Message>
                  <Label pointing color='olive'>
                    <Button color='olive' fluid size='medium' onPointerUp={ this.showWhatIs }>
                      What is BATTLE FORCES?
                    </Button>
                  </Label>
                </Grid.Column>
              </Grid>
            </center>
          </div>
        </div>
        <center>
          <Message compact style={{ opacity:'0.80' }}>
            <a target='_blank' href='https://forum.battleforces.com' rel='noopener noreferrer'>
              <Icon name='share square'/> BATTLE FORCES Forum
            </a>
          </Message>
          <br />
          <Message compact style={{ opacity:'0.75' }}>
            <small>
              <a target='_blank' href='/eula.txt' rel='noopener noreferrer'
                 style={{ color:'black' }}>
               <u>End User License Agreement</u>
              </a>
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              <a target='_blank' href='/privacypolicy.txt' rel='noopener noreferrer'
                 style={{ color:'black' }}>
               <u>Privacy Policy and Cookies</u>
              </a>
              <br />
              Version { this.state.version }
              &nbsp;&nbsp;&nbsp;
              BATTLE FORCES &copy; 2024 Human Cube Inc.
            </small>
          </Message>
        </center>
        <BaseFooter />
        <Modal open={ this.state.displayWhatIs }
               onClose={ this.hideWhatIs }
               onPointerDown={ this.eatHideWhatIs } >
          <Modal.Header>BATTLE FORCES is the Ultimate in Turn-Based Strategy</Modal.Header>
          <Modal.Content image>
            <Image wrapped size='medium' src={ screenshot1 } />
            <Modal.Description>
              <p>
              </p>
              <p>
                Appealing to fans of Axis and Allies*, Diplomacy* and RISK*,<br />
                BATTLE FORCES takes wargames to the next level!
              </p>
              <p>
                Moves are submitted any time of day. Once all players for a game<br />
                have submitted their turns, the server calculates all actions<br />
                simultaneously. Easily play with players all over the world.
              </p>
              <p>
                While keeping the rules simple, the simultaneous turns open up<br />
                a vast array of strategic and diplomatic decisions. After BATTLE<br />
                FORCES, other games will feel like checkers (draughts).
              </p>
              <p>
                *BATTLE FORCES is not associated with Avalon Hill or Hasbro.
              </p>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

/*
// Tried to use the semantic react control, but could never get it to respect the toggle state.
// This does not work:
                <Checkbox style={{marginBottom: '13px'}}
                  defaultChecked={this.state.remember ? '1' : ''}
                  checked={this.state.remember}
                  onChange={this.handleRememberChange}
                  label='Remember Password'
                />
// This works:
                <div className="ui checked checkbox" style={{marginBottom: '13px'}}>
                  <input type="checkbox"
                    checked={this.state.remember}
                    onChange={this.handleRememberChange}
                  />
                  <label>Remember Password</label>
                </div>
*/
