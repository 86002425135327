/*
  GuiChatInputWidget.js
  (c) Human Cube Inc.
*/

import { useRef } from 'react';
import { Button } from 'semantic-ui-react';


const GuiChatInputWidget = ({ chatSendHandler }: { chatSendHandler: (text: string) => void }) => {

  const inputRef = useRef<HTMLInputElement>(null);

  const sendChat = (e: any) => {
    e.stopPropagation();
    if(inputRef && inputRef.current && inputRef.current.value) {
      const chat = inputRef.current.value.trim();
      if (chat) {
        chatSendHandler(chat);
      }
      inputRef.current.value = '';
    }
  };

  return (
    <div>
      <input ref={inputRef} style={{ width: 320, height: 32 }} />
      <Button basic inverted onClick={ sendChat }>
        Send
      </Button>
    </div>
  );
}

export default GuiChatInputWidget;
