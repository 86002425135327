/*
  BaseLoading.js
  (c) Human Cube Inc.
*/

import { Icon, Message } from 'semantic-ui-react'

import BaseAdSense from './BaseAdSense';
import BaseFooter from './BaseFooter';
import background from './img/background/raptor1920x1200.jpg';


export default function BaseLoading ({ store }) {
  return (
    <div
        className="full-bg-size"
        style={{
          backgroundImage: `url(${ background })`,
          height: '100vh',
        }}>
      <center>
        <BaseAdSense />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Message compact style={{ opacity:'0.85' }}>
          <h3>Connecting to Server...</h3>
          <p>
            If a connection is not established within 3.14159<br />
            seconds, please refresh your browser.<br />
          </p>
        </Message>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Message compact style={{ opacity:'0.85' }}>
          <a target='_blank' href='https://forum.battleforces.com' rel='noopener noreferrer'>
            <Icon name='share square'/> BATTLE FORCES Forum
          </a>
        </Message>
        <br />
        <small>Version { store.version }</small>
      </center>
      <BaseFooter />
    </div>
  );
}
