/*
  BaseAdSense.js
  (c) Human Cube Inc.
*/

import React, { Component } from 'react';

import { store } from '../../app/store';


class BaseAdSense extends Component {

	componentDidMount() {
    const { soloNoAuthMode } = store.getState();
    if(!soloNoAuthMode) {
  		(window.adsbygoogle = window.adsbygoogle || []).push({})
    }
	}

	render() {
    const { soloNoAuthMode } = store.getState();
    if(soloNoAuthMode) {
      return(
        <div
          style={{
            display: 'inline-block',
            width: '728px',
            height: '90px',
          }}>
          <div className='mb-2 font-black text-5xl'>
            BATTLE FORCES Solitaire
          </div>
        </div>
      );
    }

    // This is for the BFHorizontal728x90 ad unit:
    return(
      <ins className='adsbygoogle'
        style={{
            display: 'inline-block',
            width: '728px',
            height: '90px',
          }}
          data-ad-client='ca-pub-0451671765608072'
          data-ad-slot='4471784202'></ins>
    );

/*
    // This is for the BattleForcesLowerBanner ad unit:
    return(
      <ins className='adsbygoogle'
        style={{
          display: 'inline-block',
          width: '468px',
          height: '60px',
        }}
        data-ad-client='ca-pub-0451671765608072'
        data-ad-slot='6477705320'></ins>
    );
*/

/*
    // This is for the BFHorizontalResponsive ad unit:
		return(
      <ins className='adsbygoogle'
        style={{
          display: 'block',
        }}
        data-ad-client='ca-pub-0451671765608072'
        data-ad-slot='2691798673'
        data-ad-format='auto'
        data-full-width-responsive='true'>
      </ins>
		);
*/
	}
}

export default BaseAdSense;
