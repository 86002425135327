import { store } from '../app/store';
import BF from '../bfcore/bfconst1';

export function localUID () {
  const session = store.getState().userSession;
  if( session && session.uid )
    return session.uid;
  return 0;
}

export function userMembershipLevel () {
  const session = store.getState().userSession;
  if( session && session.bf && session.bf.membershipLevel )
    return session.bf.membershipLevel;
  return 1;
}

export function userBeforeFirstGame () {
  if(isGameAdmin())
    return false;
  const session = store.getState().userSession;
  if(session && session.bf && Array.isArray(session.bf.game) &&
      session.bf.game.length === 0 && session.bf.finishedCount === 0)
    return true;
  return false;
}

export function userOnFirstGame () {
  if(isGameAdmin())
    return false;
  const session = store.getState().userSession;
  if(session && session.bf && Array.isArray(session.bf.game) &&
      session.bf.game.length === 1 && session.bf.finishedCount === 0)
    return true;
  return false;
}

export function userJustFinishedFirstGame () {
  if(isGameAdmin())
    return false;
  const session = store.getState().userSession;
  if(session && session.bf && Array.isArray(session.bf.game) &&
      session.bf.game.length === 0 && session.bf.finishedCount === 1)
    return true;
  return false;
}

export function userCanCreateGame () {
  if(isGameAdmin() || userMembershipLevel() >= 2 )
    return true;
  return false;
}

export function userCanCreateSpecificGame (scenario) {
  if(isGameAdmin())
    return true;
  if(scenario.gameFlags & BF.GAME_FLAG_PREMIUM_MEMBERS_ONLY)
    if( userMembershipLevel() < 2 )
      return false;
  if(scenario.gameFlags & BF.GAME_FLAG_EXTREME_MEMBERS_ONLY)
    if( userMembershipLevel() < 3 )
      return false;
  if( userMembershipLevel() >= 2 )  // Must be at least premimum to create multi-player game.
    return true;
  if(scenario.humanPlayers === 1)
    return true;
  return false;
}

export function userCanCreateMap () {
  if(isGameAdmin() || userMembershipLevel() >= 2)
    return true;
  return false;
}

export function userCanCreateScenario () {
  if(isGameAdmin() || userMembershipLevel() >= 2)
    return true;
  return false;
}

export function isGameAdmin () {
  const session = store.getState().userSession;
  if(session && session.uName === 'GameAdmin')
    return true;
  return false;
}

export function isBetaTester () {
  const session = store.getState().userSession;
  if(session && (isGameAdmin() || session.uName === 'SPHINX' || session.uName === 'Orbots'))
    return true;
  return false;
}
