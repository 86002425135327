/*
  BaseForceTypes.js
  (c) Human Cube Inc.
*/

import BaseCamoBackScroll from './BaseCamoBackScroll';
import BaseFooter from './BaseFooter';
import BaseMenuBasic from './BaseMenuBasic';
import GuiForce from '../GameGui/GuiForce';


export default function BaseForceTypes () {
  return (
    <BaseCamoBackScroll>
      <BaseMenuBasic pageName='Force Types' />
      <center>
        <br />
        <br />
        <br />
        <div>
            <h1>Force Types</h1>
        </div>
        <div style={{margin: '12px', maxWidth: 600 }} >
          <div className='text-xl'>
            There are ten standard Force Types in BATTLE FORCES.<br />
            Some games may only use a subset of these.<br />
            <i>Land War Africa</i>, for example, only has three different force types.<br /><br />
          </div>

          <div className='bg-slate-300/[.6]' >
            <table style={{ border: '1px solid' }}>
              <tbody>
                <tr>
                  <th width="20%">Force Type</th>
                  <th>Details</th>
                </tr>

                <tr style={{ border: '1px solid' }}>
                  <td>
                    <center>
                      <br />
                      <GuiForce forceType={ 1 } /><br />
                      <b>Troop</b><br /><br />
                      Cost: 10<br />
                      Range: 1<br />
                      Attack: 15%<br />
                      Defend: 30%<br /><br />
                    </center>
                  </td>
                  <td>
                    The basic millitary unit.  Cheap, but like a marine, they get the job done.
                    Troops perform better in defensive rather than offensive roles.<br />
                    <br />
                    Troops can only move on land.  To move across water, they must be loaded onto a Transport Ship.
                    Up to two Troops can be loaded onto a Transport Ship at one time.  Troops on a Transport Ship
                    do not contribute to combat.  If a Transport Ship is destroyed, any Troops it is carrying are also destroyed.<br />
                  </td>
                </tr>

                <tr style={{ border: '1px solid' }}>
                  <td>
                    <center>
                      <br />
                      <GuiForce forceType={ 2 } /><br />
                      <b>Tank</b><br /><br />
                      Cost: 15<br />
                      Range: 2<br />
                      Attack: 50%<br />
                      Defend: 30%<br /><br />
                    </center>
                  </td>
                  <td>
                    The dominant land assault force, Tanks bring range and fire power to the battlefield.<br />
                    <br />
                    Tanks can only move on land.  Transport Ships are required to move a Tank across water.
                    Transport Ships can carry up to two Tanks at a time.  While loaded on a Transport Ship,
                    a Tank will be unable to contribute to combat.  If a Transport Ship is destroyed while
                    carrying a Tank, the Tank will also be destroyed.<br />
                  </td>
                </tr>

                <tr style={{ border: '1px solid' }}>
                <td>
                  <center>
                    <br />
                    <GuiForce forceType={ 3 } /><br />
                    <b>SAM Launcher</b><br /><br />
                    Cost: 15<br />
                    Range: 1<br />
                    Attack: 0%<br />
                    Defend: 20%*<br /><br />
                  </center>
                </td>
                <td>
                  <br />
                  Feared by all pilots, Surface To Air Missile (SAM) Launchers defend home airspace from invading aircraft.<br /><br />
                  SAM Launchers are a unique air defence only force.  SAM Launchers can only fire when they are in their own land zones.<br /><br />
                  SAM Launchers cannot fire on enemy soil, nor can they defend against land forces such as Troops or Tanks.<br /><br />
                  * SAM Launchers get a shot at each attacking Fighter or Bomber.  The more enemy forces, the more they are able to fire.  This makes them particularly useful for defending valuable locations such as capitals and production zones.<br /><br />
                  SAM Launchers can only move over land with a range of 1.  To move across water, they must be loaded on to a Transport Ship.  A Transport Ship may carry up to two SAM Launchers at a time.  SAM Launchers on Transport Ships cannot participate in combat.  If a Transport Ship is destroyed while transporting a SAM Launcher, the SAM Launcher will also be destoryed.<br /><br />
                  SAM Launchers block both land and air forces movement and are blocked by both land and air forces.<br />
                  <br />
                </td>
                </tr>

                <tr style={{ border: '1px solid' }}>
                <td>
                  <center>
                    <br />
                    <GuiForce forceType={ 4 } /><br />
                    <b>Fighter</b><br /><br />
                    Cost: 30<br />
                    Range: 4<br />
                    Attack: 50%<br />
                    Defend: 65%<br /><br />
                  </center>
                </td>
                <td>
                <br />
                Excellent multi-purpose force.<br />
                <br />
                Fighters block land and surface water forces but are not by blocked them, with the exception of the SAM Launcher which blocks all aircraft.  Enemy aircraft will also block Fighters.<br />
                <br />
                Fighters must land at the end of every turn.  They must land on an Aircraft Carrier or on a land territory that was owned since the beginning of the turn.<br />
                <br />
                Up to four Fighters may land on a single Aircraft Carrier.<br />
                <br />
                Fighters that do not land at a safe location at the end of a turn are destroyed.<br />
                <br />
                Fighters have a maximum range of 4.  Care must be taken to ensure they have enough range to reach a safe landing spot.<br />
                <br />
                </td>
                </tr>

                <tr style={{ border: '1px solid' }}>
                <td>
                  <center>
                    <br />
                    <GuiForce forceType={ 5 } /><br />
                    <b>Bomber</b><br /><br />
                    Cost: 35<br />
                    Range: 6<br />
                    Attack: 65%<br />
                    Defend: 15%<br />
                  </center>
                </td>
                <td>
                <br />
                Bombers are much stronger at attacking than defending.<br /><br />
                Bombers block land and surface water forces but are not by blocked them, with the exception of the SAM Launcher which blocks all aircraft.  Enemy aircraft will also block Bombers.<br /><br />
                Bombers must land at the end of every turn. They must land on a land territory that was owned since the beginning of the turn. Bombers cannot land on Aircraft Carriers.<br />
                <br />
                Bombers that do not land at a safe location at the end of a turn are destroyed.<br />
                <br />
                Bombers have a maximum range of 6. Care must be taken to ensure they have enough range to reach a safe landing spot.<br />
                <br />
                </td>
                </tr>

                <tr style={{ border: '1px solid' }}>
                <td>
                  <center>
                    <br />
                    <GuiForce forceType={ 6 } /><br />
                    <b>Submarine</b><br /><br />
                    Cost: 20<br />
                    Range: 2<br />
                    Attack: 30%<br /><br />
                  </center>
                </td>
                <td>
                Useful for disrupting shipping supply lines, Submarines are the silent hunters of the deep.<br /><br />
                Submarines are never blocked, but they block other ships, and can disrupt shipping movement by blocking them.<br /><br />
                Submarines can only attack forces that are in or on the water.  This makes them vulnerable and unable to retaliate against aircraft.<br />
                </td>
                </tr>

                <tr style={{ border: '1px solid' }}>
                  <td>
                    <center>
                      <br />
                      <GuiForce forceType={ 7 } /><br />
                      <b>Transport</b><br /><br />
                      Cost: 20<br />
                      Range: 2<br />
                      Attack: 0%<br /><br />
                    </center>
                  </td>
                  <td>
                    <br />
                    Transport Ships cannot attack or defend. They also do not block other ships. They do count as casualties and can help in a fleet.
                    Hard working and under respected, their main usefulness is in transporting land forces over water.<br />
                    <br />
                    Transport Ships can carry up to two units of cargo.
                    Troops, Tanks and SAM Launchers each take up one unit of cargo space.
                    In other words, a Transport Ship may, at most, carry any two land forces at a time.
                    These do not have to be of the same type.<br />
                    <br />
                    Transport Ships can load so long as they have enough Load Points and the force that is being loaded onto the transport
                    is not blocked and has not yet moved.<br />
                    <br />
                    Transport ships cannot move or load after unloading.
                    If a Transport Ship has been blocked, it will be prevented from unloading.
                    Unloading a Transport Ship does not require Load Points.<br />
                    <br />
                    Cargo that is unloaded may not move any further that turn.
                    Cargo cannot be directly transfered between Transport Ships.<br />
                    <br />
                    Any Transport Ships destroyed while carrying cargo, will result in the cargo also being destroyed.
                    Any forces that are carried as cargo cannot assist in combat until they are unloaded onto land.<br />
                    <br />
                  </td>
                </tr>

                <tr style={{ border: '1px solid' }}>
                  <td>
                    <center>
                      <br />
                      <GuiForce forceType={ 8 } /><br />
                      <b>Aircraft Carrier</b><br /><br />
                      Cost: 35<br />
                      Range: 2<br />
                      Attack: 35%<br /><br />
                    </center>
                  </td>
                  <td>
                    A lifeline for Fighter aircraft, Aircraft Carriers can transport deadly force into enemy waters.<br />
                    <br />
                    Aircraft Carriers can carry up to four Fighters each.<br />
                    <br />
                    Bombers cannot land on Aircraft Carriers.<br />
                  </td>
                </tr>

                <tr style={{ border: '1px solid' }}>
                  <td>
                    <center>
                      <br />
                      <GuiForce forceType={ 9 } /><br />
                      <b>Destroyer</b><br /><br />
                      Cost: 25<br />
                      Range: 2<br />
                      Attack: 50%<br /><br />
                    </center>
                  </td>
                  <td>
                    King of the seas, destroyers are the dominant force on the water.<br />
                    <br />
                    Primarily used to escort aircraft carriers and transport ships, destroyers can attack anything in the air or water,
                    but cannot attack land.<br />
                  </td>
                </tr>

                <tr style={{ border: '1px solid' }}>
                  <td>
                    <center>
                      <GuiForce forceType={ 10 } /><br />
                      <b>Factory</b><br /><br />
                      Cost: 45<br /><br />
                    </center>
                  </td>
                  <td>
                    New forces are created in zones with a factory. Factories can also produce new naval forces in
                    directly adjacent water zones, however the water zone must be empty or contain at least one
                    friendly force. Water zones containing only enemy forces will block production.<br />
                    <br />
                    Factories cannot move or be destroyed. If a zone is captured with a factory in it, ownership of
                    the factory is automatically transfered to the new owner of the zone.<br />
                    <br />
                    Factories located at a player's capital has unrestricted production. All other factories are
                    restricted to producing a maximum number of forces equal to the income count for the zone. For
                    example, a factory on a zone with 3 income can only produce at most three forces per turn.<br />
                    <br />
                    New factories that are purchased, may only be placed on land zones that a player had already
                    owned at the beginning of the turn. A maximum of one factory is permitted per zone.<br />
                    <br />
                    New forces cannot be placed at a new factory on the same turn that the factory is built or
                    captured.<br />
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <br />
        <br />
        <br />
        <BaseFooter />
        <br /><br /><br />
        <br /><br /><br />
      </center>
    </BaseCamoBackScroll>
  );
}
