/*
  turn-generate.js
  Convert working data to turn format for turn submit.
*/

import BF from '../bfcore/bfconst1';

/*
Normal Turn:
{
arrange: {forceID1: [x, y],
          forceID2: [x, y],
            ...
         },

move: {
       forceID1: [
            zoneID to move to 1, x to move to 1, y to move to 1, extra 1,
            zoneID to move to 2, x to move to 2, y to move to 2, extra 2,
            zoneID to move to 3, x to move to 3, y to move to 3, extra 3],
       forceID2: [
            zoneID to move to 1, x to move to 1, y to move to 1, extra 1],
       forceID3: [
            zoneID to move to 1, x to move to 1, y to move to 1, extra 1,
            zoneID to move to 2, x to move to 2, y to move to 2, extra 2],
        ...
      },
  // WHERE:  extra is 0 for normal movement
  //         extra is -1 for load cargo (zoneID is the force ID of cargo to load)
  //         extra is forceID of cargo to unload (zoneID, x, y is for destination of cargo)

land: {forceID1: [zoneID, x, y],
       forceID2: [zoneID, x, y],
         ...
      },

purchase: [[force type index, zone id to place at, x to place at, y to place at],
           [force type index, zone id to place at, x to place at, y to place at],
           [force type index, zone id to place at, x to place at, y to place at],
            ...
          ],
}
*/

export function didMovement (zones, forceTypes, working, playerIndex) {
  // Returns true if user specified at least one movement or if they do not have anything to move.
  // else returns false.
  const force = working.force;
  const forces = working.force.length;
  let hasOneThatCouldMove = false;
  for(let i = 0; i < forces; i++) {
    if(force[i].owner === playerIndex) {
      if(force[i].move.length > 0) {
        return true;
      }
      if(force[i].r > 0) {
        hasOneThatCouldMove = true;
      }
    }
  }
  if(!hasOneThatCouldMove) {
    // Unable to move.
    return true;
  }
  return false;
}

export function didPurchase (zones, forceTypes, working, playerIndex) {
  // Returns true if user specified at least one purchase or if they are unable to purschase.
  // else returns false.
  const buy = working.buy;
  let hasProduction = false;
  for(let z = 1; z <= zones; z++) {
    if(buy[z]) {
      for(let f = 0; f < forceTypes; f++) {
        if(buy[z][f]) {
          return true;
        }
      }
    }
    if(working.production[z] > 0) {
      hasProduction = true;
    }
  }

  if(!hasProduction || working.cash < 10) {

    // TODO improve: find cheapest force cost and use that value instead of 10 above.

    // Unable to purchase.
    return true;
  }
  return false;
}

export function generateTurnFromWorking (zones, forceTypes, working, playerIndex) {
  // Returns a generated turn object for the given playerIndex.
  var turn = {
    source: BF.TURN_SOURCE_MANUAL,
    arrange: {},
    move: {},
    land: {},
    purchase: []
  };

  const force = working.force;
  const forces = working.force.length;
  const buy = working.buy;

  for(let i = 0; i < forces; i++) {
    if(force[i].owner !== playerIndex) {
      continue;
    }
    if(force[i].move.length > 0) {
      // if(force[i].move[0].z === force[i].sz && !force[i].move[0].e) {
      //   // Same zone and not a force load or unload, so it is just a location adjust.
      //   turn.arrange[force[i].id] = [
      //     Math.floor(force[i].move[0].x * BF.WORLD_WIDTH),
      //     Math.floor(force[i].move[0].y * BF.WORLD_HEIGHT)
      //   ];
      // }
      // else {
        turn.move[force[i].id] = [];
        for(let m = 0; m < force[i].move.length; m++) {
          turn.move[force[i].id].push(force[i].move[m].z);
          turn.move[force[i].id].push(Math.floor(force[i].move[m].x * BF.WORLD_WIDTH));
          turn.move[force[i].id].push(Math.floor(force[i].move[m].y * BF.WORLD_HEIGHT));
          turn.move[force[i].id].push(force[i].move[m].e ? force[i].move[m].e : 0);
        }
      // }
    }
  }

  // Aircraft Landing:
  for(const forceIndex in working.landing) {
    const zoneID = working.landing[forceIndex];
    const forceID = force[forceIndex].id;
    turn.land[forceID] = [zoneID, 0, 0];
  }

  // Purchases:
  for(let z = 1; z <= zones; z++) {
    if(buy[z]) {
      for(let f = 0; f < forceTypes; f++) {
        if(buy[z][f]) {
          for(let c = 0; c < buy[z][f]; c++) {
            turn.purchase.push([f, z, 0, 0]);
          }
        }
      }
    }
  }

  return turn;
}
