/* (c) 2013-present Human Cube Inc. */

import { store } from '../app/store';
import actions from '../reducers/actions';
import { log } from '../helper/log';


export function bfInResPacket (data) {
  log('bf_inRes: ' + data.e);
  log(data);

  if(data.e === 'allowTeammatesSubmit') {
    if(data.result === 'success') {
      store.dispatch({type: actions.GAME_UPDATE_TEAMMATES_SUBMIT,
                      gameID: data.gameID,
                      teammatesSubmit: data.teammatesSubmit,
                    });
    }
  }
  else if(data.e === 'setAutoPlay') {
    if(data.result === 'success') {
      store.dispatch({type: actions.GAME_UPDATE_AUTOPLAY,
                      // uid: data.uid,
                      gameID: data.gameID,
                      autoPlay: data.autoPlay,
                      autoSpend: data.autoSpend,
                      autoMove: data.autoMove,
                    });
    }
  }
  else if(data.e === 'setEmailNotify') {
    if(data.result === 'success') {
      store.dispatch({type: actions.USER_SESSION_UPDATE_SOME_BF, flags: data.userFlags});
    }
  }
  else if(data.e === 'soloScenarios') {
    store.dispatch({type: actions.SET_SOLO_SCENARIOS, data: data.d});
  }
  else if(data.e === 'registeringGames') {
    store.dispatch({type: actions.SET_REGISTERING_GAMES, data: data.d});
  }

  /*
      else if(data.e == 'userGamesTuneUp') {
          if(data.result == 'success' && session.uid == data.uid) {
              session.bf.game = data.game;
              session.bf.finishedCount = data.finishedCount;
          }
      }
  */

}
