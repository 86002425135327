import React, { Component } from 'react';
import BufferSprite from './BufferSprite';
import * as PIXI from 'pixi.js';
import BF from '../../bfcore/bfconst1';

function rgb(r,g,b) {
  return [r, g, b];
}

const distinctColor = [
  rgb(0,0,0),
  rgb(251,191,255), rgb(255,167,153), rgb(217,100,22), rgb(255,201,140), rgb(229,209,126),
  rgb(144,153,107), rgb(0,140,0), rgb(115,191,166), rgb(42,121,140), rgb(143,169,191),
  rgb(4,4,89), rgb(133,23,230), rgb(163,124,166), rgb(255,77,172), rgb(115,11,25),
  rgb(242,182,198), rgb(204,142,133), rgb(127,59,13), rgb(77,62,46), rgb(89,81,49),
  rgb(60,64,45), rgb(26,102,26), rgb(10,191,155), rgb(86,170,191), rgb(0,102,255),
  rgb(77,76,255), rgb(103,46,153), rgb(89,18,84), rgb(140,49,98), rgb(191,38,59),
  rgb(140,14,14), rgb(102,80,77), rgb(166,81,25), rgb(51,41,31), rgb(128,119,83),
  rgb(98,128,38), rgb(70,140,70), rgb(24,242,199), rgb(45,80,89), rgb(10,82,191),
  rgb(49,49,140), rgb(184,102,255), rgb(166,41,157), rgb(89,36,64), rgb(140,35,49),
  rgb(217,33,33), rgb(153,41,0), rgb(64,35,16), rgb(191,160,124), rgb(230,216,161),
  rgb(200,255,89), rgb(89,255,111), rgb(36,102,89), rgb(140,232,255), rgb(8,35,77),
  rgb(77,77,153), rgb(142,86,191), rgb(204,102,197), rgb(255,128,196), rgb(204,82,98),
  rgb(76,19,19), rgb(115,31,0), rgb(255,148,77), rgb(128,107,83), rgb(179,170,134),
  rgb(163,191,105), rgb(77,191,92), rgb(191,255,242), rgb(143,182,191), rgb(48,105,191),
  rgb(43,12,242), rgb(166,122,204), rgb(153,77,148), rgb(191,143,169), rgb(102,46,53),
  rgb(229,80,80), rgb(255,77,13), rgb(217,139,87), rgb(255,225,191), rgb(166,155,8),
  rgb(187,204,153), rgb(153,204,160), rgb(0,242,226), rgb(48,61,64), rgb(29,63,115),
  rgb(11,3,64), rgb(73,54,89), rgb(255,153,248), rgb(166,0,66), rgb(229,126,140),
  rgb(255,128,128), rgb(204,69,20), rgb(153,98,61), rgb(153,102,0), rgb(242,228,24),
  rgb(137,204,61), rgb(11,77,29), rgb(10,191,179), rgb(0,94,140), rgb(61,98,153),
  rgb(28,13,128), rgb(110,89,128), rgb(140,14,115), rgb(204,51,112), rgb(179,116,124),
  rgb(166,91,91), rgb(89,43,27), rgb(115,80,57), rgb(51,34,0), rgb(115,110,46),
  rgb(225,255,191), rgb(105,191,128), rgb(7,140,131), rgb(6,45,64), rgb(115,145,191),
  rgb(55,36,179), rgb(191,115,230), rgb(51,5,42), rgb(51,23,34), rgb(153,115,120),
  rgb(127,77,77), rgb(51,25,15), rgb(255,186,140), rgb(89,61,4), rgb(242,234,121),
  rgb(104,242,12), rgb(38,64,48), rgb(10,51,48), rgb(36,174,242), rgb(77,97,128),
  rgb(84,67,191), rgb(133,92,153), rgb(255,64,217), rgb(191,105,140), rgb(64,38,38),
  rgb(166,87,58), rgb(153,116,92), rgb(255,183,38), rgb(239,255,13), rgb(106,166,66),
  rgb(62,89,73), rgb(19,77,73), rgb(34,88,115), rgb(41,50,64), rgb(133,124,191),
  rgb(204,0,255), rgb(64,38,59), rgb(255,153,194), rgb(255,191,191), rgb(229,129,92),
  rgb(255,209,179), rgb(204,153,51), rgb(119,128,6), rgb(80,115,57), rgb(6,115,64),
  rgb(124,191,187), rgb(58,130,166), rgb(115,130,153), rgb(80,77,102), rgb(71,0,89),
  rgb(204,133,190), rgb(153,107,125), rgb(51,7,0), rgb(127,71,51), rgb(102,87,77),
  rgb(127,96,32), rgb(193,204,41), rgb(56,77,42), rgb(38,255,154), rgb(96,128,125),
  rgb(170,218,242), rgb(20,42,102), rgb(46,25,102), rgb(51,0,64), rgb(191,57,147),
  rgb(64,48,54), rgb(89,16,4), rgb(242,171,145), rgb(217,116,0), rgb(255,208,115),
  rgb(148,153,77), rgb(23,51,13), rgb(27,89,60), rgb(67,89,88), rgb(96,117,128),
  rgb(126,154,230), rgb(25,15,51), rgb(155,10,191), rgb(128,77,111), rgb(242,0,65),
  rgb(255,89,64), rgb(166,117,99), rgb(115,67,11), rgb(140,112,0), rgb(237,242,157),
  rgb(153,242,121), rgb(80,230,160), rgb(61,230,242), rgb(26,148,255), rgb(92,110,230),
  rgb(40,27,77), rgb(102,52,115), rgb(242,182,222), rgb(64,6,22), rgb(191,75,57),
  rgb(77,54,46), rgb(153,96,31), rgb(229,188,23), rgb(134,166,8), rgb(123,166,108),
  rgb(63,140,104), rgb(41,157,166), rgb(5,30,51), rgb(23,27,51), rgb(68,52,115),
  rgb(73,57,77), rgb(102,77,94), rgb(89,13,34), rgb(115,45,34), rgb(128,95,83),
  rgb(76,50,19), rgb(89,74,13), rgb(63,77,11), rgb(104,128,96), rgb(83,128,107),
  rgb(46,110,115), rgb(57,149,230), rgb(140,156,255), rgb(153,121,242), rgb(107,6,115),
  rgb(255,0,136), rgb(255,77,124), rgb(153,73,61), rgb(255,208,191), rgb(255,178,89),
  rgb(64,54,13), rgb(44,51,15), rgb(63,204,41), rgb(170,242,208), rgb(170,237,242),
  rgb(27,60,89), rgb(77,83,128), rgb(45,38,64), rgb(218,57,230), rgb(153,0,82),
  rgb(140,49,73), rgb(204,116,102), rgb(191,156,143), rgb(115,83,46), rgb(166,149,83),
  rgb(218,242,121), rgb(169,242,157), rgb(0,153,102), rgb(36,201,242), rgb(145,197,242),
  rgb(116,123,166), rgb(199,170,242), rgb(61,26,64), rgb(115,6,64), rgb(102,61,72),
  rgb(89,51,45), rgb(89,38,4), rgb(191,142,86), rgb(51,46,26), rgb(95,102,66),
  rgb(0,153,0), rgb(0,51,34), rgb(10,53,64), rgb(58,75,89), rgb(191,200,255),
  rgb(78,7,140), rgb(76,4,43), rgb(242,0,0), rgb(255,0,0),
];

export default class MapEdit extends Component {
  state = {
    xCenter: 0,
    yCenter: 0,
    mapEdit: {},
    mapEditSelectedZoneID: 0,
    zoneFlags: [],
    renderMapData: [],
    renderTexture: null,
    mapEditZoom: 3,
  };

  renderMapData (mapData, xs, ys, zoneFlags, selectedZone) {
    // Calculate raw render data from the map.
    if(!mapData) {
      return null;
    }

    const data = new Uint8Array(256 * 256 * 4);
    data.fill(128);

    let s = 0;
    const xo = Math.floor((128 - xs) / 2);
    const yo = Math.floor((128 - ys) / 2);

    let blueIndex = [];  // zoneID indexed.
    let waterCount = 0;
    if(Array.isArray(zoneFlags)) {
      for(let z = 0; z < zoneFlags.length; z++) {
        if(zoneFlags[z] & BF.ZONE_FLAG_WATER_ZONE) {
          blueIndex[z] = waterCount++;
        }
  			else {
          blueIndex[z] = 0;
        }
      }
    }

    let wbs = 1;
    if( waterCount >= 1 )
			wbs = Math.floor(190 / waterCount);
		if( wbs < 1 )
			wbs = 1;

    // TODO: might be able to optimize this better.

    for(let y = 0; y < ys; y++) {
    	for(let x = 0; x < xs; x++) {
        const c = parseInt(mapData.substring(s, s + 2), 16);
        let w = ((y + yo) * 256 + x + xo) * 2 + ((y & 1) === 0 ? 1 : 0);

        w *= 4;

        let water = false;
        if(zoneFlags[c] & BF.ZONE_FLAG_WATER_ZONE) {
          water = true;
        }

        let color = distinctColor[c]
        if(water) {
          color = [0, 0, 65+(blueIndex[c]*wbs)];
        }

        if(c && c === selectedZone) {
          color = [255, 0, 0];
          water = false;  // Do not dither.
        }

//        water = false;  // Do not dither.

        if(!water) {
          data[w+4] = color[0];
          data[w+1024] = color[0];
        }
        else {
          data[w+4] = 0;
          data[w+1024] = 0;
        }
        data[w+4+1024] = color[0];
        data[w++] = color[0];

        if(!water) {
          data[w+4] = color[1];
          data[w+1024] = color[1];
        }
        else {
          data[w+4] = 0;
          data[w+1024] = 0;
        }
        data[w+4+1024] = color[1];
        data[w++] = color[1];

        if(!water) {
          data[w+4] = color[2];
          data[w+1024] = color[2];
        }
        else {
          data[w+4] = 255;
          data[w+1024] = 255;
        }
        data[w+4+1024] = color[2];
        data[w++] = color[2];

        data[w+4] = 255;
        data[w+4+1024] = 255;
        data[w+1024] = 255;
        data[w++] = 255;

        s += 2;
      }
    }

    return data;
  }

  constructor(props) {
    super(props);  // Required step: always call the parent class' constructor

    this.state.xCenter = props.xCenter;
    this.state.yCenter = props.yCenter;
    this.state.mapEditSelectedZoneID = props.store.mapEditSelectedZoneID;
    this.state.mapEditZoom = props.store.mapEditZoom;
    this.state.mapEdit = props.store.mapEdit;
    this.state.renderMapData = this.renderMapData(props.store.mapEdit.mapData,
                                                  props.store.mapEdit.xs,
                                                  props.store.mapEdit.ys,
                                                  props.store.mapEdit.zoneFlags,
                                                  props.store.mapEditSelectedZoneID);
    this.state.renderTexture = PIXI.Texture.fromBuffer(this.state.renderMapData, 256, 256);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.xCenter !== this.state.xCenter ||
        nextProps.yCenter !== this.state.yCenter ||
        nextProps.store.mapEditSelectedZoneID !== this.state.mapEditSelectedZoneID ||
        nextProps.store.mapEditZoom !== this.state.mapEditZoom ||
        nextProps.store.mapEdit !== this.state.mapEdit) {

      let { renderMapData, renderTexture } = this.state;
      if(nextProps.store.mapEdit.mapData !== this.state.mapEdit.mapData ||
          nextProps.store.mapEditSelectedZoneID !== this.state.mapEditSelectedZoneID ||
          nextProps.store.mapEdit.zoneFlags !== this.state.mapEdit.zoneFlags) {
        renderMapData = this.renderMapData(nextProps.store.mapEdit.mapData,
                                           nextProps.store.mapEdit.xs,
                                           nextProps.store.mapEdit.ys,
                                           nextProps.store.mapEdit.zoneFlags,
                                           nextProps.store.mapEditSelectedZoneID);
        renderTexture = PIXI.Texture.fromBuffer(renderMapData, 256, 256);
      }

      this.setState({
        xCenter: nextProps.xCenter,
        yCenter: nextProps.yCenter,
        mapEditSelectedZoneID: nextProps.store.mapEditSelectedZoneID,
        mapEditZoom: nextProps.store.mapEditZoom,
        mapEdit: nextProps.store.mapEdit,
        renderMapData,
        renderTexture,
      });
    }
  }

  render() {
    let { renderTexture, xCenter, yCenter, mapEditZoom } = this.state;

    if(!renderTexture) {
      return null;
    }

    return (
      <BufferSprite
        x={ xCenter }
        y={ yCenter }
        scale={ mapEditZoom }
        texture={ renderTexture }
      />
    );
  }
}
