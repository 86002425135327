
export const linkText = {
  color:'#0645AD',
  cursor: 'pointer',
  borderBottom: '1px solid #666666',
};

export const linkTextLight = {
  color:'#DDDDDD',
  cursor: 'pointer',
  borderBottom: '1px solid #AAAAAA',
};
