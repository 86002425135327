import { Game } from "../../../bfcore/gameTypes";


export const worldWarIIGame: Game = {
    "_id": 0,  // To be filled in.
    "state": 3,
    "flags": 3137,
    "name": "World War II",
    "uid": 2,
    "uName": 'GameAdmin',
    "scenarioID": 40005,
    "scenarioName": "World War II",
    "scenarioUID": 2,
    "scenarioUName": "GameAdmin",
    "factories": 8,
    "mapID": 4,
    "mapName": "World War II",
    "mapUID": 2,
    "mapUName": "GameAdmin",
    "mapFlags": 47,
    "mapXS": 512,
    "mapYS": 512,
    "mapData": "",
    "zones": 127,
    "zonesLand": 63,
    "zonesWater": 64,
    "zoneBonus": [],
    "zoneKey": [
        0,
        63,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
        58,
        59,
        60,
        61,
        62,
        101,
        102,
        103,
        104,
        105,
        106,
        107,
        108,
        109,
        110,
        111,
        112,
        113,
        114,
        115,
        116,
        117,
        118,
        119,
        120,
        121,
        122,
        123,
        124,
        125,
        126,
        127,
        128,
        129,
        130,
        131,
        132,
        133,
        134,
        135,
        136,
        137,
        138,
        139,
        140,
        141,
        142,
        143,
        144,
        145,
        146,
        147,
        148,
        149,
        150,
        151,
        152,
        153,
        154,
        155,
        156,
        157,
        158,
        159,
        160,
        161,
        162,
        163,
        164
    ],
    "teams": 2,
    "players": 5,
    "humanPlayers": 5,
    "computerPlayers": 0,
    "pickingOrder": 1,
    "victoryCapitals": 5,
    "victoryIncome": 0,
    "victoryLandZones": 0,
    "victoryCash": 0,
    "maxDuration": 31536000,
    "maxTurns": 100,
    "regScore": [
        0,
        1000000
    ],
    "regMembership": [
        0,
        10
    ],
    "playTimes": [],
    "timeTurn": 1209600,
    "timeZonePicking": 1209600,
    "timeCapitalPicking": 1209600,
    "sourceGameID": 0,
    "privatePIN": 0,
    "turn": 100000000,
    "timeLimit": "",
    "required": 0,
    "noWait": 0,
    "submitted": 0,
    "autoPlay": 0,
    "teammatesSubmit": 0,
    "autoSpend": [
        100,
        100,
        100,
        100,
        100,
        100
    ],
    "autoMove": [
        100,
        100,
        100,
        100,
        100,
        100
    ],
    "slotName": [
        "",
        "Russia",
        "Germany",
        "UK",
        "Japan",
        "USA"
    ],
    "slotFlag": [],
    "playerID": [
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "playerName": [
        "",
        "",
        "",
        "",
        "",
        ""
    ],
    "playerFlags": [
        0,
        1,
        2,
        2,
        2,
        2
    ],
    "playerFlag": [
        "",
        "ru",
        "de",
        "gb",
        "jp",
        "us"
    ],
    "playerColor": [
        16777215,
        12544851,
        8158332,
        16382385,
        16764699,
        8499330
    ],
    "playerScore": [
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "canBuy": [
        0,
        1023,
        1023,
        1023,
        1023,
        1023
    ],
    "maxBuy": [
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "showBuy": [
        0,
        1023,
        1023,
        1023,
        1023,
        1023
    ],
    "finished": [
        [
            0,
            0
        ],
        [
            0,
            0
        ],
        [
            0,
            0
        ],
        [
            0,
            0
        ],
        [
            0,
            0
        ],
        [
            0,
            0
        ]
    ],
    "playerAutoTotal": [
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "playerAutoCurrent": [
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "playerMissedTotal": [
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "playerMissedCurrent": [
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "capital": [
        0,
        7,
        15,
        3,
        55,
        13
    ],
    "capitalX": [
        0,
        4224,
        2624,
        1792,
        6448,
        512
    ],
    "capitalY": [
        0,
        640,
        1984,
        768,
        2096,
        2624
    ],
    "cash": [
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "team": [
        0,
        1,
        2,
        1,
        2,
        1
    ],
    "fixedAlly": [
        1,
        42,
        20,
        42,
        20,
        42
    ],
    "declaredAlly": [
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "force": [
        [],
        [
            [
                17,
                0,
                5,
                5,
                0,
                1,
                0,
                0,
                3072,
                1024,
                3072,
                1024
            ],
            [
                18,
                0,
                5,
                5,
                0,
                1,
                0,
                0,
                3008,
                448,
                3008,
                448
            ],
            [
                19,
                0,
                5,
                5,
                0,
                1,
                0,
                0,
                3072,
                320,
                3072,
                320
            ],
            [
                20,
                3,
                5,
                5,
                0,
                4,
                0,
                0,
                2816,
                384,
                2816,
                384
            ],
            [
                21,
                5,
                67,
                67,
                0,
                2,
                0,
                0,
                2880,
                48,
                2880,
                48
            ],
            [
                22,
                0,
                6,
                6,
                0,
                1,
                0,
                0,
                3968,
                0,
                3968,
                0
            ],
            [
                23,
                0,
                6,
                6,
                0,
                1,
                0,
                0,
                3264,
                512,
                3264,
                512
            ],
            [
                24,
                0,
                6,
                6,
                0,
                1,
                0,
                0,
                4032,
                256,
                4032,
                256
            ],
            [
                25,
                1,
                6,
                6,
                0,
                2,
                0,
                0,
                3392,
                576,
                3392,
                576
            ],
            [
                26,
                0,
                7,
                7,
                0,
                1,
                0,
                0,
                4032,
                512,
                4032,
                512
            ],
            [
                27,
                0,
                7,
                7,
                0,
                1,
                0,
                0,
                4672,
                320,
                4672,
                320
            ],
            [
                28,
                0,
                7,
                7,
                0,
                1,
                0,
                0,
                4288,
                0,
                4288,
                0
            ],
            [
                29,
                0,
                7,
                7,
                0,
                1,
                0,
                0,
                4224,
                128,
                4224,
                128
            ],
            [
                30,
                1,
                7,
                7,
                0,
                2,
                0,
                0,
                4416,
                512,
                4416,
                512
            ],
            [
                31,
                1,
                7,
                7,
                0,
                2,
                0,
                0,
                4160,
                128,
                4160,
                128
            ],
            [
                32,
                2,
                7,
                7,
                0,
                1,
                0,
                0,
                4160,
                256,
                4160,
                256
            ],
            [
                33,
                3,
                7,
                7,
                0,
                4,
                0,
                0,
                4224,
                64,
                4224,
                64
            ],
            [
                34,
                9,
                7,
                7,
                0,
                0,
                0,
                0,
                4160,
                512,
                4160,
                512
            ],
            [
                35,
                0,
                8,
                8,
                0,
                1,
                0,
                0,
                5056,
                256,
                5056,
                256
            ],
            [
                36,
                0,
                8,
                8,
                0,
                1,
                0,
                0,
                4800,
                128,
                4800,
                128
            ],
            [
                37,
                0,
                9,
                9,
                0,
                1,
                0,
                0,
                5632,
                128,
                5632,
                128
            ],
            [
                38,
                0,
                9,
                9,
                0,
                1,
                0,
                0,
                5568,
                320,
                5568,
                320
            ],
            [
                39,
                0,
                10,
                10,
                0,
                1,
                0,
                0,
                5952,
                0,
                5952,
                0
            ],
            [
                40,
                0,
                10,
                10,
                0,
                1,
                0,
                0,
                6016,
                192,
                6016,
                192
            ],
            [
                81,
                0,
                18,
                18,
                0,
                1,
                0,
                0,
                3840,
                1984,
                3840,
                1984
            ],
            [
                82,
                0,
                18,
                18,
                0,
                1,
                0,
                0,
                3424,
                1328,
                3424,
                1328
            ],
            [
                83,
                0,
                18,
                18,
                0,
                1,
                0,
                0,
                3456,
                1344,
                3456,
                1344
            ],
            [
                84,
                0,
                18,
                18,
                0,
                1,
                0,
                0,
                3968,
                1600,
                3968,
                1600
            ],
            [
                85,
                1,
                18,
                18,
                0,
                2,
                0,
                0,
                3520,
                1920,
                3520,
                1920
            ],
            [
                86,
                0,
                19,
                19,
                0,
                1,
                0,
                0,
                4608,
                1408,
                4608,
                1408
            ],
            [
                87,
                0,
                19,
                19,
                0,
                1,
                0,
                0,
                4352,
                1536,
                4352,
                1536
            ],
            [
                88,
                0,
                20,
                20,
                0,
                1,
                0,
                0,
                5696,
                960,
                5696,
                960
            ],
            [
                89,
                0,
                20,
                20,
                0,
                1,
                0,
                0,
                5760,
                832,
                5760,
                832
            ],
            [
                134,
                0,
                36,
                36,
                0,
                1,
                0,
                0,
                3648,
                2880,
                3648,
                2880
            ],
            [
                135,
                0,
                36,
                36,
                0,
                1,
                0,
                0,
                3648,
                2816,
                3648,
                2816
            ],
            [
                136,
                0,
                36,
                36,
                0,
                1,
                0,
                0,
                3840,
                2304,
                3840,
                2304
            ],
            [
                137,
                0,
                36,
                36,
                0,
                1,
                0,
                0,
                3520,
                2496,
                3520,
                2496
            ],
            [
                138,
                1,
                36,
                36,
                0,
                2,
                0,
                0,
                3584,
                2688,
                3584,
                2688
            ],
            [
                139,
                2,
                36,
                36,
                0,
                1,
                0,
                0,
                3520,
                2624,
                3520,
                2624
            ],
            [
                140,
                9,
                36,
                36,
                0,
                0,
                0,
                0,
                3776,
                2816,
                3776,
                2816
            ],
            [
                141,
                0,
                37,
                37,
                0,
                1,
                0,
                0,
                4096,
                2688,
                4096,
                2688
            ],
            [
                142,
                0,
                37,
                37,
                0,
                1,
                0,
                0,
                3968,
                2944,
                3968,
                2944
            ]
        ],
        [
            [
                13,
                0,
                4,
                4,
                0,
                1,
                0,
                0,
                2624,
                320,
                2624,
                320
            ],
            [
                14,
                0,
                4,
                4,
                0,
                1,
                0,
                0,
                2560,
                240,
                2560,
                240
            ],
            [
                15,
                0,
                4,
                4,
                0,
                1,
                0,
                0,
                2624,
                832,
                2624,
                832
            ],
            [
                16,
                3,
                4,
                4,
                0,
                4,
                0,
                0,
                2560,
                240,
                2560,
                240
            ],
            [
                53,
                0,
                14,
                14,
                0,
                1,
                0,
                0,
                1920,
                2240,
                1920,
                2240
            ],
            [
                54,
                0,
                14,
                14,
                0,
                1,
                0,
                0,
                2048,
                2240,
                2048,
                2240
            ],
            [
                55,
                1,
                14,
                14,
                0,
                2,
                0,
                0,
                1920,
                2560,
                1920,
                2560
            ],
            [
                56,
                1,
                14,
                14,
                0,
                2,
                0,
                0,
                1664,
                2432,
                1664,
                2432
            ],
            [
                57,
                3,
                14,
                14,
                0,
                4,
                0,
                0,
                2112,
                1984,
                2112,
                1984
            ],
            [
                58,
                4,
                14,
                14,
                0,
                6,
                0,
                0,
                1920,
                2496,
                1920,
                2496
            ],
            [
                59,
                0,
                15,
                15,
                0,
                1,
                0,
                0,
                2240,
                1984,
                2240,
                1984
            ],
            [
                60,
                0,
                15,
                15,
                0,
                1,
                0,
                0,
                2496,
                1856,
                2496,
                1856
            ],
            [
                61,
                0,
                15,
                15,
                0,
                1,
                0,
                0,
                2432,
                2112,
                2432,
                2112
            ],
            [
                62,
                0,
                15,
                15,
                0,
                1,
                0,
                0,
                2368,
                1920,
                2368,
                1920
            ],
            [
                63,
                1,
                15,
                15,
                0,
                2,
                0,
                0,
                2304,
                2112,
                2304,
                2112
            ],
            [
                64,
                1,
                15,
                15,
                0,
                2,
                0,
                0,
                2432,
                1856,
                2432,
                1856
            ],
            [
                65,
                1,
                15,
                15,
                0,
                2,
                0,
                0,
                2560,
                1984,
                2560,
                1984
            ],
            [
                66,
                2,
                15,
                15,
                0,
                1,
                0,
                0,
                2624,
                2368,
                2624,
                2368
            ],
            [
                67,
                3,
                15,
                15,
                0,
                4,
                0,
                0,
                2496,
                1728,
                2496,
                1728
            ],
            [
                68,
                4,
                15,
                15,
                0,
                6,
                0,
                0,
                2624,
                2432,
                2624,
                2432
            ],
            [
                69,
                5,
                68,
                68,
                0,
                2,
                0,
                0,
                2432,
                1536,
                2432,
                1536
            ],
            [
                70,
                5,
                68,
                68,
                0,
                2,
                0,
                0,
                2496,
                1088,
                2496,
                1088
            ],
            [
                71,
                6,
                68,
                68,
                0,
                2,
                2,
                0,
                2240,
                1728,
                2240,
                1728
            ],
            [
                72,
                8,
                68,
                68,
                0,
                2,
                0,
                0,
                2816,
                1152,
                2816,
                1152
            ],
            [
                73,
                9,
                15,
                15,
                0,
                0,
                0,
                0,
                2176,
                2112,
                2176,
                2112
            ],
            [
                74,
                0,
                16,
                16,
                0,
                1,
                0,
                0,
                2816,
                1280,
                2816,
                1280
            ],
            [
                75,
                0,
                16,
                16,
                0,
                1,
                0,
                0,
                2880,
                1344,
                2880,
                1344
            ],
            [
                76,
                1,
                16,
                16,
                0,
                2,
                0,
                0,
                2752,
                1664,
                2752,
                1664
            ],
            [
                77,
                3,
                16,
                16,
                0,
                4,
                0,
                0,
                3008,
                2240,
                3008,
                2240
            ],
            [
                78,
                0,
                17,
                17,
                0,
                1,
                0,
                0,
                3456,
                1984,
                3456,
                1984
            ],
            [
                79,
                0,
                17,
                17,
                0,
                1,
                0,
                0,
                3392,
                1984,
                3392,
                1984
            ],
            [
                80,
                0,
                17,
                17,
                0,
                1,
                0,
                0,
                3136,
                1328,
                3136,
                1328
            ],
            [
                112,
                0,
                31,
                31,
                0,
                1,
                0,
                0,
                1664,
                4224,
                1664,
                4224
            ],
            [
                113,
                1,
                31,
                31,
                0,
                2,
                0,
                0,
                2048,
                4096,
                2048,
                4096
            ],
            [
                114,
                0,
                32,
                32,
                0,
                1,
                0,
                0,
                2304,
                4160,
                2304,
                4160
            ],
            [
                115,
                1,
                32,
                32,
                0,
                2,
                0,
                0,
                2176,
                4224,
                2176,
                4224
            ],
            [
                116,
                0,
                33,
                33,
                0,
                1,
                0,
                0,
                2624,
                2816,
                2624,
                2816
            ],
            [
                117,
                0,
                33,
                33,
                0,
                1,
                0,
                0,
                2304,
                2944,
                2304,
                2944
            ],
            [
                118,
                0,
                33,
                33,
                0,
                1,
                0,
                0,
                2624,
                3136,
                2624,
                3136
            ],
            [
                119,
                1,
                33,
                33,
                0,
                2,
                0,
                0,
                2752,
                3392,
                2752,
                3392
            ],
            [
                120,
                2,
                33,
                33,
                0,
                1,
                0,
                0,
                2752,
                3584,
                2752,
                3584
            ],
            [
                121,
                6,
                77,
                77,
                0,
                2,
                2,
                0,
                2368,
                3904,
                2368,
                3904
            ],
            [
                122,
                8,
                77,
                77,
                0,
                2,
                0,
                0,
                2240,
                3648,
                2240,
                3648
            ],
            [
                123,
                9,
                33,
                33,
                0,
                0,
                0,
                0,
                2304,
                3008,
                2304,
                3008
            ],
            [
                124,
                0,
                34,
                34,
                0,
                1,
                0,
                0,
                3072,
                3136,
                3072,
                3136
            ],
            [
                125,
                0,
                34,
                34,
                0,
                1,
                0,
                0,
                2880,
                3008,
                2880,
                3008
            ],
            [
                126,
                1,
                34,
                34,
                0,
                2,
                0,
                0,
                2944,
                2624,
                2944,
                2624
            ],
            [
                127,
                3,
                34,
                34,
                0,
                4,
                0,
                0,
                2944,
                3200,
                2944,
                3200
            ],
            [
                128,
                0,
                35,
                35,
                0,
                1,
                0,
                0,
                3264,
                3072,
                3264,
                3072
            ],
            [
                129,
                0,
                35,
                35,
                0,
                1,
                0,
                0,
                3200,
                2112,
                3200,
                2112
            ],
            [
                130,
                0,
                35,
                35,
                0,
                1,
                0,
                0,
                3328,
                2240,
                3328,
                2240
            ],
            [
                131,
                0,
                35,
                35,
                0,
                1,
                0,
                0,
                3328,
                2560,
                3328,
                2560
            ],
            [
                132,
                1,
                35,
                35,
                0,
                2,
                0,
                0,
                3136,
                2240,
                3136,
                2240
            ],
            [
                133,
                3,
                35,
                35,
                0,
                4,
                0,
                0,
                3200,
                2944,
                3200,
                2944
            ]
        ],
        [
            [
                1,
                1,
                1,
                1,
                0,
                2,
                0,
                0,
                64,
                256,
                64,
                256
            ],
            [
                2,
                6,
                64,
                64,
                0,
                2,
                2,
                0,
                576,
                576,
                576,
                576
            ],
            [
                3,
                0,
                3,
                3,
                0,
                1,
                0,
                0,
                1728,
                1536,
                1728,
                1536
            ],
            [
                4,
                0,
                3,
                3,
                0,
                1,
                0,
                0,
                1728,
                1536,
                1728,
                1536
            ],
            [
                5,
                0,
                3,
                3,
                0,
                1,
                0,
                0,
                1472,
                960,
                1472,
                960
            ],
            [
                6,
                1,
                3,
                3,
                0,
                2,
                0,
                0,
                1600,
                1408,
                1600,
                1408
            ],
            [
                7,
                2,
                3,
                3,
                0,
                1,
                0,
                0,
                1792,
                1472,
                1792,
                1472
            ],
            [
                8,
                3,
                3,
                3,
                0,
                4,
                0,
                0,
                1920,
                1152,
                1920,
                1152
            ],
            [
                9,
                4,
                3,
                3,
                0,
                6,
                0,
                0,
                1728,
                1344,
                1728,
                1344
            ],
            [
                10,
                6,
                65,
                65,
                0,
                2,
                2,
                0,
                1344,
                320,
                1344,
                320
            ],
            [
                11,
                8,
                65,
                65,
                0,
                2,
                0,
                0,
                1344,
                320,
                1344,
                320
            ],
            [
                12,
                9,
                3,
                3,
                0,
                0,
                0,
                0,
                1728,
                896,
                1728,
                896
            ],
            [
                42,
                0,
                12,
                12,
                0,
                1,
                0,
                0,
                7680,
                256,
                7680,
                256
            ],
            [
                143,
                8,
                76,
                76,
                0,
                2,
                0,
                0,
                1600,
                3904,
                1600,
                3904
            ],
            [
                144,
                0,
                41,
                41,
                0,
                1,
                0,
                0,
                2880,
                5056,
                2880,
                5056
            ],
            [
                145,
                1,
                41,
                41,
                0,
                2,
                0,
                0,
                2880,
                4544,
                2880,
                4544
            ],
            [
                146,
                3,
                41,
                41,
                0,
                4,
                0,
                0,
                2880,
                4864,
                2880,
                4864
            ],
            [
                147,
                0,
                45,
                45,
                0,
                1,
                0,
                0,
                2688,
                7360,
                2688,
                7360
            ],
            [
                148,
                0,
                47,
                47,
                0,
                1,
                0,
                0,
                3520,
                4352,
                3520,
                4352
            ],
            [
                149,
                5,
                97,
                97,
                0,
                2,
                0,
                0,
                3008,
                4800,
                3008,
                4800
            ],
            [
                150,
                0,
                48,
                48,
                0,
                1,
                0,
                0,
                3968,
                4032,
                3968,
                4032
            ],
            [
                151,
                0,
                49,
                49,
                0,
                1,
                0,
                0,
                4416,
                3776,
                4416,
                3776
            ],
            [
                152,
                0,
                49,
                49,
                0,
                1,
                0,
                0,
                4224,
                4096,
                4224,
                4096
            ],
            [
                153,
                0,
                49,
                49,
                0,
                1,
                0,
                0,
                4544,
                4288,
                4544,
                4288
            ],
            [
                154,
                3,
                49,
                49,
                0,
                4,
                0,
                0,
                4224,
                4032,
                4224,
                4032
            ],
            [
                155,
                6,
                98,
                98,
                0,
                2,
                2,
                0,
                4672,
                4800,
                4672,
                4800
            ],
            [
                156,
                7,
                98,
                98,
                0,
                2,
                0,
                0,
                4160,
                4928,
                4160,
                4928
            ],
            [
                157,
                8,
                98,
                98,
                0,
                2,
                0,
                0,
                4160,
                4736,
                4160,
                4736
            ],
            [
                158,
                0,
                50,
                50,
                0,
                1,
                0,
                0,
                6144,
                6720,
                6144,
                6720
            ],
            [
                159,
                0,
                50,
                50,
                0,
                1,
                0,
                0,
                6336,
                7296,
                6336,
                7296
            ],
            [
                160,
                0,
                50,
                50,
                0,
                1,
                0,
                0,
                6144,
                7232,
                6144,
                7232
            ],
            [
                161,
                5,
                103,
                103,
                0,
                2,
                0,
                0,
                6144,
                7936,
                6144,
                7936
            ],
            [
                162,
                6,
                103,
                103,
                0,
                2,
                2,
                0,
                6160,
                7856,
                6160,
                7856
            ],
            [
                163,
                0,
                51,
                51,
                0,
                1,
                0,
                0,
                7168,
                7152,
                7168,
                7152
            ]
        ],
        [
            [
                164,
                0,
                52,
                52,
                0,
                1,
                0,
                0,
                5824,
                1792,
                5824,
                1792
            ],
            [
                165,
                0,
                52,
                52,
                0,
                1,
                0,
                0,
                5504,
                2496,
                5504,
                2496
            ],
            [
                166,
                3,
                52,
                52,
                0,
                4,
                0,
                0,
                5632,
                1984,
                5632,
                1984
            ],
            [
                167,
                0,
                53,
                53,
                0,
                1,
                0,
                0,
                5440,
                3648,
                5440,
                3648
            ],
            [
                168,
                0,
                53,
                53,
                0,
                1,
                0,
                0,
                5568,
                3648,
                5568,
                3648
            ],
            [
                169,
                0,
                53,
                53,
                0,
                1,
                0,
                0,
                5504,
                2816,
                5504,
                2816
            ],
            [
                170,
                6,
                122,
                122,
                0,
                2,
                2,
                0,
                5760,
                3456,
                5760,
                3456
            ],
            [
                171,
                0,
                54,
                54,
                0,
                1,
                0,
                0,
                5120,
                4224,
                5120,
                4224
            ],
            [
                172,
                0,
                54,
                54,
                0,
                1,
                0,
                0,
                4992,
                3520,
                4992,
                3520
            ],
            [
                173,
                3,
                54,
                54,
                0,
                4,
                0,
                0,
                5120,
                3776,
                5120,
                3776
            ],
            [
                174,
                0,
                55,
                55,
                0,
                1,
                0,
                0,
                6016,
                3456,
                6016,
                3456
            ],
            [
                175,
                0,
                55,
                55,
                0,
                1,
                0,
                0,
                6400,
                2624,
                6400,
                2624
            ],
            [
                176,
                0,
                55,
                55,
                0,
                1,
                0,
                0,
                6512,
                1904,
                6512,
                1904
            ],
            [
                177,
                0,
                55,
                55,
                0,
                1,
                0,
                0,
                6400,
                2096,
                6400,
                2096
            ],
            [
                178,
                1,
                55,
                55,
                0,
                2,
                0,
                0,
                6432,
                1968,
                6432,
                1968
            ],
            [
                179,
                1,
                55,
                55,
                0,
                2,
                0,
                0,
                6352,
                1904,
                6352,
                1904
            ],
            [
                180,
                2,
                55,
                55,
                0,
                1,
                0,
                0,
                6400,
                2624,
                6400,
                2624
            ],
            [
                181,
                3,
                55,
                55,
                0,
                4,
                0,
                0,
                6336,
                2240,
                6336,
                2240
            ],
            [
                182,
                4,
                55,
                55,
                0,
                6,
                0,
                0,
                6304,
                2160,
                6304,
                2160
            ],
            [
                183,
                6,
                123,
                123,
                0,
                2,
                2,
                0,
                6400,
                1728,
                6400,
                1728
            ],
            [
                184,
                8,
                123,
                123,
                0,
                2,
                0,
                0,
                6400,
                1728,
                6400,
                1728
            ],
            [
                185,
                9,
                55,
                55,
                0,
                0,
                0,
                0,
                6272,
                3392,
                6272,
                3392
            ],
            [
                186,
                0,
                56,
                56,
                0,
                1,
                0,
                0,
                6272,
                4640,
                6272,
                4640
            ],
            [
                187,
                0,
                57,
                57,
                0,
                1,
                0,
                0,
                6640,
                4608,
                6640,
                4608
            ],
            [
                188,
                0,
                58,
                58,
                0,
                1,
                0,
                0,
                5808,
                5040,
                5808,
                5040
            ],
            [
                189,
                0,
                58,
                58,
                0,
                1,
                0,
                0,
                5840,
                4912,
                5840,
                4912
            ],
            [
                190,
                0,
                59,
                59,
                0,
                1,
                0,
                0,
                6464,
                5424,
                6464,
                5424
            ],
            [
                191,
                3,
                59,
                59,
                0,
                4,
                0,
                0,
                6512,
                5344,
                6512,
                5344
            ],
            [
                192,
                7,
                113,
                113,
                0,
                2,
                0,
                0,
                6144,
                4864,
                6144,
                4864
            ],
            [
                193,
                8,
                113,
                113,
                0,
                2,
                0,
                0,
                6208,
                5184,
                6208,
                5184
            ],
            [
                194,
                0,
                60,
                60,
                0,
                1,
                0,
                0,
                5248,
                6336,
                5248,
                6336
            ],
            [
                195,
                0,
                60,
                60,
                0,
                1,
                0,
                0,
                5040,
                5680,
                5040,
                5680
            ],
            [
                196,
                3,
                60,
                60,
                0,
                4,
                0,
                0,
                5024,
                5808,
                5024,
                5808
            ],
            [
                197,
                3,
                60,
                60,
                0,
                4,
                0,
                0,
                5184,
                6336,
                5184,
                6336
            ],
            [
                198,
                7,
                100,
                100,
                0,
                2,
                0,
                0,
                5248,
                5952,
                5248,
                5952
            ],
            [
                199,
                8,
                100,
                100,
                0,
                2,
                0,
                0,
                5312,
                5952,
                5312,
                5952
            ],
            [
                200,
                0,
                61,
                61,
                0,
                1,
                0,
                0,
                5696,
                5824,
                5696,
                5824
            ],
            [
                201,
                0,
                62,
                62,
                0,
                1,
                0,
                0,
                6272,
                6256,
                6272,
                6256
            ],
            [
                202,
                0,
                63,
                63,
                0,
                1,
                0,
                0,
                7104,
                6272,
                7104,
                6272
            ],
            [
                203,
                5,
                108,
                108,
                0,
                2,
                0,
                0,
                6912,
                5248,
                6912,
                5248
            ]
        ],
        [
            [
                41,
                0,
                11,
                11,
                0,
                1,
                0,
                0,
                7296,
                1280,
                7296,
                1280
            ],
            [
                43,
                0,
                13,
                13,
                0,
                1,
                0,
                0,
                0,
                2368,
                0,
                2368
            ],
            [
                44,
                0,
                13,
                13,
                0,
                1,
                0,
                0,
                512,
                2432,
                512,
                2432
            ],
            [
                45,
                0,
                13,
                13,
                0,
                1,
                0,
                0,
                192,
                2112,
                192,
                2112
            ],
            [
                46,
                1,
                13,
                13,
                0,
                2,
                0,
                0,
                704,
                2368,
                704,
                2368
            ],
            [
                47,
                2,
                13,
                13,
                0,
                1,
                0,
                0,
                576,
                2560,
                576,
                2560
            ],
            [
                48,
                3,
                13,
                13,
                0,
                4,
                0,
                0,
                384,
                2240,
                384,
                2240
            ],
            [
                49,
                4,
                13,
                13,
                0,
                6,
                0,
                0,
                576,
                2560,
                576,
                2560
            ],
            [
                50,
                6,
                73,
                73,
                0,
                2,
                2,
                0,
                640,
                3136,
                640,
                3136
            ],
            [
                51,
                8,
                73,
                73,
                0,
                2,
                0,
                0,
                512,
                3008,
                512,
                3008
            ],
            [
                52,
                9,
                13,
                13,
                0,
                0,
                0,
                0,
                256,
                2496,
                256,
                2496
            ],
            [
                90,
                0,
                21,
                21,
                0,
                1,
                0,
                0,
                7936,
                2496,
                7936,
                2496
            ],
            [
                91,
                0,
                21,
                21,
                0,
                1,
                0,
                0,
                7744,
                2240,
                7744,
                2240
            ],
            [
                92,
                2,
                21,
                21,
                0,
                1,
                0,
                0,
                7792,
                2096,
                7792,
                2096
            ],
            [
                93,
                3,
                21,
                21,
                0,
                4,
                0,
                0,
                7920,
                2096,
                7920,
                2096
            ],
            [
                94,
                6,
                118,
                118,
                0,
                2,
                2,
                0,
                7616,
                3008,
                7616,
                3008
            ],
            [
                95,
                8,
                118,
                118,
                0,
                2,
                0,
                0,
                7680,
                2944,
                7680,
                2944
            ],
            [
                96,
                9,
                21,
                21,
                0,
                0,
                0,
                0,
                7872,
                2624,
                7872,
                2624
            ],
            [
                97,
                0,
                22,
                22,
                0,
                1,
                0,
                0,
                8144,
                2160,
                8144,
                2160
            ],
            [
                98,
                0,
                22,
                22,
                0,
                1,
                0,
                0,
                8144,
                2224,
                8144,
                2224
            ],
            [
                99,
                8,
                83,
                83,
                0,
                2,
                0,
                0,
                96,
                4336,
                96,
                4336
            ],
            [
                100,
                0,
                27,
                27,
                0,
                1,
                0,
                0,
                4864,
                2816,
                4864,
                2816
            ],
            [
                101,
                0,
                27,
                27,
                0,
                1,
                0,
                0,
                4608,
                2816,
                4608,
                2816
            ],
            [
                102,
                0,
                28,
                28,
                0,
                1,
                0,
                0,
                5376,
                2688,
                5376,
                2688
            ],
            [
                103,
                0,
                28,
                28,
                0,
                1,
                0,
                0,
                5184,
                3200,
                5184,
                3200
            ],
            [
                104,
                3,
                28,
                28,
                0,
                4,
                0,
                0,
                5056,
                2752,
                5056,
                2752
            ],
            [
                105,
                0,
                29,
                29,
                0,
                1,
                0,
                0,
                7168,
                2592,
                7168,
                2592
            ],
            [
                106,
                0,
                30,
                30,
                0,
                1,
                0,
                0,
                7440,
                4384,
                7440,
                4384
            ],
            [
                107,
                0,
                30,
                30,
                0,
                1,
                0,
                0,
                7376,
                4384,
                7376,
                4384
            ],
            [
                108,
                3,
                30,
                30,
                0,
                4,
                0,
                0,
                7424,
                4400,
                7424,
                4400
            ],
            [
                109,
                3,
                30,
                30,
                0,
                4,
                0,
                0,
                7344,
                4144,
                7344,
                4144
            ],
            [
                110,
                7,
                115,
                115,
                0,
                2,
                0,
                0,
                6976,
                4160,
                6976,
                4160
            ],
            [
                111,
                8,
                115,
                115,
                0,
                2,
                0,
                0,
                7424,
                3776,
                7424,
                3776
            ]
        ]
    ],
    "losses": [
        [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        [
            0,
            0,
            0,
            0,
            0,
            0
        ]
    ],
    "missedAllowed": 2,
    "autoTotal": 0,
    "autoCurrent": 0,
    "autoAllowed": 2,
    "teamName": [
        "",
        "Allies",
        "Axis"
    ],
    "zoneFlags": [
        0,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2
    ],
    "zoneName": [
        "",
        "Eastern Canada",
        "Greenland",
        "United Kingdom",
        "Norway",
        "Karelia",
        "Archangel",
        "Russia",
        "Evenki National Okrug",
        "Yakut",
        "Soviet Far East",
        "Alaska",
        "Western Canada",
        "Eastern Usa",
        "Western Europe",
        "Deutschland",
        "Eastern Europe",
        "Belorussia",
        "West Russia",
        "Novosibirsk",
        "Buryatia",
        "Western Usa",
        "Central Usa",
        "Mexico",
        "Panama",
        "West Indies",
        "Brazil",
        "Sinkiang",
        "China",
        "Midway",
        "Hawaii",
        "Algeria",
        "Libya",
        "Southern Europe",
        "Balkans",
        "Ukraine",
        "Caucasus",
        "Kazakh",
        "Gibraltar",
        "West Africa",
        "Equatorial Africa",
        "Egypt",
        "Congo",
        "East Africa",
        "Rhodesia",
        "South Africa",
        "Madagascar",
        "Jordan",
        "Persia",
        "India",
        "Australia",
        "New Zealand",
        "Manchuria",
        "Kwangtung",
        "Indochina",
        "Nippon",
        "Okinawa",
        "Wake Island",
        "Philippines",
        "Caroline Islands",
        "East Indies",
        "Borneo",
        "New Guinea",
        "Solomon Islands",
        "Hudsons Bay",
        "Irish Sea",
        "Norwegian Sea",
        "Barents Sea",
        "Baltic Sea",
        "North Sea",
        "Bay Of Biscay",
        "North Atlantic",
        "Gulf Of St Lawrence",
        "Gulf Of Mexico",
        "Atlantic Ocean",
        "Coast Of Spain",
        "Strait Of Gilbraltar",
        "Ionian Sea",
        "Gulf Of Sidra",
        "Black Sea",
        "Cape Verde Basin",
        "Guiana Basin",
        "Caribbean Sea",
        "Gulf Of Panama",
        "Peru Basin",
        "Uruguay Sea",
        "Gulf Of Guinea",
        "South Atlantic",
        "Argentine Basin",
        "Scotia Sea",
        "Namibian Sea",
        "Cape Of Good Hope",
        "Madagascar Basin",
        "Crozet Basin",
        "Indian Ocean",
        "Seychelles Basin",
        "Mozambique Channel",
        "Red Sea",
        "Bay Of Bengal",
        "South China Sea",
        "Java Sea",
        "West Aus Basin",
        "South Aus Basin",
        "Tasman Sea",
        "Fiji Basin",
        "Ross Sea",
        "South Pacific",
        "Samoa Sea",
        "Solomon Sea",
        "Coral Sea",
        "Guinea Sea",
        "Celebes Sea",
        "Sulu Sea",
        "Caroline Sea",
        "Wake Sea",
        "Hawaiian Sea",
        "Pacific Ocean",
        "Gulf Of California",
        "California Coast",
        "Midway Sea",
        "North Pacific",
        "Okinawa Sea",
        "East China Sea",
        "Western Pacific",
        "Sea Of Japan",
        "Sea Of Okhotsk",
        "Bering Strait",
        "Gulf Of Alaska"
    ],
    "zoneIncome": [
        0,
        3,
        0,
        8,
        3,
        2,
        2,
        8,
        1,
        1,
        1,
        2,
        1,
        12,
        6,
        10,
        3,
        2,
        2,
        2,
        1,
        10,
        6,
        2,
        1,
        1,
        3,
        2,
        2,
        0,
        1,
        1,
        1,
        6,
        3,
        3,
        4,
        2,
        0,
        1,
        1,
        2,
        1,
        1,
        1,
        2,
        1,
        1,
        1,
        3,
        2,
        1,
        3,
        3,
        3,
        8,
        1,
        0,
        3,
        0,
        4,
        4,
        1,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "bordersLand": [
        [],
        [
            13,
            22,
            12
        ],
        [],
        [],
        [
            5
        ],
        [
            17,
            18,
            16,
            6,
            4
        ],
        [
            18,
            5,
            7
        ],
        [
            37,
            19,
            36,
            18,
            8,
            6
        ],
        [
            19,
            9,
            7
        ],
        [
            20,
            19,
            8,
            10
        ],
        [
            20,
            9
        ],
        [
            12
        ],
        [
            21,
            22,
            1,
            11
        ],
        [
            24,
            22,
            1
        ],
        [
            33,
            15
        ],
        [
            33,
            34,
            14,
            16
        ],
        [
            34,
            15,
            35,
            17,
            5
        ],
        [
            35,
            18,
            16,
            5
        ],
        [
            35,
            36,
            17,
            7,
            5,
            6
        ],
        [
            37,
            27,
            7,
            9,
            8
        ],
        [
            52,
            9,
            10
        ],
        [
            23,
            22,
            12
        ],
        [
            23,
            13,
            21,
            1,
            12
        ],
        [
            24,
            22,
            21
        ],
        [
            23,
            13
        ],
        [],
        [],
        [
            37,
            28,
            19
        ],
        [
            54,
            53,
            27,
            52
        ],
        [],
        [],
        [
            32
        ],
        [
            41,
            31
        ],
        [
            34,
            14,
            15
        ],
        [
            33,
            35,
            15,
            16
        ],
        [
            34,
            36,
            16,
            18,
            17
        ],
        [
            48,
            37,
            35,
            7,
            18
        ],
        [
            48,
            27,
            36,
            19,
            7
        ],
        [],
        [
            40
        ],
        [
            42,
            39,
            41
        ],
        [
            42,
            43,
            40,
            32,
            47
        ],
        [
            44,
            40,
            43,
            41
        ],
        [
            44,
            42,
            41
        ],
        [
            45,
            42,
            43
        ],
        [
            44
        ],
        [],
        [
            48,
            41
        ],
        [
            49,
            47,
            36,
            37
        ],
        [
            48,
            54
        ],
        [],
        [],
        [
            53,
            28,
            20
        ],
        [
            54,
            28,
            52
        ],
        [
            49,
            53,
            28
        ],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [
            1
        ],
        [
            2,
            3
        ],
        [
            3,
            4
        ],
        [
            5,
            6
        ],
        [
            4,
            5,
            14,
            15,
            16
        ],
        [
            3,
            4,
            14
        ],
        [
            3,
            14
        ],
        [
            3
        ],
        [
            1
        ],
        [
            13,
            24
        ],
        [],
        [
            31
        ],
        [
            14,
            31,
            38
        ],
        [
            32,
            33
        ],
        [
            41,
            47
        ],
        [
            34,
            35,
            36
        ],
        [
            39
        ],
        [
            26
        ],
        [
            24,
            25
        ],
        [
            24
        ],
        [],
        [
            26
        ],
        [
            40,
            42
        ],
        [],
        [],
        [],
        [
            45
        ],
        [
            45,
            46
        ],
        [
            46
        ],
        [],
        [],
        [
            46
        ],
        [
            44,
            45,
            46
        ],
        [
            41,
            43,
            47,
            48
        ],
        [
            49
        ],
        [
            54
        ],
        [
            60
        ],
        [
            50
        ],
        [
            50
        ],
        [
            50
        ],
        [
            51
        ],
        [],
        [],
        [],
        [
            63
        ],
        [
            50
        ],
        [
            62
        ],
        [
            61
        ],
        [
            58
        ],
        [
            59
        ],
        [
            57
        ],
        [
            30
        ],
        [],
        [
            21,
            23
        ],
        [
            12,
            21
        ],
        [
            29
        ],
        [],
        [
            56
        ],
        [
            53
        ],
        [
            20,
            55
        ],
        [
            52,
            55
        ],
        [
            10,
            20
        ],
        [
            11,
            12
        ],
        [
            11,
            12
        ]
    ],
    "bordersWater": [
        [],
        [
            64,
            72
        ],
        [
            65
        ],
        [
            65,
            71,
            70,
            69,
            66
        ],
        [
            69,
            68,
            66
        ],
        [
            67,
            68
        ],
        [
            67
        ],
        [],
        [],
        [],
        [
            125
        ],
        [
            126,
            127
        ],
        [
            118,
            127,
            126
        ],
        [
            73
        ],
        [
            70,
            76,
            69,
            68
        ],
        [
            68
        ],
        [
            68
        ],
        [],
        [],
        [],
        [
            125,
            123
        ],
        [
            118,
            117
        ],
        [],
        [
            117
        ],
        [
            83,
            82,
            73
        ],
        [
            82
        ],
        [
            85,
            81
        ],
        [],
        [],
        [
            119
        ],
        [
            115
        ],
        [
            75,
            76
        ],
        [
            77
        ],
        [
            77
        ],
        [
            79
        ],
        [
            79
        ],
        [
            79
        ],
        [],
        [
            76
        ],
        [
            80
        ],
        [
            86
        ],
        [
            78,
            97
        ],
        [
            86
        ],
        [
            97
        ],
        [
            96
        ],
        [
            91,
            90,
            96
        ],
        [
            91,
            92,
            95,
            96
        ],
        [
            97,
            78
        ],
        [
            97
        ],
        [
            98
        ],
        [
            103,
            102,
            101,
            109
        ],
        [
            104
        ],
        [
            124
        ],
        [
            122
        ],
        [
            99
        ],
        [
            123,
            124
        ],
        [
            121
        ],
        [
            114
        ],
        [
            112
        ],
        [
            113
        ],
        [
            100
        ],
        [
            111
        ],
        [
            110
        ],
        [
            108
        ],
        [
            72,
            71,
            65
        ],
        [
            64,
            71,
            66
        ],
        [
            69,
            67,
            65
        ],
        [
            66
        ],
        [
            69
        ],
        [
            70,
            68,
            66
        ],
        [
            75,
            71,
            69
        ],
        [
            75,
            72,
            70,
            64,
            65
        ],
        [
            74,
            73,
            75,
            71,
            64
        ],
        [
            82,
            74,
            72
        ],
        [
            81,
            82,
            75,
            73,
            72
        ],
        [
            80,
            81,
            74,
            76,
            70,
            72,
            71
        ],
        [
            77,
            75
        ],
        [
            78,
            76,
            79
        ],
        [
            97,
            77,
            79
        ],
        [
            78,
            77
        ],
        [
            86,
            85,
            81,
            75
        ],
        [
            85,
            80,
            82,
            74,
            75
        ],
        [
            81,
            74,
            83,
            73
        ],
        [
            84,
            117,
            82
        ],
        [
            88,
            106,
            83,
            117
        ],
        [
            87,
            88,
            86,
            80,
            81
        ],
        [
            90,
            87,
            85,
            80
        ],
        [
            89,
            88,
            90,
            86,
            85
        ],
        [
            105,
            89,
            87,
            106,
            84,
            85
        ],
        [
            88,
            90,
            87
        ],
        [
            89,
            91,
            87,
            86
        ],
        [
            90,
            92,
            96
        ],
        [
            91,
            93,
            95
        ],
        [
            92,
            102,
            95,
            94,
            101
        ],
        [
            93,
            95,
            101,
            100,
            98
        ],
        [
            92,
            93,
            94,
            96,
            97,
            98
        ],
        [
            91,
            95,
            97
        ],
        [
            96,
            95,
            98,
            78
        ],
        [
            95,
            97,
            94,
            100,
            99
        ],
        [
            100,
            111,
            112,
            98,
            122
        ],
        [
            101,
            94,
            110,
            111,
            99,
            98
        ],
        [
            93,
            94,
            102,
            109,
            110,
            100
        ],
        [
            93,
            103,
            101
        ],
        [
            102,
            104,
            109
        ],
        [
            103,
            105,
            109,
            107,
            108
        ],
        [
            104,
            88,
            107,
            106
        ],
        [
            105,
            107,
            88,
            84,
            116,
            117
        ],
        [
            105,
            104,
            106,
            108,
            115,
            116
        ],
        [
            104,
            109,
            107,
            110,
            113,
            114,
            115
        ],
        [
            103,
            104,
            101,
            108,
            110
        ],
        [
            101,
            109,
            100,
            108,
            111,
            113
        ],
        [
            100,
            110,
            113,
            99,
            112
        ],
        [
            111,
            99,
            113,
            121,
            122,
            123
        ],
        [
            111,
            110,
            108,
            112,
            114,
            121
        ],
        [
            108,
            113,
            115,
            121,
            123,
            120
        ],
        [
            108,
            114,
            107,
            116,
            120,
            119,
            118
        ],
        [
            107,
            115,
            106,
            117,
            118
        ],
        [
            106,
            116,
            84,
            83,
            118
        ],
        [
            115,
            119,
            116,
            117,
            127
        ],
        [
            115,
            120,
            118,
            127,
            126
        ],
        [
            114,
            123,
            115,
            119,
            125,
            126
        ],
        [
            113,
            112,
            114,
            123
        ],
        [
            112,
            99,
            123,
            124
        ],
        [
            122,
            112,
            121,
            114,
            120,
            124,
            125
        ],
        [
            123,
            122
        ],
        [
            120,
            123,
            126
        ],
        [
            120,
            125,
            119,
            127
        ],
        [
            119,
            118,
            126
        ]
    ],
    "zoneOwner": [
        0,
        3,
        5,
        3,
        2,
        1,
        1,
        1,
        1,
        1,
        1,
        5,
        3,
        5,
        2,
        2,
        2,
        2,
        1,
        1,
        1,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        2,
        2,
        2,
        2,
        2,
        1,
        1,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "zoneOwnerPrev": [
        0,
        3,
        5,
        3,
        2,
        1,
        1,
        1,
        1,
        1,
        1,
        5,
        3,
        5,
        2,
        2,
        2,
        2,
        1,
        1,
        1,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        5,
        2,
        2,
        2,
        2,
        2,
        1,
        1,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "forceID": [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10
    ],
    "forceName": [
        "Troop",
        "Tank",
        "SAM Launcher",
        "Fighter",
        "Bomber",
        "Submarine",
        "Transport",
        "Carrier",
        "Destroyer",
        "Factory"
    ],
    "forceNamePlural": [
        "Troops",
        "Tanks",
        "SAM Launchers",
        "Fighters",
        "Bombers",
        "Submarines",
        "Transports",
        "Carriers",
        "Destroyers",
        "Factories"
    ],
    "forceDescription": [
        "Standard Troop",
        "Standard Tank",
        "Standard SAM Launcher",
        "Standard Fighter",
        "Standard Bomber",
        "Standard Submarine",
        "Standard Transport",
        "Standard Aircraft Carrier",
        "Standard Destroyer",
        "Standard Factory"
    ],
    "forceFlags": [
        198485,
        198485,
        235477,
        75501,
        75501,
        3162,
        2099034 - 64,
        1882,
        1882,
        260046849
    ],
    "forceCost": [
        10,
        15,
        15,
        30,
        35,
        20,
        20,
        35,
        25,
        45
    ],
    "forceRange": [
        1,
        2,
        1,
        4,
        6,
        2,
        2,
        2,
        2,
        0
    ],
    "forceAttack": [
        15,
        50,
        0,
        50,
        64,
        30,
        0,
        34,
        50,
        0
    ],
    "forceAttackMultiple": [
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1
    ],
    "forceDefense": [
        30,
        30,
        20,
        64,
        15,
        30,
        0,
        34,
        50,
        0
    ],
    "forceDefenseMultiple": [
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1
    ],
    "forceCargoCapacity": [
        0,
        0,
        0,
        0,
        0,
        0,
        2,
        0,
        0,
        0
    ],
    "forceCargoSpace": [
        1,
        1,
        1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1
    ],
    "forceCarrierCapacity": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        4,
        0,
        0
    ],
    "forceCarrierSpace": [
        -1,
        -1,
        -1,
        1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1
    ],
    "forceLiftCapacity": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "forceLiftSpace": [
        1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1
    ],
    "forceProduction": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        1
    ],
    "forceZ": [
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1
    ],
    "forceScale": [
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1
    ],
    "forceSfxMake": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "forceSfxMove": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "forceSfxDie": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "forceVersion": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "nextForceID": 204,
    "year": 1939,
    "replay": {},
    "created": 1728695409779,
    "started": "",
    "ended": "",
    "zoneBoxXSize": 16,
    "zoneBoxYSize": 16,
    "mapXScale": 1.75,
    "mapYScale": 1,
    "zoneBoxX": [
        0,
        12,
        68,
        112,
        152,
        192,
        232,
        276,
        308,
        344,
        396,
        456,
        488,
        20,
        120,
        152,
        180,
        204,
        228,
        292,
        364,
        492,
        504,
        502,
        5,
        36,
        52,
        296,
        328,
        448,
        456,
        116,
        144,
        152,
        180,
        200,
        228,
        256,
        97,
        104,
        136,
        176,
        160,
        192,
        176,
        168,
        199,
        212,
        240,
        272,
        388,
        442,
        360,
        344,
        316,
        400,
        391,
        416,
        360,
        397,
        320,
        352,
        388,
        433,
        36,
        80,
        136,
        200,
        160,
        128,
        104,
        84,
        68,
        36,
        60,
        76,
        116,
        148,
        184,
        208,
        80,
        68,
        36,
        5,
        8,
        72,
        124,
        84,
        36,
        92,
        136,
        184,
        224,
        284,
        284,
        248,
        200,
        236,
        288,
        324,
        328,
        344,
        340,
        415,
        448,
        484,
        500,
        472,
        436,
        408,
        392,
        356,
        368,
        400,
        420,
        452,
        484,
        500,
        476,
        452,
        424,
        392,
        360,
        404,
        376,
        416,
        448,
        463
    ],
    "zoneBoxY": [
        0,
        20,
        16,
        60,
        44,
        40,
        20,
        20,
        20,
        20,
        4,
        60,
        20,
        156,
        144,
        132,
        108,
        100,
        108,
        96,
        72,
        144,
        152,
        207,
        243,
        244,
        316,
        168,
        176,
        156,
        261,
        256,
        267,
        200,
        184,
        152,
        164,
        172,
        229,
        312,
        336,
        316,
        392,
        356,
        400,
        460,
        459,
        256,
        236,
        252,
        440,
        459,
        132,
        208,
        252,
        164,
        279,
        285,
        316,
        339,
        375,
        360,
        391,
        378,
        40,
        20,
        16,
        8,
        72,
        60,
        128,
        128,
        144,
        188,
        216,
        224,
        228,
        232,
        252,
        196,
        304,
        300,
        260,
        271,
        364,
        368,
        384,
        404,
        416,
        476,
        464,
        488,
        500,
        500,
        376,
        432,
        416,
        304,
        304,
        308,
        376,
        420,
        500,
        495,
        436,
        452,
        344,
        344,
        348,
        428,
        380,
        356,
        272,
        328,
        264,
        260,
        268,
        256,
        168,
        144,
        144,
        268,
        220,
        148,
        172,
        52,
        36,
        103
    ],
    "navyZone": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    "startingForces": [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        []
    ]
};
