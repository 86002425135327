/*
  BaseNotifyModal.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

import actions from '../../reducers/actions';
import { store } from '../../app/store';


export default class BaseNotifyModal extends React.Component {

  state = {
    errorModals: []
  };

  constructor(props) {
    super(props);
    this.state.notifyModals = props.notifyModals;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.notifyModals !== this.state.notifyModals) {
      this.setState({
        notifyModals: nextProps.notifyModals,
      });
    }
  }

  okClose = () => {
    store.dispatch({ type: actions.NOTIFY_MODAL_POP });
  };

  render() {
    if(this.state.notifyModals.length === 0) {
      return null;
    }
    const message = this.state.notifyModals[0];

    return (
      <Modal
          closeOnDimmerClick={ false }
          closeOnEscape={ false }
          dimmer='inverted'
          open={ true }
          size='small'>
        <Modal.Content>
          <Modal.Description>
            <p>
              <b>
                { message.title }
              </b>
            </p>
            { message.body &&
              <p>{ message.body }</p>
            }
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={ this.okClose }
            positive
            labelPosition='right'
            icon='checkmark'
            content='OK'
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
