/*
  GuiZoneSingle.tsx
  (c) Human Cube Inc.
*/

import { useState } from 'react';
import { BiSolidFactory } from 'react-icons/bi';
import { FaUndoAlt } from 'react-icons/fa';
import { FaParachuteBox, FaPeopleRoof, FaRoad, FaShareFromSquare } from 'react-icons/fa6';
import { LuPlaneLanding } from 'react-icons/lu';
import { Flag, FlagNameValues } from 'semantic-ui-react';

import actions from '../../reducers/actions';
import mainModes from '../../reducers/mainModes';
import { store } from '../../app/store';
import { ForceDetailsForZone } from '../../helper/forceDetailsForZone';
import GuiForce from './GuiForce';
import BF from '../../bfcore/bfconst1';
import bfH from '../../bfcore/bf_helper';
import { sendPacket } from '../../network/network';
import { localSoloGameCalculate } from '../../solo/localSoloGame';
import { Game } from '../../bfcore/gameTypes';
import { WorkingData } from '../../bfworking/workingTypes';
import { NumberNumberDictionary } from '../../helper/types';
import GuiTile from './GuiTile';
import GuiButton from './GuiButton';
import GuiLoadLiftersWindow from './GuiLoadLiftersWindow';
import GuiUnloadLiftersWindow from './GuiUnloadLiftersWindow';
import GuiCircleNumber from './GuiCircleNumber';
import ForcesGroup from './GuiForcesGroup';



function forceInfoFromForceID (game: Game, forceID: number):
    {id: number, owner: number, ft: number, z: number, rsz: number} | null {
  // Returns null if not found, or dictionary of info for the given force.
  for(let p = 0; p <= game.players; p++) {
    if(Array.isArray(game.force[p])) {
      for(let f = 0; f < game.force[p].length; f++) {
        const playerForce = game.force[p][f];
        if(Array.isArray(playerForce) && playerForce[BF.FORCE_INDEX_ID] === forceID) {
          return {
            id: forceID,
            owner: p,
            ft: playerForce[BF.FORCE_INDEX_TYPE],
            z: playerForce[BF.FORCE_INDEX_ZONE],
            rsz: playerForce[BF.FORCE_INDEX_ZONE_PREV],
          };
        }
      }
    }
  }
  return null;
}

interface GuiZoneSingleProps {
  details: ForceDetailsForZone;
  game: Game;
  localPlayerIndex: number;
  mainMode: number;
  replayStage: number;
  working: WorkingData;
  zoneID: number;
};

export default function GuiZoneSingle (props: GuiZoneSingleProps) {
  const { details, game, localPlayerIndex, mainMode, replayStage, working, zoneID } = props;

  const [showLoadLiftersWindow, setShowLoadLiftersWindow] = useState(false);
  const [showUnloadLiftersWindow, setShowUnloadLiftersWindow] = useState(false);

  const zoneOwner = 
    (replayStage >= BF.REPLAY_STAGE_ADJUST && replayStage < BF.REPLAY_STAGE_ZONE_CHANGE)
    ? game.zoneOwnerPrev[zoneID]
    : game.zoneOwner[zoneID];

  if(showLoadLiftersWindow) {
    return (
      <GuiLoadLiftersWindow
         game={game} details={details} zoneID={zoneID} localPlayerIndex={localPlayerIndex}
         hideWindow={ ()=>{ setShowLoadLiftersWindow(false); }}
      />
    );
  }
  if(showUnloadLiftersWindow) {
    return (
      <GuiUnloadLiftersWindow
         game={game} details={details} zoneID={zoneID} localPlayerIndex={localPlayerIndex}
         hideWindow={ ()=>{ setShowUnloadLiftersWindow(false); }}
      />
    );
  }

  const isZonePickTurn = (game.turn >= BF.TURN_PICK_ZONE && game.turn < BF.TURN_PICK_CAPITAL);
  const isCapitalPickTurn = (game.turn === BF.TURN_PICK_CAPITAL);

  function showForcePurchaseWindow () {
    store.dispatch({
      type: actions.SHOW_FORCE_PURCHASE_WINDOW,
    });
  }

  function undoForceMovements () {
    store.dispatch({
      type: actions.UNDO_ZONE_FORCE_MOVEMENTS,
      game,
      playerIndex: localPlayerIndex,
      zoneID,
    });
  }

  function showForceRetreats () {
    store.dispatch({
      type: actions.SHOW_FORCE_RETREAT_WINDOW,
    });
  }

  function selectZonePickOrCapitalPick () {
    store.dispatch({type: actions.SET_MAIN_MODE, mode: mainModes.GAME_SUBMITTING_TURN});
    const playersTurn = { pick: zoneID };
    if(game.flags & BF.GAME_FLAG_CLIENT_CALCULATED) {
      // Game advancement is calculated on the client.
      const { uid } = store.getState().userSession;
      localSoloGameCalculate(game, uid, localPlayerIndex, playersTurn);
      return;
    }
    // else send to server for calculation:
    sendPacket('bf_submitTurn', {gameID: game._id, turn: game.turn, data: playersTurn});
  }

  function landingList () {
    store.dispatch({
      type: actions.SHOW_LANDING_LIST_WINDOW,
    });
  }

  function showLanding () {
    store.dispatch({
      type: actions.SHOW_LANDING_HERE_WINDOW,
    });
  }

  const currentForces = [];
  for(let i = 0; i < details.playersWithForces.length; i++) {
    const playerIndex = details.playersWithForces[i];

    if(!playerIndex || playerIndex === zoneOwner) {
      // Display neutral or zone owner forces at the top.
      currentForces.unshift(
        <ForcesGroup
          key={ 'fg' + playerIndex } playerIndex={playerIndex} game={game}
          counts={details.forcesTotal[playerIndex]}
          transportsByCargoSimple={details.transportsByCargoSimple}
          liftersByLiftsSimple={details.liftersByLiftsSimple}
        />
      );
    }
    else {
      currentForces.push(
        <ForcesGroup
          key={ 'fg' + playerIndex } playerIndex={playerIndex} game={game}
          counts={details.forcesTotal[playerIndex]}
          transportsByCargoSimple={details.transportsByCargoSimple}
          liftersByLiftsSimple={details.liftersByLiftsSimple}
        />
      );
    }
  }

  let incomeRender = null;
  if((game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE) || game.zoneIncome[zoneID] > 0) {
    incomeRender =
      <div className='flex flex-row items-center'>
        <div>Income:&nbsp;&nbsp;</div>
        <GuiCircleNumber value={game.zoneIncome[zoneID]} />
      </div>;
  }

  let ownerRender = null;
  if(game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE) {
    ownerRender =
      <div>
        Owner:&nbsp;&nbsp;<Flag name={bfH.playerFlag(game, zoneOwner) as FlagNameValues} />
        {bfH.playerName(game, zoneOwner)}
      </div>;
  }

  const purchasesHere = [];
  let purchaseButton = null;
  let undoButton = null;
  let retreatButton = null;
  let moveableText = null;
  let blockedText = null;

  if(localPlayerIndex > 0) {
    for(const forceTypeIndex in details.buy) {
      const fti = parseInt(forceTypeIndex, 10);
      if(details.buy[fti]) {
        purchasesHere.push(
          <span key={ 'buy' + fti }>
            <GuiForce forceType={ game.forceID[fti] } />
            {details.buy[fti]}
          </span>
        );
      }
    }

    if(isZonePickTurn) {
      if(game.zoneOwner[zoneID] === 0 &&
          (game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE)) {
        purchaseButton =
          <div style={{marginBottom: '6px'}}>
            <GuiButton key='purchaseButton' height={48} onClick={ selectZonePickOrCapitalPick }>
              Pick This<br />Zone to Own
            </GuiButton>
          </div>;
      }
    }
    else if(isCapitalPickTurn) {
      if(game.zoneOwner[zoneID] === localPlayerIndex &&
          (game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE)) {
        purchaseButton =
          <div style={{marginBottom: '6px'}}>
            <GuiButton key='purchaseButton' height={48} onClick={ selectZonePickOrCapitalPick }>
              Select this<br />Zone as Capital
            </GuiButton>
          </div>;
      }
    }
    else if(game.zoneOwner[zoneID] === localPlayerIndex) {
      purchaseButton =
        <div style={{marginBottom: '6px'}}>
          <GuiButton key='purchaseButton' onClick={ showForcePurchaseWindow }
                     height={ purchasesHere.length ? 48 : 32 }>
            <BiSolidFactory />
            <div>
              {
              purchasesHere.length ?
                <div className='flex flex-row' style={{ marginTop: 2, paddingTop: 2 }}>
                  { purchasesHere }
                </div>
                : (details.hasFactory ? 'Build New Forces Here' : 'Build Factory Here')
              }
            </div>
          </GuiButton>
        </div>;
    }
    else if(game.zoneFlags[zoneID] & BF.ZONE_FLAG_WATER_ZONE) {
      if(details.hasFactory) {  // this actually means a neighboring factory in this case.
        purchaseButton =
          <div style={{marginBottom: '6px'}}>
            <GuiButton key='purchaseButton' onClick={ showForcePurchaseWindow }
                       height={ purchasesHere.length ? 48 : 32 }>
              <BiSolidFactory />
              <div>{ purchasesHere.length ? 
                <div className='flex flex-row' style={{ marginTop: 2, paddingTop: 2 }}>
                  { purchasesHere }
                </div>
                : 'Build New Forces Here' }</div>
            </GuiButton>
          </div>;
      }
    }


    if(game.turn >= BF.TURN_NORMAL && details.forcesUndoable > 0) {
      undoButton =
        <div style={{ marginBottom: '6px' }}>
          <GuiButton key='undoButton' onClick={ undoForceMovements }>
            <FaUndoAlt /><div>Undo All Forces Moved Here</div>
          </GuiButton>
        </div>;
    }

    if(game.turn >= BF.TURN_NORMAL && details.retreatableCount[localPlayerIndex] > 0) {
      retreatButton =
        <div style={{ marginBottom: '6px' }}>
          <GuiButton key='retreatButton' onClick={ showForceRetreats }>
            <FaShareFromSquare /><div>Retreat Forces</div>
          </GuiButton>
        </div>;
    }

    if(game.turn >= BF.TURN_NORMAL) {
      if(details.moveableCount[localPlayerIndex] > 0) {
        moveableText =
          <div>
            <br />
            <b>
              To move forces, select a neighboring<br />
              destination zone.
            </b>
          </div>;
      }
      else if(details.forceCount[localPlayerIndex] > 0) {
        if(details.hasCargo) {
          moveableText =
            <div>
              <br />
              <b>
                None of your forces in this zone can move<br />
                any further. To unload transports, select<br />
                a neighboring land zone.
              </b>
            </div>;
        }
        else {
          moveableText =
            <div>
              <br />
              <b>
                None of your forces in this zone can move<br />
                any further.
              </b>
            </div>;
        }
      }
      else {
        moveableText =
          <div>
            <br />
            <b>
              You do not have any forces here.
            </b>
          </div>;
      }

      if(details.moveableCount[localPlayerIndex] > 0 || details.forceCount[localPlayerIndex] > 0) {
        if(details.blockedAirCount && details.blockedSurfaceCount) {
          blockedText =
            <div style={{ color: 'red' }}>
              <br />
              <b>
                There are opposing forces in this zone that<br />
                might be blocking both air and surface movement.
              </b>
            </div>;
        }
        else if(details.blockedAirCount) {
          blockedText =
            <div style={{ color: 'red' }}>
              <br />
              <b>
                There are opposing forces in this zone that<br />
                might be blocking air movement.
              </b>
            </div>;
        }
        else if(details.blockedSurfaceCount) {
          blockedText =
            <div style={{ color: 'red' }}>
              <br />
              <b>
                There are opposing forces in this zone that<br />
                might be blocking surface movement.
              </b>
            </div>;
        }
      }
    }
  }

  let showCasualties = false;
  const casualties = [];
  if(
    !isZonePickTurn &&
    !isCapitalPickTurn &&
    mainMode === mainModes.GAME_REPLAY &&
    replayStage === BF.REPLAY_STAGE_DESTROY
  ) {
    showCasualties = true;

    // Keyed by playerIndex and then an object for each with key forceType:
    const hit: NumberNumberDictionary[] = [];
    for(let p = 0; p <= game.players; p++) {
      hit[p] = {};
    }

    if(Array.isArray(working.zoneHits[zoneID])) {
      for(let i = 0; i < working.zoneHits[zoneID].length; i++) {
        const forceID = working.zoneHits[zoneID][i];
        if(forceID) {
          const forceInfo = forceInfoFromForceID(game, forceID);
          if(forceInfo) {
            const key = forceInfo.ft;
            if(!hit[forceInfo.owner][key]) {
              hit[forceInfo.owner][key] = 0;
            }
            hit[forceInfo.owner][key]++;
          }
        }
      }
    }

    for(let p = 0; p <= game.players; p++) {
      const items = [];
      for (var key in hit[p]) {
        const count = hit[p][key];
        const part = key.split(':');
        const forceType = parseInt(part[0], 10);
        items.push(
          <span key={ p + 'cf:' + key }>
            <GuiForce forceType={ game.forceID[forceType] } />
            <b>&nbsp;{count}</b>
          </span>
        );
      }
      if(items.length) {
        const line =
          <div key={ 'ccf' + p } className='flex flex-row'>
            <div className='mt-1'>
              <Flag name={game.playerFlag[p] as FlagNameValues} />
            </div>
            { items }
          </div>;
        casualties.push(line);
      }
    }

    if(casualties.length === 0) {
      casualties.push(<p key='isNeeded'>No casualties in this zone.</p>);
    }
  }

  return (
    <GuiTile edge='left' maxWidth={512}>
        <center>
          <div className='text-xl'>{ game.zoneName[zoneID] }</div>
        </center>
        { ownerRender }
        { incomeRender }
        <br />
        <div>{ currentForces }</div>
        { purchaseButton }
        { (localPlayerIndex >= 0 && game.turn >= BF.TURN_NORMAL && details.landingCount > 0) &&
          <div style={{ marginBottom: '6px' }}>
            <GuiButton key='landingCountButton' onClick={ showLanding }>
              <FaRoad /><div>{ details.landingCount } Aircraft
              Landing{ details.landingCount > 1 ? 's' : '' } Here</div>
            </GuiButton>
          </div>
        }
        { (localPlayerIndex >= 0 && game.turn >= BF.TURN_NORMAL && details.aircraftCount > 0) &&
          <div style={{ marginBottom: '6px' }}>
            <GuiButton key='landAircraftButton' onClick={ landingList }>
              <LuPlaneLanding /><div>Landings For Aircraft Here</div>
            </GuiButton>
          </div>
        }
        { (localPlayerIndex >= 0 &&
            details.zoneLifters > 0 &&
            (game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE)) &&
          <div style={{ marginBottom: '6px' }}>
            <GuiButton key='ParatroopersLoad' onClick={ () => { setShowLoadLiftersWindow(true); } }>
              <FaPeopleRoof /><div>Load Paratroopers</div>
            </GuiButton>
          </div>
        }
        { (localPlayerIndex >= 0 &&
            details.hasLift &&
            (game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE)) &&
          <div style={{ marginBottom: '6px' }}>
            <GuiButton key='ParatroopersUnload' onClick={ () => { setShowUnloadLiftersWindow(true); } }>
              <FaParachuteBox /><div>Unload Paratroopers</div>
            </GuiButton>
          </div>
        }
        { undoButton }
        { retreatButton }
        { moveableText }
        { showCasualties && <div><p><b><br />Casualties</b></p>{ casualties }</div> }
        { blockedText }
    </GuiTile>
  );
}
