/*
  packetBFGet.js
  (c) 2013-present Human Cube Inc.
*/

import { store } from '../app/store';
import actions from '../reducers/actions';
import mainModes from '../reducers/mainModes';
import { loadHitmapIntoCache } from '../map/hitmapCache';
import { chatFetchMoreIfNeeded } from '../helper/chat';
import { gameEnhanceData, parseGameCore, sendSawFinishGameIfNeeded } from '../helper/game';
import { decodeGameString } from '../bfcore/gameStringDecodeEncode';
import { localUID } from '../helper/user';
import { log } from '../helper/log';

export function bfGetResPacket(data) {
  // log('bf_getRes: ' + data.e);
  // log(data);

  if(data.e === 'blockAdd') {
    if(data.result === 'success' && data.block) {
      store.dispatch({type: actions.ADD_BLOCK_ITEM, item: data.block});
      store.dispatch({type: actions.REMOVE_FRIEND_FOE_ITEM, item: data.block});
    }
  }
  else if(data.e === 'blockRemove') {
    if(data.result === 'success' && data.block) {
      store.dispatch({type: actions.REMOVE_BLOCK_ITEM, item: data.block});
    }
  }
  else if(data.e === 'blocks') {
    if(data.result === 'success' && Array.isArray(data.data)) {
      if(Array.isArray(data.meta)) {
        store.dispatch({type: actions.ADD_PLAYER_STRINGS, data: data.meta});
      }
      if(Array.isArray(data.data)) {
        store.dispatch({type: actions.SET_BLOCK_LIST, data: data.data});
      }
    }
  }

  else if(data.e === 'chatGetPlayer') {
    if(data.result === 'success' && data.gameID &&
        Array.isArray(data.text) && data.text.length > 0) {
      const gameID = data.gameID;
      const playerIndex = data.playerIndex;
      store.dispatch({type: actions.CHAT_ADD, gameID, playerIndex, data: data.text});
    }
  }

  else if(data.e === 'chatGetPublic') {
    if(data.result === 'success' && data.gameID &&
        Array.isArray(data.text) && data.text.length > 0) {
      const gameID = data.gameID;
      store.dispatch({type: actions.CHAT_ADD, gameID, playerIndex: 0, data: data.text});
    }
  }

  else if(data.e === 'chatGetTeam') {
    if(data.result === 'success' && data.gameID &&
        Array.isArray(data.text) && data.text.length > 0) {
      const gameID = data.gameID;
      store.dispatch({type: actions.CHAT_ADD, gameID, playerIndex: 1, data: data.text});
    }
  }

  else if(data.e === 'chatMeta') {
    if(data.result === 'success' && data.gameID) {
      store.dispatch({type: actions.CHAT_META_ADD, gameID: data.gameID, data: data.meta});
      // Automatically fetch public chat for the given gameID if available:
      chatFetchMoreIfNeeded(data.gameID, 0, 0);
    }
  }

  else if(data.e === 'chatNew') {
    // New game chat available notification.
    let { gameID, mask, chatID } = data;

    const gameStatus = store.getState().gameStatus;
    if(gameStatus[gameID]) {
      const {playerID, teams} = gameStatus[gameID];
      const localUserID = store.getState().userSession.uid;
      let teamGame = false;
      if(teams >= 2) {
        teamGame = true;
      }

      let otherIndex = 0;
      if(!teamGame) {
        let playerIndex = 0;
        for(let i = 0; i < playerID.length; i++) {
          if(playerID[i] === localUserID) {
            playerIndex = i;
            break;
          }
        }
        if(playerIndex) {
          mask &= ~(1 << playerIndex);
          mask >>= 1;
          while(mask) {
            otherIndex++;
            mask >>= 1;
          }
        }
      }
      else {
        // Team game, so either 0 public or 1 team:
        if(mask) {
          otherIndex = 1;
        }
      }

      store.dispatch({
        type: actions.CHAT_META_SET_SINGLE_CID,
        gameID,
        playerIndex: otherIndex,
        cid: chatID,
      });

      // If it is the active game, fetch the new chat message immediately:
      if(store.getState().gameID === gameID) {
        chatFetchMoreIfNeeded(gameID, teamGame, otherIndex);
      }
    }
  }

  else if(data.e === 'friendAdd') {
    if(data.result === 'success' && data.friend) {
      store.dispatch({type: actions.ADD_FRIEND_FOE_ITEM, item: data.friend});
      store.dispatch({type: actions.REMOVE_BLOCK_ITEM, item: data.friend});
    }
  }
  else if(data.e === 'friendRemove') {
    if(data.result === 'success' && data.friend) {
      store.dispatch({type: actions.REMOVE_FRIEND_FOE_ITEM, item: data.friend});
    }
  }
  else if(data.e === 'friends') {
    if(data.result === 'success' && Array.isArray(data.data)) {
      if(Array.isArray(data.meta)) {
        store.dispatch({type: actions.ADD_PLAYER_STRINGS, data: data.meta});
      }
      if(Array.isArray(data.data)) {
        // First remove self from list just incase it is there.
        const localUserID = store.getState().userSession.uid;
        const cleanData = data.data.filter(e => e !== localUserID);
        store.dispatch({type: actions.SET_FRIEND_FOE_LIST, data: cleanData});
      }
    }
  }

  else if(data.e === 'gameCore') {
    const { mainMode } = store.getState();
    if(data.result === 'success') {
      let gameData = null;
      if(data.core) {
        gameData = parseGameCore(data.core);
      }
      else {
        gameData = parseGameCore(decodeURI(data.coreEncoded));
      }

      if(!gameData) {
        log('Failed to parse game core.');
        if(mainMode === mainModes.GAME_LOADING) {
          store.dispatch({type: actions.SET_MAIN_MODE, mode: mainModes.GAME_LOADING_ERROR});
        }
        return;
      }
      loadHitmapIntoCache(gameData.mapID,
                          gameData.mapFlags, gameData.mapXS, gameData.mapYS, gameData.mapData,
                          function (err) {
        if(err) {
          log('Hitmap loading for map failed.');
          if(mainMode === mainModes.GAME_LOADING) {
            store.dispatch({type: actions.SET_MAIN_MODE, mode: mainModes.GAME_LOADING_ERROR});
          }
          return;
        }
        gameEnhanceData(gameData);


        console.log('gameData', gameData);


        let localPlayerIndex = 0;
        const { playFor } = store.getState();
        if(playFor.uid) {
          // Playing for a teammate.
          for(let p = 0; p <= gameData.players; p++) {
            if(gameData.playerID[p] === playFor.uid) {
              localPlayerIndex = p;
              break;
            }
          }
        }
        else {
          // Playing as themself.
          const localUserID = store.getState().userSession.uid;
          for(let p = 0; p <= gameData.players; p++) {
            if(gameData.playerID[p] === localUserID) {
              localPlayerIndex = p;
              break;
            }
          }
          if(mainMode === mainModes.GAME_DETAIL ||
              mainMode === mainModes.GAME_LOADING ||
              mainMode === mainModes.GAME_REPLAY ||
              mainMode === mainModes.GAME_DOING_TURN ||
              mainMode === mainModes.GAME_SUBMITTING_TURN) {
            // Determine if player is in the game and eliminated:
            let localPlayerIsEliminated = false;
            if(localPlayerIndex > 0 && Array.isArray(gameData.finished) &&
                gameData.finished[localPlayerIndex][0]) {
              localPlayerIsEliminated = true;
            }
            sendSawFinishGameIfNeeded(gameData._id, gameData.state, localPlayerIsEliminated);
          }
        }
        store.dispatch({type: actions.SET_GAME, game: gameData, localPlayerIndex});
      });
      return;
    }
    log('Unable to fetch gameCore. (' + data.result + ')');
    store.dispatch({
      type: actions.NOTIFY_MODAL_PUSH,
      title: 'Error Loading Game',
      body: 'Unable to fetch gameCore. (' + data.result + ')',
    });
    if(store.getState().mainMode === mainModes.GAME_LOADING) {
      store.dispatch({type: actions.SET_MAIN_MODE, mode: mainModes.GAME_LOADING_ERROR});
    }
  }

  else if(data.e === 'gameState') {
    // Game state change notification.

    // gameState Packet:
    //   {e: "gameState"
    //   gameID: 408115722
    //   noWait: 0
    //   required: 14
    //   seconds: 693052
    //   state: 5
    //   submitted: 0
    //   timeLimit: 1568087898050
    //   turn: 400000002}

    // Update portion of game status for this game:
    const partialGameStatus = {
      _id: data.gameID,
      noWait: data.noWait,
      required: data.required,
      timeLimit: data.timeLimit,
      state: data.state,
      submitted: data.submitted,
      turn: data.turn,
    };
    store.dispatch({type: actions.UPDATE_GAME_STATUS, data: partialGameStatus});
  }

  else if(data.e === 'gameString') {
    if(data.result === 'success' && Array.isArray(data.data)) {
      const items = [];
      for(let i = 0; i < data.data.length; i++) {
        const item = decodeGameString(data.data[i]);
        if(item && item._id) {
          items.push(item);
        }
      }
      if(items.length) {
        store.dispatch({type: actions.SET_GAME_STATUS, data: items});
      }
    }
  }

  else if(data.e === 'leaderboard') {
    if(data.result === 'success') {
      const key = data.key.split(':');
      if(Array.isArray(data.meta)) {
        store.dispatch({type: actions.ADD_PLAYER_STRINGS, data: data.meta});
      }
      const dataParsed = [];
      for(let i = 0; i < data.data.length; i++) {
        dataParsed.push(parseInt(data.data[i], 10));
      }
      if(key[0] === 'bfz_score') {
        store.dispatch({type: actions.SET_LEADERBOARD_SCORE, data: dataParsed});
      }
      else if(key[0] === 'bfz_xp') {
        store.dispatch({type: actions.SET_LEADERBOARD_XP, data: dataParsed});
      }
      else if(key[0] === 'bfz_mapPlays') {
        const mapID = parseInt(key[1]);
        if(store.getState().mapID === mapID) {
          store.dispatch({type: actions.SET_LEADERBOARD_MAP_PLAYS, mapID, data: dataParsed});
        }
      }
      else if(key[0] === 'bfz_scenarioPlays') {
        const scenarioID = parseInt(key[1]);
        if(store.getState().scenarioID === scenarioID) {
          store.dispatch({type: actions.SET_LEADERBOARD_SCENARIO_PLAYS,
                          scenarioID, data: dataParsed});
        }
      }
    }
  }

  else if(data.e === 'membershipChange') {
    if(localUID() === data.uid) {
      store.dispatch({
        type: actions.MEMBERSHIP_CHANGE,
        rank: data.rank,
        membershipLevel: data.membershipLevel,
        membershipExpire: data.membershipExpire,
      });
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Membership Updated',
        body: '',
      });
    }
  }
  else if(data.e === 'news') {
    if(Array.isArray(data.data)) {
      store.dispatch({type: actions.NEWS_ADD, data: data.data});
    }
  }
  else if(data.e === 'newsFetch') {
    if(data.result === 'success') {
      store.dispatch({type: actions.NEWS_ADD, data: data.data});
    }
  }
  else if(data.e === 'playerInfo') {
    if(data.result === 'success') {
      store.dispatch({type: actions.ADD_PLAYER_INFOS, data: [data.data]});
    }
  }
  else if(data.e === 'playerStats') {
    if(data.result === 'success') {
      const dataParsed = [];
      for(let i = 0; i < data.data.length; i++) {
        if(data.data[i] === null) {
          dataParsed.push(null);
        }
        else {
          dataParsed.push(parseInt(data.data[i], 10));
        }
      }
      store.dispatch({type: actions.ADD_PLAYER_STAT, uid: data.uid, data: dataParsed});
    }
  }
  else if(data.e === 'scoreChange') {
    if(localUID() === data.uid) {
      store.dispatch({
        type: actions.USER_SESSION_UPDATE_SOME_BF,
        score: data.score,
        xp: data.xp,
        rank: data.rank,
      });
    }
  }

}
