/*
  FlagIcon.js
  (c) Human Cube Inc.
*/

import { Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";


const centerAnchor = new PIXI.Point(0.5, 0.5);

export default function FlagIcon (props) {
  const texture = process.env.PUBLIC_URL + '/img/flagicon/' + props.cc + '.png';
  const coreProps = (({ cc, ...o }) => o)(props);  // Exclude cc field.
  return (
    <Sprite
      anchor={ centerAnchor }
      texture={ PIXI.Texture.from(texture) }
      { ...coreProps }
    />
  );
}
