/*
  BaseJoinMulti.js
  (c) Human Cube Inc.
*/

import { useNavigate } from 'react-router-dom';
import { Message, Table } from 'semantic-ui-react';

import BaseAdSense from './BaseAdSense';
import BaseBackScroll from './BaseBackScroll';
import BaseMapIcon from './BaseMapIcon';
import BaseMenuBasic from './BaseMenuBasic';
import background from './img/background/airforce77911.jpg';


/*
TODO: use more of this data to display nicer joingametiles:
TODO: can calculate 0 of 4 registered etc.

created: '2018-02-27T00:05:26.305Z'
flags: 3137
id: 790549814
mapID: 1
name: 'World Powers'
playerID: (5) [0, 0, 0, 0, 0]
playerName: (5) ['', '', '', '', '']
players: 4
scenarioID: 10014
uName: 'GameAdmin'
uid: 2
*/

function JoinGameTableRow ({gameID, gameData, membership}) {

  const navigate = useNavigate();

  if(!gameData || !gameData.name) {
    return null;
  }

  let registered = 0;
  for(let p = 1; p <= gameData.players; p++) {
    if(gameData.playerID[p]) {
      registered++;
    }
  }

  return (
    <Table.Row
      key={gameID}
      style={{ cursor: 'pointer' }}
      onPointerUp={() => { navigate('/game/' + gameID); }}
    >
      <Table.Cell>
        <BaseMapIcon mapID={ gameData.mapID } />
      </Table.Cell>
      <Table.Cell><u>{ gameID }</u></Table.Cell>
      <Table.Cell>{ gameData.name }</Table.Cell>
      <Table.Cell textAlign='center'>{ registered + ' of ' + gameData.players }</Table.Cell>
      <Table.Cell textAlign='center'>{ gameData.players - registered }</Table.Cell>
      <Table.Cell>{ gameData.uName }</Table.Cell>
      <Table.Cell>{ membership }</Table.Cell>
    </Table.Row>
  );
}


export default function BaseJoinMulti ({store}) {

  const { registeringGames } = store;

  const gameTiles = [];

  for(let i = 0; i < registeringGames.standard.basic.length; i++) {
    const gameID = registeringGames.standard.basic[i].id;
    gameTiles.push(
      <JoinGameTableRow
        key={ gameID }
        gameID={ gameID }
        gameData={ registeringGames.standard.basic[i] }
        membership='Anyone'
      />
    );
  }
  for(let i = 0; i < registeringGames.standard.premium.length; i++) {
    const gameID = registeringGames.standard.premium[i].id;
    gameTiles.push(
      <JoinGameTableRow
        key={ gameID }
        gameID={ gameID }
        gameData={ registeringGames.standard.premium[i] }
        membership='Premium or Extreme'
      />
    );
  }
  for(let i = 0; i < registeringGames.standard.extreme.length; i++) {
    const gameID = registeringGames.standard.extreme[i].id;
    gameTiles.push(
      <JoinGameTableRow
        key={ gameID }
        gameID={ gameID }
        gameData={ registeringGames.standard.extreme[i] }
        membership='Extreme Only'
      />
    );
  }

  for(let i = 0; i < registeringGames.user.basic.length; i++) {
    const gameID = registeringGames.user.basic[i].id;
    gameTiles.push(
      <JoinGameTableRow
        key={ gameID }
        gameID={ gameID }
        gameData={ registeringGames.user.basic[i] }
        membership='Anyone'
      />
    );
  }
  for(let i = 0; i < registeringGames.user.premium.length; i++) {
    const gameID = registeringGames.user.premium[i].id;
    gameTiles.push(
      <JoinGameTableRow
        key={ gameID }
        gameID={ gameID }
        gameData={ registeringGames.user.premium[i] }
        membership='Premium or Extreme'
      />
    );
  }
  for(let i = 0; i < registeringGames.user.extreme.length; i++) {
    const gameID = registeringGames.user.extreme[i].id;
    gameTiles.push(
      <JoinGameTableRow
        key={ gameID }
        gameID={ gameID }
        gameData={ registeringGames.user.extreme[i] }
        membership='Premium Only'
      />
    );
  }

  return (
    <BaseBackScroll background={ background } >
      <BaseMenuBasic pageName='Multiplayer Games Available to Join' />
      <center>
        <br /><br /><br />
        <BaseAdSense />
        <br />
        <Message compact style={{ opacity: 0.9 }}>
          <p>
            Available multiplayer games to join are listed below.<br />
            Once the open slots for a game have been filled, it will start.<br />
            Tap any one to view futher details.<br />
          </p>
        </Message>
        <Table collapsing striped selectable style={{ opacity: 0.9 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign='center'>
                Map
              </Table.HeaderCell>
              <Table.HeaderCell>
                Game ID
              </Table.HeaderCell>
              <Table.HeaderCell>
                Game Name
              </Table.HeaderCell>
              <Table.HeaderCell>
                Players
              </Table.HeaderCell>
              <Table.HeaderCell>
                Open Slots
              </Table.HeaderCell>
              <Table.HeaderCell>
                Started By
              </Table.HeaderCell>
              <Table.HeaderCell>
                Membership
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { gameTiles }
          </Table.Body>
        </Table>
        <br /><br /><br />
        <br /><br /><br />
      </center>
    </BaseBackScroll>
  );
}
