/*
  BaseGameLoading.js
  (c) Human Cube Inc.
*/

import BaseMenuBasic from './BaseMenuBasic';


export default function BaseGameLoading () {
  return (
    <BaseMenuBasic pageName='Loading Game...' />
  );
}
