/*
  BasePlayerTile.js
  (c) Human Cube Inc.
*/

import { Link } from 'react-router-dom';
import { Flag } from 'semantic-ui-react';

import BaseRank from './BaseRank';


export default function BasePlayerTile ({uid, value, playerString}) {

  if(uid && playerString) {
    const d = playerString.split('|');
    const uName = d[1];
    const rank = parseInt(d[2], 36);
    // const membershipLevel = parseInt(d[3], 36);
    // const score = parseInt(d[4], 36);
    // const xp = parseInt(d[5], 36);
    // const plays = parseInt(d[6], 36);
    let cc = '';
    if(d[7]) {
      cc = d[7];
    }

    return (
      <Link to={'/player/' + uid}>
        <div
          className='flex flex-row bg-slate-300'
          style={{ height: 32, borderColor: 'red', borderWidth: 1 }}
        >
          <BaseRank rank={rank} />
          <div className='flex flex-row mt-1'>
            <div className='m-1'>
              <Flag name={cc} />
            </div>
            <div className='mt-1 font-semibold'>
              {uName}
            </div>
          </div>
          {value}
        </div>
      </Link>
    );
  }

  return (
    <Link to={'/player/' + uid}>
      <div>Player<br />{'#' + uid}</div>
    </Link>
  );
}
