/*
  hitmapCache.js
  Async loading of hitmap data, for later use by bf_hitmap.
*/

import BF from '../bfcore/bfconst1';
import { setHitmapBitmapGetterFunction } from '../bfcore/bf_hitmap';

import hitmap1 from './hitmap/hitmap_1_512.raw';
import hitmap3 from './hitmap/hitmap_3_512.raw';
import hitmap4 from './hitmap/hitmap_4_512.raw';
import hitmap5 from './hitmap/hitmap_5_512.raw';
import hitmap6 from './hitmap/hitmap_6_512.raw';


const hitmapCache = [];

// Important initialization needed for bfcore hitmap support:
setHitmapBitmapGetterFunction(getHitmapFromCache);


function readFileAsByteArray (filename, callback) {
  // calls callback(byteArray (Uint8Array)) on success, callback(null) on error.

  const oReq = new XMLHttpRequest();
  oReq.open('GET', filename, true);
  oReq.responseType = 'arraybuffer';

  oReq.onerror = function () {
    callback(null);
  };

  oReq.onload = function (oEvent) {
    const arrayBuffer = oReq.response; // Note: not oReq.responseText
    if (arrayBuffer) {
      const byteArray = new Uint8Array(arrayBuffer);
      // for (var i = 0; i < byteArray.byteLength; i++) {
      //   // do something with each byte in the array
      // }
      callback(byteArray);
    }
  };

  oReq.send(null);
}

function parseHexMapIntoByteArray (mapFlags, mapXS, mapYS, mapData) {
  const HEX_SIZE = 8;
  const destXSize = mapXS * HEX_SIZE + Math.floor(HEX_SIZE/2);
  const destYSize = mapYS * HEX_SIZE;
  const bufferSize = destYSize * destXSize;
  const buffer = new Uint8Array(bufferSize);

  const xs = mapXS;
  const ys = mapYS;
  for(let y = 0; y < ys; y++) {
    for(let x = 0; x < xs; x++) {
      const p = y * xs + x;
      const c = parseInt(mapData.substr(p * 2, 2), 16);
      let xOffset = x * HEX_SIZE;
      if((y & 1) === 0) {
        xOffset += Math.floor(HEX_SIZE/2);
      }
      for(let yd = 0; yd < HEX_SIZE; yd++) {
        for(let xd = 0; xd < HEX_SIZE; xd++) {
          buffer[(y * HEX_SIZE + yd) * destXSize + xd + xOffset] = c;
        }
      }
    }
  }
  return buffer;
}

export function loadHitmapIntoCache (mapID, mapFlags, mapXS, mapYS, mapData, callback) {
  // calls callback(null) on success,
  // calls callback('error message') on error.

  if(hitmapCache[mapID]) {
    // Hitmap already loaded into cache.
    callback(null);  // Just return success.
    return;
  }

  if(mapFlags & BF.MAP_FLAG_RAWMAP) {
    callback(null);  // Just return success.
    return;
  }

  if(mapFlags & BF.MAP_FLAG_HEXMAP) {
    hitmapCache[mapID] = parseHexMapIntoByteArray(mapFlags, mapXS, mapYS, mapData);
    callback(null);
    return;
  }

  if(mapFlags & BF.MAP_FLAG_BITMAP) {
    let filename;
    switch(mapID) {
      case 1: filename = hitmap1; break;
      case 3: filename = hitmap3; break;
      case 4: filename = hitmap4; break;
      case 5: filename = hitmap5; break;
      case 6: filename = hitmap6; break;
      default:
        callback('error');
        return;
    }

    readFileAsByteArray(filename, function (data) {
      if(data) {
        hitmapCache[mapID] = data;
        callback(null);
        return;
      }
      callback('error');
    });

    return;
  }

  callback('error');
}

export function getHitmapFromCache (mapID) {
  if(mapID && hitmapCache[mapID]) {
    return hitmapCache[mapID];
  }
  return null;
}
