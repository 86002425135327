/*
  BaseNews.js
  (c) Human Cube Inc.
*/

import BaseAdSense from './BaseAdSense';
import BaseBackScroll from './BaseBackScroll';
import BaseMenuBasic from './BaseMenuBasic';
import BaseNewsItem from './BaseNewsItem';

import background from './img/background/radar-62991.jpg';


export default function BaseNews ({ news }) {
  const newsItems = [];
  for(let i = 0; i < news.length; i++) {
    newsItems.push(
      <BaseNewsItem key={ 'n' + i } newsItem={ news[i] } />
    );
  }

  return (
    <BaseBackScroll background={ background } >
      <BaseMenuBasic pageName='Player and Game News' />
      <br />
      <br />
      <br />
      <BaseAdSense />
      <br />
      <div style={{
          width: '580px',
          background: 'rgba(255, 255, 255, 0.8)',
          padding: '6px',
          border: '2px solid',
          textAlign: 'left',
        }} >
        <ul>
          { newsItems }
        </ul>
      </div>
      <br /><br /><br />
      <br /><br /><br />
    </BaseBackScroll>
  );
}
