import actions from './actions'

function twoHexDigits (n) {
	n &= 255;
  const hex = Number(n).toString(16).toUpperCase();
  if(hex.length < 2) {
    return '0' + hex;
  }
  return hex;
}

export function mapEdit (state = {}, action) {
  // mapEdit is a map object, but can be empty object.
  switch (action.type) {
    case actions.MAP_EDIT_SET_MAP:
      return action.mapEdit;

    case actions.MAP_EDIT_SAVED:
      // Update the _id of the mapEdit object so future saves will not create new entries.
      if(action.mapID) {
        return Object.assign({}, state, { _id: action.mapID });
      }
      return state;

    case actions.MAP_EDIT_ZONE_FLAGS:
      const zoneFlags = [...state.zoneFlags];
      zoneFlags[action.zoneID] = action.zoneFlags;
      return Object.assign({}, state, { zoneFlags });
    case actions.MAP_EDIT_ZONE_NAME:
      const zoneName = [...state.zoneName];
      zoneName[action.zoneID] = action.zoneName;
      return Object.assign({}, state, { zoneName });
    case actions.MAP_EDIT_PIXEL:
      const mapData = state.mapData.substring(0, action.offset * 2) +
                      twoHexDigits(action.zoneID) +
                      state.mapData.substring(action.offset * 2 + 2);
      return Object.assign({}, state, { mapData });
    case actions.MAP_EDIT_NAME:
      return Object.assign({}, state, { name: action.name });
    case actions.MAP_EDIT_DESCRIPTION:
      return Object.assign({}, state, { description: action.description });
    default:
      return state;
  }
}

export function mapEditSaved (state = true, action) {
  // Has the current map data been saved.
  switch (action.type) {
    case actions.MAP_EDIT_SAVED:
      return true;
    case actions.MAP_EDIT_SET_MAP:
      if(action.alreadySaved) {
        return true;
      }
      return false;
    case actions.MAP_EDIT_ZONE_FLAGS:
    case actions.MAP_EDIT_ZONE_NAME:
    case actions.MAP_EDIT_PIXEL:
    case actions.MAP_EDIT_NAME:
    case actions.MAP_EDIT_DESCRIPTION:
      return false;
    default:
      return state;
  }
}

export function mapEditZoom (state = 3, action) {
  switch (action.type) {
    case actions.MAP_EDIT_SET_MAP:
      return 3;
    case actions.MAP_EDIT_ZOOM_IN:
      return state + 1;
    case actions.MAP_EDIT_ZOOM_OUT:
      if(state > 1) {
        return state - 1;
      }
      return state;
    default:
      return state;
  }
}


export function mapEditSelectedZoneID (state = 0, action) {
	// Selected ZoneID in map edit mode. 0 for nothing selected.
  switch (action.type) {
    case actions.MAP_EDIT_SET_SELECTED_ZONE_ID:
      return action.zoneID;
    default:
      return state;
  }
}
