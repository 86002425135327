/*
  BaseLocalSoloGames.tsx
*/

import { Button, Icon } from 'semantic-ui-react';
import { useNavigate } from "react-router-dom";
import { useLiveQuery } from 'dexie-react-hooks';

import actions from '../../reducers/actions';
import { store } from '../../app/store';
import { soloGameDB, SoloGameDBEntry } from '../../solo/localSoloGameDB';
import { Game } from '../../bfcore/gameTypes';
import { decodeGameString, encodeGameString } from '../../bfcore/gameStringDecodeEncode';
import BaseMapIcon from './BaseMapIcon';
import { loadHitmapIntoCache } from '../../map/hitmapCache';


const MAX_SOLO_GAMES = 3;

export const LocalSoloGameList = (
  {uid, startNewButton = null} : {uid: number, startNewButton?: any}
) => {
  const navigate = useNavigate();

  const games = useLiveQuery(async () => {
    // Query the DB using our promise based API. Lazy fetches.
    return await soloGameDB.soloGames.where('uid').equals(uid).toArray();
  });

  if(!games || !Array.isArray(games)) {
    return null;
  }

  // Convert to array of Game[]:
  const soloGames = games.map((item: SoloGameDBEntry) => item.game);

  // Load any hitmaps if required, NOP if already loaded.
  // (Bit of a non-blocking race condition, but whatever):
  soloGames.forEach((game) => {
    loadHitmapIntoCache(game.mapID, game.mapFlags, game.mapXS, game.mapYS, game.mapData,
      (err: any) => {
        if(err) {
          console.log('Hitmap loading for map failed for SOLO Game.');
        }
      }
    );
  });

  return (
    <div
      className='flex items-center justify-center'
      style={{maxWidth: 476}}
    >
      <div className='flex flex-row flex-wrap justify-center pt-1'>
        { soloGames.map((game: Game) => {
          const { mapID } = game;
          let localPlayerIndex = 0;
          let turnRequiredFlag = false;
          for(let p = 1; p <= game.players; p++) {
            if(game.playerID[p] === uid) {
              localPlayerIndex = p;
              if(game.required & (1 << p)) {
                turnRequiredFlag = true;
              }
              break;
            }
          }
          return (
            <div
              key={'lsg' + game._id}
              style={{padding: 1}}
              onMouseUp={() => {
                store.dispatch({type: actions.SET_GAME, game, localPlayerIndex});
                const gameStatuses = [decodeGameString(encodeGameString(game))];
                store.dispatch({type: actions.SET_GAME_STATUS, data: gameStatuses});
                navigate('/game/' + game._id);
              }}
            >
              <Button color={ turnRequiredFlag ? 'green' : 'olive' } >
                <div className='flex flex-row items-center'>
                  <div>
                    <BaseMapIcon mapID={ mapID } />
                  </div>
                  <div className='pl-2'>
                    { game.name }<br />
                    { 'LOCAL #' + game._id }
                    { turnRequiredFlag ? <Icon color='red' name='exclamation' /> : null }
                  </div>
                </div>
              </Button>
            </div>
          );
        })}
        { (soloGames.length < MAX_SOLO_GAMES && startNewButton) && startNewButton }
      </div>
    </div>
  );
};
