/*
  BaseGameDetailPlayer.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { Link } from "react-router-dom";
import { Icon, Table } from 'semantic-ui-react';

import BF from '../../bfcore/bfconst1';
import { linkText } from '../../helper/style';


function ordinalStr (n) {
  return n + (['','st','nd','rd'][~~(n/10%10)-1?n%10:0] || 'th');
}

function finishedPosStr (pos) {
  if(!pos) {
    return '-';
  }
  return ordinalStr(pos);
}

function playerForcesCount (game, playerIndex) {
  // Returns count.
  var count = 0;
  if(Array.isArray(game.force) && Array.isArray(game.force[playerIndex]))
    for(let i = 0; i < game.force[playerIndex].length; i++) {
      const zoneID = game.force[playerIndex][i][BF.FORCE_INDEX_ZONE];
      if(zoneID >= BF.FORCE_ZONE_PRODUCED || zoneID <= BF.FORCE_ZONE_CARGO)
          count++;  // Only count live forces.
    }
  return count;
}

class BaseGameDetailPlayer extends React.Component {

  state = {
    playerIndex: 0,
    playerIncome: 0,
    playerZones: 0,
    store: {},
  };

  constructor(props) {
    super(props);  // Required step: always call the parent class' constructor

    this.state.playerIndex = props.playerIndex;
    this.state.playerIncome = props.playerIncome;
    this.state.playerZones = props.playerZones;
    this.state.store = props.store;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store !== this.state.store ||
        nextProps.playerIndex !== this.state.playerIndex ||
        nextProps.playerIncome !== this.state.playerIncome ||
        nextProps.playerZones !== this.state.playerZones ) {
      this.setState({
        store: nextProps.store,
        playerIndex: nextProps.playerIndex,
        playerIncome: nextProps.playerIncome,
        playerZones: nextProps.playerZones,
      });
    }
  }

  playerTitle() {
    const { gameID, gameStatus } = this.state.store;
    if(!gameID || !gameStatus[gameID]) {
      return null;
    }
    const { playerName, playerID } = gameStatus[gameID];
    const userName = playerName[this.state.playerIndex];
    const actualPlayerID = playerID[this.state.playerIndex];
    if(userName) {
      return(
        <Link to={'/player/' + actualPlayerID}>
          <span style={ linkText } >
            { userName }
          </span>
        </Link>
      );
    }
    return <span>- Empty Slot -</span>;
  }

  render() {
    const { playerIncome, playerZones } = this.state;
    const { game, gameID, gameStatus } = this.state.store;
    const p = this.state.playerIndex;

    if(!gameStatus[gameID]) {
      return null;
    }
    const { required, submitted } = gameStatus[gameID];

    return(
      <Table.Row key={ 'tr' + p } >
        { (game.state !== BF.GAME_STATE_FINISHED &&
          game.state !== BF.GAME_STATE_REGISTERING) &&
          <Table.Cell textAlign='center'>
            {(required & (1<<p)) ? ((submitted & (1<<p)) ?
              <Icon color='green' name='check circle' /> :
              <Icon color='red' name='exclamation' />) : '-'
            }
          </Table.Cell>
        }
        { game.teams > 1 &&
          <Table.Cell textAlign='center'>
            { game.teamName[game.team[p]] }
          </Table.Cell>
        }
        <Table.Cell>{ this.playerTitle() }</Table.Cell>
        <Table.Cell textAlign='center'>
          {(Array.isArray(game.finished) && game.finished[p][0]) ?
             (game.finished[p][0] === 1 ? <b>1st</b> :
          finishedPosStr(game.finished[p][0])) :
          '-'
          }
        </Table.Cell>

        <Table.Cell textAlign='center'>{ playerIncome }</Table.Cell>

        <Table.Cell textAlign='center'>
          { Array.isArray(game.cash) ? game.cash[p] : '-' }
        </Table.Cell>

        <Table.Cell textAlign='center'>{ playerForcesCount(game, p)}</Table.Cell>
        { game.state !== BF.GAME_STATE_FINISHED &&
          <Table.Cell textAlign='center'>
            { (Array.isArray(game.maxBuy) && Array.isArray(game.finished) &&
               Array.isArray(game.finished[p]) && !game.finished[p][0]) ? game.maxBuy[p] : '-' }
          </Table.Cell>
        }

        <Table.Cell textAlign='center'>{ playerZones }</Table.Cell>

        { game.state === BF.GAME_STATE_FINISHED &&
          <Table.Cell textAlign='center'>
            {(Array.isArray(game.finished) && game.finished[p][2]) ?
              (game.finished[p][2] > 0 ? '+' + game.finished[p][2] : game.finished[p][2]) : '0'
            }
          </Table.Cell>
        }

        { game.state === BF.GAME_STATE_FINISHED &&
          <Table.Cell textAlign='center'>
            {(Array.isArray(game.finished) && game.finished[p][3]) ?
              (game.finished[p][3] > 0 ? '+' + game.finished[p][3] : game.finished[p][2]) : '0'
            }
          </Table.Cell>
        }

      </Table.Row>
    );
  }
}

export default BaseGameDetailPlayer;
